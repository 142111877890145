<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Scan info</h4>
</div>
<div class="modal-body">
  <div class="alert alert-info" *ngIf="!errorMsg && !log">
    There was no info logged for this scan
  </div>
  <ngb-tabset style="margin-top: 10px;" type="pills">
    <!-- ERRORS TAB -->
    <ngb-tab id="scanErrors" *ngIf="!!errorMsg">
      <ng-template ngbTabTitle>
        ERRORS
      </ng-template>
      <ng-template ngbTabContent>
        <perfect-scrollbar [style.max-height]="'500px'"
          [config]="{suppressScrollX: false, suppressScrollY: false}">
          <div style="display: inline-block; margin-top: 10px;">
            <pre style="text-align: left">{{errorMsg}}</pre>
          </div>
        </perfect-scrollbar>
      </ng-template>
    </ngb-tab>
    <!-- LOGS TAB -->
    <ngb-tab id="scanLogs" *ngIf="!!log">
      <ng-template ngbTabTitle>
        LOG
      </ng-template>
      <ng-template ngbTabContent>
        <perfect-scrollbar [style.max-height]="'500px'"
          [config]="{suppressScrollX: false, suppressScrollY: false}">
          <div style="display: inline-block; margin-top: 10px;">
            <pre style="text-align: left">{{log}}</pre>
          </div>
        </perfect-scrollbar>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-light" (click)="modal.close('click')">Close</button>
</div>
