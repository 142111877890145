import { Component, OnInit, ViewChild } from '@angular/core';
import { ScanAssetMatch } from '@app/models';
import { UiModalComponent } from '@app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { ScanAssetMatchCopyright } from '@app/models/copyright';

@Component({
  selector: 'app-copyright-view-dialog',
  templateUrl: './copyright-view-dialog.component.html',
  styleUrls: ['./copyright-view-dialog.component.scss'],
})
export class CopyrightViewDialogComponent implements OnInit {
  // target values
  filename: string; // filename of asset or match
  copyright: ScanAssetMatchCopyright;

  // modal
  @ViewChild(UiModalComponent)
  modal: UiModalComponent;

  constructor() {}

  ngOnInit(): void {}

  openDialog(fileName: string, copyright: ScanAssetMatchCopyright) {
    this.filename = fileName;
    this.copyright = copyright;
    this.modal.show();
  }
}
