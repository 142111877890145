<ng-container *ngIf="project">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Scans</h4>
  </div>
  <div class="modal-body">
    <div
      class="text-center center-table-progressbar"
      *ngIf="isDisablePagination"
    >
      <mat-progress-spinner
        strokeWidth="5"
        [diameter]="40"
        [mode]="'indeterminate'"
      >
      </mat-progress-spinner>
    </div>
    <perfect-scrollbar
      class="overflowshadow"
      *ngIf="!isDisablePagination"
      [style.max-height]="'250px'"
    >
      <p-table
        id="tabPanels"
        [columns]="columns"
        [value]="project.scans.edges"
        [paginator]="false"
        [rows]="50"
        tableStyleClass="table table-bordered table-hover dataTable no-footer table-width"
        selectionMode="single"
        [(selection)]="stateService.selectedScan"
        (onRowUnselect)="rowUnselect($event)"
        [autoLayout]="true"
        (onRowSelect)="onRowSelectEmit($event)"
        [scrollable]="false"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>ID</th>
            <th>Commit</th>
            <th>Branch name</th>
            <th>Tag</th>
            <th>Created</th>
            <th>Components</th>
            <th>Vulnerabilities</th>
            <th>Licenses</th>
            <th title="Asset Composition">ASSETS COMPOSITION</th>
            <th>Status</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-scan let-columns="columns">
          <tr
            [pSelectableRow]="scan"
            class="cursor-pointer"
            [ngClass]="{
              'selected-table-row':
                isHighlightNewScan == true &&
                highlitedScanId == scan.node?.scanId,
              'scan-highlight':
                stateService.selectedScan?.node?.scanId === scan.node?.scanId
            }"
          >
            <td
              (dblclick)="
                copyToClipboard(
                  scan.node?.scanId,
                  'Scan ID copied to clipboard'
                )
              "
            >
              {{
                (scan.node?.scanId | slice : 0 : 4) +
                  '..' +
                  (scan.node?.scanId | slice : -4)
              }}
            </td>
            <td
              (dblclick)="
                copyToClipboard(
                  scan.node?.versionHash,
                  'Commit number copied to clipboard'
                )
              "
            >
              {{ scan.node?.versionHash }}
            </td>
            <td>{{ scan.node?.branch }}</td>
            <td>{{ scan.node?.tag }}</td>
            <td>
              {{ scan.node?.created | date : 'M/d h:mmaa' | lowercase }}
            </td>
            <td>{{ scan.node?.componentCount }}</td>
            <td>
              <span title="Critical" class="badge m-r-5 badge-red">{{
                scan.node.scanMetricsSummary?.vulnerabilityMetrics?.critical
              }}</span>
              <span title="High" class="badge m-r-5 badge-pink">{{
                scan.node.scanMetricsSummary?.vulnerabilityMetrics?.high
              }}</span>
              <span title="Medium" class="badge m-r-5 badge-orange">{{
                scan.node.scanMetricsSummary?.vulnerabilityMetrics?.medium
              }}</span>
              <span title="Low" class="badge m-r-5 badge-yellow">{{
                scan.node.scanMetricsSummary?.vulnerabilityMetrics?.low
              }}</span>
              <span title="Info" class="badge m-r-5 badge-light-primary">{{
                scan.node.scanMetricsSummary?.vulnerabilityMetrics?.info
              }}</span>
            </td>
            <td>
              <span
                title="Copyleft Strong"
                class="badge m-r-5 badge-copyleftstrong"
                >{{
                  scan.node.scanMetricsSummary?.licenseMetrics?.copyleftStrong
                }}</span
              >
              <span
                title="Copyleft Weak"
                class="badge m-r-5 badge-copyleftWeak"
                >{{
                  scan.node.scanMetricsSummary?.licenseMetrics?.copyleftWeak
                }}</span
              >
              <span
                title="Copyleft Partial"
                class="badge m-r-5 badge-copyleftPartial"
                >{{
                  scan.node.scanMetricsSummary?.licenseMetrics?.copyleftPartial
                }}</span
              >
              <span
                title="Copyleft Limited"
                class="badge m-r-5 badge-copyleftLimited"
                >{{
                  scan.node.scanMetricsSummary?.licenseMetrics?.copyleftLimited
                }}</span
              >
              <span title="Copyleft" class="badge m-r-5 badge-copyLeft">{{
                scan.node.scanMetricsSummary?.licenseMetrics?.copyleft
              }}</span>
              <span title="Dual" class="badge m-r-5 badge-light-warning">{{
                scan.node.scanMetricsSummary?.licenseMetrics?.dual
              }}</span>
              <span title="Custom" class="badge m-r-5 badge-light-warning">{{
                scan.node.scanMetricsSummary?.licenseMetrics?.custom
              }}</span>
              <span title="Permissive" class="badge m-r-5 badge-permissive">{{
                scan.node.scanMetricsSummary?.licenseMetrics?.permissive
              }}</span>
            </td>

            <td>
              <span title="Embedded" class="badge m-r-5 badge-blue">{{
                scan.node.scanMetricsSummary?.assetMetrics?.embedded
              }}</span>
              <span title="Open Source" class="badge m-r-5 badge-yellow">{{
                scan.node.scanMetricsSummary?.assetMetrics?.openSource
              }}</span>
              <span title="Unique" class="badge m-r-5 badge-green">{{
                scan.node.scanMetricsSummary?.assetMetrics?.unique
              }}</span>
            </td>
            <td style="text-align: center">
              <span *ngIf="scan.node?.status === 'FAIL'">
                <button
                  class="btn badge-light-danger error-btn"
                  title="Show log and error messages"
                  (click)="
                    openLogsEmit(scan.error, scan.node.errorMsg, scan.node.log)
                  "
                >
                  <i
                    class="feather icon-x badge-light-danger"
                    style="
                      font-size: 14px;
                      font-weight: 800;
                      background: none;
                      display: inline-block;
                      padding-top: 3px;
                    "
                  ></i>
                </button>
              </span>
              <span *ngIf="scan.node?.status === 'WARNING'">
                <button
                  class="btn badge-light-warning error-btn"
                  title="Show log and error messages"
                  (click)="
                    openLogsEmit(scan.error, scan.node.errorMsg, scan.node.log)
                  "
                >
                  <i
                    class="feather icon-x badge-light-warning"
                    style="
                      font-size: 14px;
                      font-weight: 800;
                      background: none;
                      display: inline-block;
                      padding-top: 3px;
                    "
                  ></i>
                </button>
              </span>
              <span *ngIf="scan.node?.status === 'SUCCESS'">
                <button
                  class="btn badge-light-success error-btn"
                  title="Show log"
                  *ngIf="!!scan.node?.errorMsg || !!scan.node?.log"
                  (click)="
                    openLogsEmit(scan.error, scan.node.errorMsg, scan.node.log)
                  "
                >
                  <i
                    class="feather icon-check badge-light-success"
                    style="
                      font-size: 14px;
                      font-weight: 800;
                      background: none;
                      display: inline-block;
                      padding-top: 3px;
                    "
                  ></i>
                </button>
                <i
                  *ngIf="!scan.node?.errorMsg && !scan.node?.log"
                  class="feather icon-check badge-light-success"
                  style="
                    font-size: 14px;
                    font-weight: 800;
                    background: none;
                    display: inline-block;
                    padding-top: 3px;
                  "
                ></i>
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </perfect-scrollbar>
    <div class="col-sm-12">
      <mat-paginator
        [hidePageSize]="false"
        [disabled]="isDisablePagination"
        [length]="project.scans?.totalCount"
        [pageSize]="defaultPageSize"
        [pageSizeOptions]="[25, 50, 100]"
        (page)="changePageEmit($event)"
      >
      </mat-paginator>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-light"
      (click)="modal.close('click')"
    >
      Close
    </button>
  </div>
</ng-container>
