import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { CoreGraphQLService } from '../core';

@Injectable({
  providedIn: 'root',
})
export class ProjectVersionService {
  public constructor(private coreGraphQLService: CoreGraphQLService) {}

  public getBackendVersion(): Observable<
    ApolloQueryResult<{
      backendVersion: string;
    }>
  > {
    return this.coreGraphQLService.coreGQLReq<{ backendVersion: string }>(
      gql(`query {
            backendVersion 
      }`),
      'no-cache'
    );
  }
}
