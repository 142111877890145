<app-ui-modal
  #licenseUrlViewDialog
  dialogClass="modal-dialog-centered popup-Width-fix"
>
  <!-- HEADER ================================================================================================= -->
  <div class="app-modal-header">
    <h5 class="modal-title">License URL and content</h5>
    <button
      type="button"
      class="close basic-close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="licenseUrlViewDialog.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- BODY ================================================================================================= -->
  <div class="app-modal-body">
    <!-- License ID -->
    <div class="info-cols"><small>License ID</small></div>
    <p class="light-font">{{ licenseId }}</p>

    <!-- License ID -->
    <div class="info-cols"><small>License Source URL</small></div>
    <p class="light-font">{{ licenseData?.url }}</p>

    <!-- TEXT -->
    <div class="info-cols"><small>License text</small></div>
    <div class="card card-body bg-light" style="padding-left: 50px">
      <pre class="asset-pane">{{ licenseData?.content }}</pre>
    </div>
  </div>

  <!-- FOOTER ================================================================================================= -->
  <div class="app-modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      (click)="licenseUrlViewDialog.hide()"
    >
      Close
    </button>
  </div>
</app-ui-modal>
