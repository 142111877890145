import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-already-attached-modal',
  templateUrl: './already-attached-modal.component.html',
  styleUrls: ['./already-attached-modal.component.scss'],
})
export class AlreadyAttachedModalComponent {
  constructor(
    public dialogRef: MatDialogRef<AlreadyAttachedModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
