import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'license-dialog',
  templateUrl: './license-dialog.component.html',
})
export class LicenseDialogComponent {
  @Input() public selectedLicenseDetail;
  public constructor(public activeModal: NgbActiveModal) {}

  public closeBtn(): void {
    this.activeModal.close();
  }
}
