export class StringSearchSettings {
  public pattern: string;
  public description: string;
  objectId: string;


  /**
   * object type a-ka string search settings level
   * possible values:
   *    ORGANIZATION,
   *    ENTITY,
   *    // scp project with all nested modules
   *    SCP_PROJECT,
   *    // module of scp project or classic root project(secure dashboard)
   *    MODULE
   */
  level: string;


  constructor(pattern: string, description: string, objectId: string, level: string) {
    this.pattern = pattern;
    this.description = description;
    this.objectId = objectId;
    this.level = level;
  }
}

