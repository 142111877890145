import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FilterStateService {
  private mapToJSON(map: Map<string, string>): string {
    return JSON.stringify([...map]);
  }

  private jsonToMap(json: string): Map<string, string> {
    const parsedJSON: Iterable<[string, string]> = JSON.parse(json);

    return new Map(parsedJSON);
  }

  saveFilter(name: string, filter: Map<string, string>) {
    localStorage.setItem(`${name}Filter`, this.mapToJSON(filter));
  }

  getFilter(name: string): Map<string, string> {
    const filter = localStorage.getItem(`${name}Filter`);

    if (filter) {
      return this.jsonToMap(filter);
    } else {
      return new Map<string, string>();
    }
  }

  public makeFilterMapString(filterMap: Map<string, any>): string {
    let compString: string = '';
    filterMap.forEach((val, key) => {
      if (
        key !== 'statusBadge' &&
        ((val !== 'ALL' && key !== 'name') || key === 'name')
      ) {
        compString += key + ':' + val + ',';
      }
    });
    return compString;
  }
}
