import { Component, Input, OnDestroy, Inject, ViewEncapsulation } from '@angular/core';
import { Spinkit } from './spinkits';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { CoreHelperService } from '@app/services/core/core-helper.service';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: [
        './spinner.component.scss',
        './spinkit-css/sk-line-material.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy {
    public isSpinnerVisible = true;
    public Spinkit = Spinkit;
    @Input() public backgroundColor = '#2196f3';
    @Input() public spinner = Spinkit.skLine;
    constructor(private router: Router,
        private coreHelperService: CoreHelperService
        , @Inject(DOCUMENT) private document: Document) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.isSpinnerVisible = true;
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.isSpinnerVisible = false;
            }
        }, () => {
            this.isSpinnerVisible = false;
        });

        this.coreHelperService.getSpinner().subscribe(data => {
            if (data === 'VISIBLE') {
                this.isSpinnerVisible = true;
            } else if (data === 'INVISIBLE') {
                this.isSpinnerVisible = false;
            } else {
                //
            }
        });
    }

    ngOnDestroy(): void {
        this.isSpinnerVisible = false;
    }
}
