import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { License, LicenseUrlAndContent, ScanAssetMatch } from '@app/models';
import { UiModalComponent } from '@app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { AlertService } from '@app/services/core/alert.service';
import { LicenseAndCopyrightAuditService } from '@app/services/license-and-copyright-audit-service';

@Component({
  selector: 'app-license-url-view-dialog',
  templateUrl: './license-url-view-dialog.component.html',
  styleUrls: ['./license-url-view-dialog.component.scss'],
})
export class LicenseUrlViewDialogComponent implements OnInit {
  licenseId: string;
  licenseData: LicenseUrlAndContent;

  // modal
  @ViewChild(UiModalComponent)
  public modal: UiModalComponent;
  public loaded: boolean = false;
  @Output() public errorWhenLoading: EventEmitter<void> =
    new EventEmitter<void>();

  constructor(
    private licenseAndCopyrightAuditService: LicenseAndCopyrightAuditService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {}

  openDialog(scanAssetMatch: ScanAssetMatch, licenseId: string) {
    this.licenseId = licenseId;
    this.licenseAndCopyrightAuditService
      .getAssetLicenseUrlAndContent(scanAssetMatch, licenseId)
      .subscribe(
        (res) => {
          // @ts-ignore
          this.licenseData = res.data.getAssetLicenseUrlAndContent;

          if (
            !!this.licenseData &&
            !!this.licenseData.url &&
            !!this.licenseData.content
          ) {
            // window.open(licenseData.url, '_blank');
            this.modal.show();
          } else if (!!this.licenseData && !!this.licenseData.provenance) {
            this.alertService.alertBox(
              this.licenseData.provenance,
              'License added by user',
              'info'
            );
          } else {
            this.alertService.alertBox(
              'No provenance is available for this license.',
              'Provenance not found',
              'warning'
            );
          }
          this.loaded = true;
        },
        (error) => this.errorWhenLoading.emit()
      );
  }

  showComponentLicenseURL() {}
  showAssetLicenseURL() {}
}
