import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PlanInterval } from '@app/subscription/models/Subscription';
import { Product } from '@app/subscription/models/Product';
import { SubscriptionRequest } from '@app/subscription/models/SubscriptionRequest';
import { SubscriptionResponse } from '@app/subscription/models/SubscriptionResponse';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { User } from '@app/models';
import { AuthenticationService } from '@app/security/services';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  @Output() subscriptionUpdated: EventEmitter<boolean> = new EventEmitter();

  public userCount: number;
  public product: Product;
  public planInterval: PlanInterval = PlanInterval.MONTHLY;

  currentUser: User;
  trialUser: boolean = false;
  subscribedUser: boolean = false;
  trialExpired: boolean = false;
  trialDaysRemaining: number;

  isHybrid: boolean = environment.hybrid;

  constructor(
    private apollo: Apollo,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.currentUser = this.authenticationService.currentUser;
    this.refresh();
  }

  subscriptionIsValid(): boolean {
    // if no subscription exists, return false
    if (!this.currentUser.organization.subscriptionExpiration) {
      return false;
    }
    return (
      new Date(this.currentUser.organization.subscriptionExpiration).getTime() >
      new Date().getTime()
    );
  }

  trialIsValid(): boolean {
    const trialDays = this.currentUser.organization.trialDays;
    const trialDaysUsed = this.diffDays(
      this.currentUser.organization.dateApproved
    );
    return trialDaysUsed < trialDays + 1;
  }

  diffDays(diffDate: Date) {
    const currentDate = new Date();
    return Math.floor(
      (currentDate.getTime() - new Date(diffDate).getTime()) /
        1000 /
        60 /
        60 /
        24
    );
  }

  refresh() {
    this.currentUser = this.authenticationService.currentUser;

    if (this.subscriptionIsValid()) {
      // org has subscription
      this.trialUser = false;
      this.subscribedUser = true;
    } else if (this.currentUser.organization.dateApproved) {
      // they don't have a subscription and their
      // account has been approved. They're a trial user
      this.trialUser = true;

      const trialDays = this.currentUser.organization.trialDays;
      const trialDaysUsed = this.diffDays(
        this.currentUser.organization.dateApproved
      );
      this.trialDaysRemaining = trialDays - trialDaysUsed;

      if (this.trialDaysRemaining < 0) {
        this.trialExpired = true;
      }
    }

    this.subscriptionUpdated.emit();
  }

  /**
   * Contributing user definition:
   * Threatrix defines contributing developers as any developer who uses
   * or contributes open source to projects scanned by Threatrix in the last 90 days.
   */

  calculatePrice(
    product: Product,
    planInterval: PlanInterval,
    users: number
  ): number {
    // full retail price
    let planCost =
      planInterval === PlanInterval.MONTHLY
        ? product.MONTHLY_PRICE * users
        : product.ANNUAL_PRICE * users;

    // Apply volume discounts
    if (users < 50) {
      // no discount
    } else if (users >= 50 && users < 100) {
      // 10% discount
      planCost = planCost * 0.9;
    } else if (users >= 100 && users < 500) {
      // 20% discount
      planCost = planCost * 0.8;
    } else if (users >= 500) {
      // 25% discount
      planCost = planCost * 0.75;
    }

    return planCost;
  }

  subscribe(subscriptionRequest: SubscriptionRequest): any {
    return this.apollo.mutate<SubscriptionResponse>({
      mutation: gql`
        mutation subscribe($subscriptionRequest: SubscriptionRequestInput!) {
          subscribe(subscriptionRequest: $subscriptionRequest) {
            status
            error {
              errorCode
              declineCode
              message
            }
            subscription {
              amount
              licensedUserCount
              startDate
              lastPaymentDate
              autoRenew
              status
              subscriptionPlan {
                code
                plan
                planInterval
              }
              paymentMethod {
                paymentMethodId
                paymentMethodType
                paymentMethodProvider
                nameOnCard
                lastFour
                expirationMonth
                expirationYear
                billingPhone
                billingAddress
                billingAddress1
                billingCity
                billingStateOrProvince
                billingPostalCode
                stripePaymentMethodId
              }
            }
          }
        }
      `,
      variables: { subscriptionRequest },
    });
  }
}
