import { Component, Input, OnInit } from '@angular/core';
import { ChartData } from '@app/models/charts';

@Component({
  selector: 'app-components-donut-chart',
  templateUrl: './components-donut-chart.component.html',
  styleUrls: ['./components-donut-chart.component.scss'],
})
export class ComponentsDonutChartComponent implements OnInit {
  @Input()
  chartData: ChartData;

  @Input()
  chartClass: string;

  private TITLE = 'Components';

  private COLORS = ['#fb5b5b', '#ee8144', '#d2e032', '#de3fd8', '#58c7c0'];

  private chartLabels = [
    {
      label: 'Critical',
      class: 'red',
    },
    {
      label: 'High',
      class: 'orange',
    },
    {
      label: 'Medium',
      class: 'yellow',
    },
    {
      label: 'Low',
      class: 'pink',
    },
    {
      label: 'Info',
      class: 'skyblue',
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.composeChartData();
  }

  // todo: ref: all new chart components have same method with same implementation, let's move it somewhere and get rid of code duplication.
  composeChartData() {
    this.chartData.title = this.TITLE;
    this.chartData.colors = this.COLORS;
    this.chartData.fill = { colors: this.COLORS };

    this.chartData.chartLabels = this.chartLabels;
    this.chartData.labels = this.chartLabels.map(({ label }) => label);
  }
}
