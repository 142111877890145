import { PageInfo, PagingScope, PagingState } from '@app/models/paging';
import { UserPreferenceService } from '@app/services/core/user-preference.service';

export abstract class Paginated {
  public pagingScope: PagingScope;
  public pagingState: PagingState;

  public pageInfo: PageInfo; // page info responded by server
  public totalCount: number;

  public constructor(
    public userPreferenceService: UserPreferenceService,
    moduleName,
    componentName
  ) {
    this.pagingScope = new PagingScope(moduleName, componentName);
    this.pagingState = new PagingState(
      this.pagingScope,
      this.userPreferenceService
    );
  }

  abstract changePage(...args);
}
