<h1 mat-dialog-title>
  Project was already scanned
  <i style="color: orange" class="fa-solid fa-circle-exclamation"></i>
</h1>
<div mat-dialog-content>
  <p class="main-text">
    Module cannot be added because it's already attached to
    <b>{{ data.name }} </b>at
    <b>{{ data.date }}</b>
  </p>
  <p class="main-text">
    Do you want to navigate to <b>{{ data.name }}</b> project or do you want to
    scan module again there?
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button class="cancel" (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="'scan'">Scan Again</button>
  <button mat-button [mat-dialog-close]="'navigate'" cdkFocusInitial>
    Navigate
  </button>
</div>
