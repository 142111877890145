import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MESSAGES } from '@app/messages/messages';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IgnoredFiles, Level, Type } from '@app/models/ignored-files';
import { Clipboard } from '@angular/cdk/clipboard';

import { ScanService } from '@app/services/scan.service';
import * as _ from 'lodash';
import { ScanAsset } from '@app/models';
import { SimmComponent } from '@app/threat-center/shared/simm/simm.component';
import { AssetService } from '@app/services/asset-service';
import { ApolloQueryResult } from 'apollo-client';
import { UiModalComponent } from '@app/theme/shared/components/modal/ui-modal/ui-modal.component';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { AlertService } from '@app/services/core';
import { ProjectService } from '@app/services/project.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-new-assets-table',
  templateUrl: './new-assets-table.component.html',
  styleUrls: ['./new-assets-table.component.scss'],
})
export class NewAssetsTableComponent implements OnInit, OnChanges {
  @Input() scanAssetsTree;
  @Input() columnsFilter = new Map();
  @Input() story;
  @Input() public loading: boolean;
  @Input() public fromLicenseDimension: boolean = false;

  //for modal view
  @Input() entityId: string;
  @Input() projectId: string;

  @Output() filterChange = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() details = new EventEmitter();

  @ViewChild(UiModalComponent) public modal: UiModalComponent;

  public columns: Array<string> = [
    'Name',
    'Matches',
    'Status',
    'Match Origin',
    'Version(s)',
    'Licenses',
    'Match Score',
    'Match Type',
  ];
  public MESSAGES = MESSAGES;
  public tableData = [];
  public ignoredAssets: { [assetId: string]: boolean } = {};
  public nameChange$ = new Subject<string>();
  public originChange$ = new Subject<string>();

  public selectedEmbeddedAsset: any;
  public selectedAsset: ScanAsset;
  public selectedLicenses: any = {};
  public allSelected: boolean = false;

  public licenses: Array<string> = [];
  public attributionComment: string;

  public constructor(
    private scanService: ScanService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private assetService: AssetService,
    private clipboard: Clipboard,
    private alertService: AlertService,
    private projectService: ProjectService,
    public dialog: MatDialog
  ) {
    this.nameChange$.pipe(debounceTime(500)).subscribe((value) => {
      if (value.length === 0) {
        this.columnsFilter.delete('Name');
      } else {
        this.columnsFilter.set('Name', value);
      }

      this.filterChange.emit({
        columnsFilter: this.columnsFilter,
        idElement: 'nameSearch',
      });
    });

    this.originChange$.pipe(debounceTime(500)).subscribe((value) => {
      if (value.length === 0) {
        this.columnsFilter.delete('Match Origin');
      } else {
        this.columnsFilter.set('Match Origin', value);
      }

      this.filterChange.emit({
        columnsFilter: this.columnsFilter,
        idElement: 'originSearch',
      });
    });
  }

  public ngOnInit(): void {
    this.loadIgnoredAssets();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['scanAssetsTree'] && this.scanAssetsTree) {
      this.tableData = _.sortBy(this.scanAssetsTree.edges, (edge) => [
        edge.node.scanAssetType,
        this.getName(edge.node.name),
      ]);
    }
  }

  public getColumnFilterValue(key: string): string {
    let value: string;
    if (key === 'Matches') {
      value = this.columnsFilter.get('Embedded Assets');
    } else {
      value = this.columnsFilter.get(key);
    }
    if (value === undefined) {
      if (
        key === 'Status' ||
        key === 'File Size' ||
        key === 'Embedded Assets' ||
        key === 'Attribution' ||
        key === 'Match Score' ||
        key === 'Match Type' ||
        key === 'Matches' ||
        key === 'Licenses'
      ) {
        return 'ALL';
      } else {
        return '';
      }
    } else {
      return value;
    }
  }

  // return match type caption by match type code
  public matchTypeVal2Caption(val: string): string {
    switch (val) {
      case 'UNIQUE_PROPRIETARY':
        return 'PROPRIETARY ';
      case 'PROPRIETARY':
        return 'PROPRIETARY/OPEN SOURCE ';
      case 'EMBEDDED_OPEN_SOURCE':
        return 'OPEN SOURCE/PROPRIETARY ';
      case 'OPEN_SOURCE':
        return 'OPEN SOURCE ';
      case 'OPEN_COMPONENT':
        return 'OPEN SOURCE COMPONENT ';
      default:
        return 'STATIC REFERENCE ';
    }
  }

  public setSelectedEmbeddedAsset(embeddedAsset: any, assetVersionModal): void {
    this.selectedEmbeddedAsset = embeddedAsset;

    console.log(this.selectedEmbeddedAsset);
    assetVersionModal.show();
  }

  public openAttributionModal(
    asset: ScanAsset,
    match,
    attributionLicensesModal,
    i: number
  ): void {
    this.selectedAsset = asset;
    this.selectedEmbeddedAsset = match;
    this.licenses = this.getSourceAssetMatchLicenses(i);
    attributionLicensesModal.show();
  }

  private getSourceAssetMatchLicenses(i: number): Array<string> {
    const licenses = [];
    const licenseIds = [];
    this.selectedAsset.embeddedAssets.edges[i].node.matchLicenses.forEach(
      (l) => {
        if (l.needIncludeInCode && !licenseIds.includes(l.licenseId)) {
          licenses.push(l);
          licenseIds.push(l.licenseId);
          this.selectedLicenses[l.licenseId] = false;
        }
      }
    );
    return licenses;
  }

  public attributeProcessExecutionModel(modelContent): void {
    this.alertService
      .alertConfirm(
        'Do you want to close dialog?',
        'You can close modal, attribution process will complete. But if you leave this page you won’t get completion notification message.',
        'question',
        true,
        true,
        '#4680ff',
        '#6c757d',
        'Yes',
        'No'
      )
      .then((result: SweetAlertResult) => {
        if (result.value) {
          modelContent.hide();
        }
      });
  }

  public selectAllLicenses(event: Event): void {
    const { checked } = event.target as HTMLInputElement;
    Object.keys(this.selectedLicenses).forEach((key: string) =>
      checked
        ? (this.selectedLicenses[key] = true)
        : (this.selectedLicenses[key] = false)
    );
  }

  public hasSelectedLicenses(): boolean {
    return Object.keys(this.selectedLicenses).some(
      (key: string) => this.selectedLicenses[key]
    );
  }

  public checkIfAllSelected(): void {
    const allSelected: boolean = Object.keys(this.selectedLicenses).every(
      (key: string) => this.selectedLicenses[key]
    );
    if (allSelected) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
  }

  // send attribute asset request тут шлем на атрибуцию
  attributeAsset(
    attributionLicensesModal,
    attributeProcessExecuteMessageModel,
    ignore: boolean
  ) {
    if (!ignore) {
      if (!this.hasSelectedLicenses()) {
        this.alertService.alertBox(
          'To attribute asset you have to select at least one of license which will be used for attribution',
          '',
          'info'
        );
        return;
      }
    }
    attributionLicensesModal.hide();
    attributeProcessExecuteMessageModel.show();
    const selectedLicensesArray: Array<string> = [];
    Object.keys(this.selectedLicenses).forEach((key: string) =>
      selectedLicensesArray.push(key)
    );
    this.projectService
      .attributeAsset(
        this.selectedAsset.scanId,
        this.selectedAsset.scanAssetId,
        ignore ? null : selectedLicensesArray,
        this.attributionComment
      )
      .subscribe(
        (result) => {
          Swal.close();
          attributeProcessExecuteMessageModel.hide();
          if (result.data.attributeAsset.attributionStatus === 'COMPLETE') {
            this.alertService.alertBox(
              result.data.attributeAsset.message
                ? result.data.attributeAsset.message
                : 'Attribution is successful',
              'License attribution',
              'success'
            );
          } else if (result.data.attributeAsset.attributionStatus === 'ERROR') {
            this.alertService.alertBox(
              `${result.data.attributeAsset.message}`,
              'License attribution',
              'error'
            );
          } else {
            this.alertService.alertBox(
              `${result.data.attributeAsset.message}`,
              'License attribution',
              'info'
            );
          }
        },
        (error) => {
          Swal.close();
          attributeProcessExecuteMessageModel.hide();
          this.alertService.alertBox(
            'Attribution error',
            'License attribution',
            'error'
          );
        }
      );
  }

  public gotoDetails(scanAsset: ScanAsset): void {
    this.details.emit(scanAsset);
  }

  public onCopyAssetID(assetID: string): void {
    if (assetID) {
      this.clipboard.copy(assetID);
      this.toastr.info(`Asset ID ${assetID} copied to clipboard`);
    }
  }

  public openInNewTab(
    repositoryCode: string,
    repositoryOwner: string,
    repositoryName: string
  ): void {
    if (repositoryCode === 'GITHUB') {
      window.open(
        '//github.com/' + repositoryOwner + '/' + repositoryName,
        '_blank'
      );
    }
  }

  public onFilterColumn(
    column: string,
    event: Event,
    idElement: string = ''
  ): void {
    const { value } = event.target as HTMLInputElement | HTMLSelectElement;

    if (value.length === 0 || value === 'ALL') {
      if (column === 'Matches') {
        this.columnsFilter.delete('Embedded Assets');
      } else {
        this.columnsFilter.delete(column);
      }
    } else {
      if (column === 'Matches') {
        this.columnsFilter.set('Embedded Assets', value);
      } else {
        this.columnsFilter.set(column, value);
      }
    }

    this.filterChange.emit({ columnsFilter: this.columnsFilter, idElement });
  }

  public getScanRepository(
    embeddedAsset: ScanAsset,
    scanAsset: ScanAsset,
    simm?: SimmComponent
  ): void {
    this.assetService.getScanRepositoryInfo(scanAsset.scanId).subscribe(
      (scanRepository: ApolloQueryResult<any>) => {
        if (!scanRepository.data.scan.scanRepository) {
          this.toastr.info('There is no data for this file available.');
          return;
        }
        if (!embeddedAsset) {
          simm.openOnlySource(
            scanRepository.data.scan.scanRepository,
            scanAsset,
            simm
          );
          return;
        }
        simm.openWithParams(
          scanRepository.data.scan.scanRepository,
          scanAsset,
          embeddedAsset,
          simm
        );
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onNameChange(event: Event) {
    const { value } = event.target as HTMLInputElement | HTMLSelectElement;
    this.nameChange$.next(value);
  }

  onOriginChange(event: Event) {
    const { value } = event.target as HTMLInputElement | HTMLSelectElement;
    this.originChange$.next(value);
  }

  goBack() {
    this.back.emit();
  }

  getName(name: string) {
    if (name.includes('/') || name.includes('\\')) {
      const orgname = name.replace('\\', '/');
      return orgname.substring(orgname.lastIndexOf('/') + 1);
    } else {
      return name;
    }
  }

  /**
   * Ignore/unignore specific asset path
   *
   * @param event mouse event
   * @param asset scan asset
   * @param ignore whether ignore or unignore asset
   */
  onIgnoreAsset(event: MouseEvent, asset, ignore: boolean) {
    event.stopPropagation();

    let pattern = this.getPattern(asset);
    // fix bug when path has / at the end of pattern for some reason
    if (pattern.substring(pattern.length - 1) === '/') {
      pattern = pattern.substring(0, pattern.length - 1);
    }

    const ignoredAsset = new IgnoredFiles();

    ignoredAsset.objectId = asset.projectId;
    ignoredAsset.pattern = pattern;
    ignoredAsset.level = Level.PROJECT;
    ignoredAsset.type = Type.PATHS;

    const ignoredFiles$ = ignore
      ? this.scanService.saveIgnoredFiles(ignoredAsset)
      : this.scanService.removeIgnoredFiles(ignoredAsset);

    asset.loading = true;
    ignoredFiles$.subscribe({
      next: () => {
        this.toastr.success(
          `${pattern} asset successfully ${ignore ? 'ignored' : 'unignored'}`,
          `${ignore ? 'Ignore' : 'Unignore'} Asset`
        );
        this.loadIgnoredAssets();
        asset.loading = false;
      },
      error: () => {
        this.toastr.error(
          `Error while ${ignore ? 'ignoring' : 'unignoring'} ${pattern} asset`,
          `${ignore ? 'Ignore' : 'Unignore'} Asset`
        );
        asset.loading = false;
      },
    });
  }

  public ignored(scanAsset): boolean {
    return this.ignoredAssets[this.getPattern(scanAsset.node)];
  }

  private getPattern(scanAsset): string {
    if (scanAsset.workspacePath) {
      const splits = scanAsset.workspacePath.split('/');
      if (splits[splits.length - 1] !== scanAsset.name) {
        splits[splits.length - 1] = scanAsset.name;
        return splits.join('/');
      }
    }
    return scanAsset.name;
  }

  private loadIgnoredAssets() {
    const entityId =
      this.route.snapshot.paramMap.get('entityId') || this.entityId;
    const projectId =
      this.route.snapshot.paramMap.get('projectId') || this.projectId;
    if (entityId) {
      this.scanService
        .getIgnoredFiles(projectId, entityId)
        .subscribe(({ data }) => {
          this.ignoredAssets = {};
          (data['getIgnoredFiles'] as IgnoredFiles[]).forEach((file) => {
            this.ignoredAssets[file.pattern] = true;
          });
        });
    }
  }
}
