<div class="modal-body fix-component">
  <p class="f-16 result-header">Result of updating the component version:</p>
  <div *ngFor="let result of fixResults" class="results-wrapper">
    <i *ngIf="result.success" class="fas fa-check"></i>
    <i *ngIf="!result.success" class="fa-regular fa-triangle-exclamation"></i>
    <span class="f-16">&nbsp;&nbsp;{{ result.buildFile }}</span>
    <p class="f-16" *ngIf="result.errorMessage">{{ result.errorMessage }}</p>
  </div>
  <div class="close-button-wrapper">
    <button class="btn btn-primary" (click)="closeBtn()">Got It!</button>
  </div>
</div>
