import { Component, Input, OnInit } from '@angular/core';
import { ChartData } from '@app/models/charts';

// todo ref: refactor to inherid from base abstract component to get rid of code duplications
//           component inheritance: https://stackoverflow.com/questions/36475626/how-to-extend-inherit-components
@Component({
  selector: 'app-licenses-donut-chart',
  templateUrl: './licenses-donut-chart.component.html',
  styleUrls: ['./licenses-donut-chart.component.scss'],
})
export class LicensesDonutChartComponent implements OnInit {
  @Input() public chartData: ChartData;

  @Input() public chartClass: string;

  @Input() public showTitle: boolean = true;

  @Input() public color: string;

  private TITLE = 'License risk';
  // important! the position of the color refers to the same index of label.
  // The number of labels should correspond to the number of colors if you want the colors of the labels be specific
  private COLORS = [
    '#dc140c',
    '#dc140c',
    '#f67112',
    '#f67112',
    '#ffa21d',
    '#11C15B',
    '#11C15B',
    '#11C15B',
    '#11C15B',
    '#11C15B',
  ];
  private chartLabels = [
    {
      label: 'Copyleft',
      class: 'red',
      prop: 'copyleft',
    },
    {
      label: 'Copyleft Strong',
      class: 'crimson',
      prop: 'copyleftStrong',
    },
    {
      label: 'Copyleft Limited',
      class: 'orange',
      prop: 'copyleftLimited',
    },
    {
      label: 'Copyleft Partial',
      class: 'orange',
      prop: 'copyleftPartial',
    },
    {
      label: 'Copyleft Weak',
      class: 'orange',
      prop: 'copyleftWeak',
    },
    {
      label: 'Proprietary',
      class: 'green',
      prop: 'proprietary',
    },
    {
      label: 'Proprietary Free',
      class: 'green',
      prop: 'proprietaryFree',
    },
    {
      label: 'Permissive',
      class: 'green',
      prop: 'permissive',
    },
    {
      label: 'Custom',
      class: 'custom',
      prop: 'custom',
    },
    {
      label: 'Dual',
      class: 'dual',
      prop: 'dual',
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.composeChartData();
  }

  composeChartData() {
    this.chartData.title = this.showTitle ? this.TITLE : null;
    this.chartData.colors = this.COLORS;
    this.chartData.fill = { colors: this.COLORS };

    this.chartData.chartLabels = this.chartLabels;
    this.chartData.labels = this.chartLabels.map(({ label }) => label);
  }
}
