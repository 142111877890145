import { Component, OnInit, ViewChild } from '@angular/core';
import { UiModalComponent } from '@app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { AuthenticationService } from '@app/security/services';
import { Repository, ScanAsset } from '@app/models';
import { RepositoryService } from '@app/services/repository.service';
import { AuthorizedClients } from '@app/models/client';

@Component({
  selector: 'app-source-code-view-dialog',
  templateUrl: './source-code-view-dialog.component.html',
  styleUrls: ['./source-code-view-dialog.component.scss'],
})
export class SourceCodeViewDialogComponent implements OnInit {
  @ViewChild(UiModalComponent)
  modal: UiModalComponent;

  fileName: string;
  content: string;
  public color: string;

  constructor(
    private authenticationService: AuthenticationService,
    private repositoryService: RepositoryService
  ) {}

  ngOnInit(): void {}

  open(matchAsset: ScanAsset, scanRepository: Repository, color?: string) {
    this.color = color;
    this.fileName = matchAsset.name;
    const authorizedClients: Array<AuthorizedClients> =
      this.authenticationService.getFromStorageBasedEnv('authorizedClients');
    const accessToken =
      this.authenticationService.getUserRepoAccountAccessToken(
        authorizedClients,
        scanRepository
      );
    this.repositoryService
      .fetchAuthenticatedAsset(matchAsset.assetRepositoryUrl.data, accessToken)
      .subscribe((response) => {
        if (response.content) {
          this.content = atob(response.content);
        } else {
          // bitbucket return raw file only, it response hasn't node 'content'
          this.content = atob(response);
        }
        this.modal.show();
      });
  }
}
