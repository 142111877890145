import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApolloQueryResult } from 'apollo-client';
import version from '../assets/version.json';
import { ProjectVersionService } from './services/project/project-version.service';
import { OngoingOperationsService } from './services/ongoing-operations.service';
import { environment } from 'environments/environment';
import { LocalService } from './services/core';
import { ReloadService } from './services/reload.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // get the FE version from a file inside assets
  public frontendVersion: {
    version: string;
  } = version;
  public backendVersion: string;
  public constructor(
    private router: Router,
    private projectVersionService: ProjectVersionService,
    private ongoingOperations: OngoingOperationsService,
    private localService: LocalService,
    // do not remove,
    private reloadService: ReloadService
  ) {
    // this.router.errorHandler = (error: any) => {
    //   alert(
    //     'Such page does not exist. You will be redirected to the main page.'
    //   );
    //   this.router.navigate(['']);
    // };
  }

  /**
   * scroll page to the top when navigationg and receive backend
   * version of the application
   */
  public ngOnInit(): void {
    const el: HTMLElement = document.getElementById('app-version');
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (evt.url.includes('/login') || evt.url.includes('create-account')) {
        el.classList.add('display-none');
      } else if (evt.url.includes('/awaiting-approval')) {
       el.classList.add('diff');
       el.classList.remove('display-none');
      } else {
        el.classList.add('diff');
        el.classList.remove('display-none');
      }
      window.scrollTo(0, 0);
    });
    this.getBackedVersion();

    this.restoreOngoingOperations();
  }

  private restoreOngoingOperations(): void {
    let username: string = '';
    if (environment.production && !environment.staging) {
      username = this.localService.getLocalStorage('currentUser')?.username;
    } else {
      username = JSON.parse(localStorage.getItem('currentUser'))?.username;
    }
    setTimeout(() => {
      this.ongoingOperations.subject.next({
        username,
        logout: false,
      });
    }, 4000);
  }

  /**
   * receive backend version from the api
   * on the backend there is a file which they are reading and sending this info to FE
   */
  private getBackedVersion(): void {
    this.projectVersionService
      .getBackendVersion()
      .subscribe((result: ApolloQueryResult<{ backendVersion: string }>) => {
        this.backendVersion = result.data.backendVersion;
      });
  }
}
