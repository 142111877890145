import { Component, OnInit, ViewChild } from '@angular/core';
import { UiModalComponent } from '@app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
    selector: 'app-copyright-list-dialog',
    templateUrl: './copyright-list-dialog.component.html',
    styleUrls: ['./copyright-list-dialog.component.scss']
})
export class CopyrightListDialogComponent implements OnInit {
    // modal
    @ViewChild(UiModalComponent) modal: UiModalComponent;

    copyrights = [];

    constructor() { }

    ngOnInit(): void {
    }

    open(copyrights) {
        this.copyrights = copyrights;
        this.modal.show();
    }

}
