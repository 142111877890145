<div class="simm-box" *ngIf="sourceAsset && matchAsset && stateOpen">
  <!-- TOOLBAR -->
  <div class="simm-toolbar">
    <div
      class="btn-group"
      role="group"
      aria-label="Basic example"
      *ngIf="
        matches &&
        matches?.length > 0 &&
        matchAsset?.name !== 'CodeVerifierAuthenticator.java'
      "
    >
      <button
        class="btn btn-outline-secondary btn-sm btn-next"
        (click)="prevMatch()"
        [placement]="'bottom'"
        ngbTooltip="Prev match"
      >
        <i class="feather icon-arrow-up"></i>
      </button>
      <button
        class="btn btn-outline-secondary btn-sm btn-prev"
        (click)="nextMatch()"
        [placement]="'bottom'"
        ngbTooltip="Next match"
      >
        <i class="feather icon-arrow-down"></i>
      </button>
    </div>
    <div
      class="mr-auto"
      *ngIf="
        matches &&
        matches?.length > 0 &&
        matchAsset?.name !== 'CodeVerifierAuthenticator.java'
      "
    >
      <span class="hint"> << jump to next/prev match (use up/down keys)</span>
    </div>

    <div
      class="mr-auto"
      *ngIf="
        matches?.length === 0 &&
        matchAsset?.name !== 'CodeVerifierAuthenticator.java'
      "
    >
      <div class="alert alert-danger p-1 m-0">
        Unable to display matches for these files
      </div>
    </div>

    <div
      class="mr-auto"
      *ngIf="!matches && matchAsset?.name !== 'CodeVerifierAuthenticator.java'"
    ></div>

    <div
      style="width: 100%"
      *ngIf="matchAsset?.name === 'CodeVerifierAuthenticator.java'"
    >
      <div style="width: 51%; display: flex; justify-content: flex-start">
        <button
          style="max-width: 130px; max-height: 35px"
          class="btn btn-outline-info btn-sm btn-next"
          (click)="openTestLink()"
        >
          Open vulnerability
        </button>
      </div>
      <div
        class="btn-group"
        style="width: 38%; display: flex; justify-content: flex-start"
        role="group"
        aria-label="Basic example"
      >
        <button
          style="max-width: 100px; max-height: 35px"
          class="btn btn-outline-secondary btn-sm btn-next"
          (click)="applyPatchTest()"
        >
          Apply Patch
        </button>
      </div>
      <div
        style="
          width: 11%;
          display: flex;
          justify-content: flex-end;
          flex-wrap: nowrap;
        "
      >
        <span class="hint">type Esc to close >></span>

        <button
          style="max-height: 32px"
          class="btn btn-outline-secondary btn-sm btn-close float-right"
          (click)="close()"
          [placement]="'bottom'"
          ngbTooltip="Close Patch Tool (Esc key press works too.)"
        >
          <i class="feather icon-x"></i>
        </button>
      </div>
    </div>

    <div
      class="float-right"
      *ngIf="matchAsset?.name !== 'CodeVerifierAuthenticator.java'"
    >
      <span class="hint">type Esc to close >></span>
    </div>
    <button
      class="btn btn-outline-secondary btn-sm btn-close float-right"
      (click)="close()"
      [placement]="'bottom'"
      ngbTooltip="Close SIMM Tool (Esc key press works too.)"
      *ngIf="matchAsset?.name !== 'CodeVerifierAuthenticator.java'"
    >
      <i class="feather icon-x"></i>
    </button>
  </div>

  <!-- HEADER -->
  <div class="simm-header">
    <div>
      <span
        ><b>{{ sourceAsset.name }}</b> | File size:
        {{ sourceAsset.assetSize | number : '1.0-0' }}B</span
      >
    </div>
    <div>
      <span
        ><b>{{ matchAsset.name }}</b> | File size:
        {{ matchAsset.assetSize | number : '1.0-0' }}B</span
      >
    </div>
  </div>

  <!-- FILES -->
  <div class="simm-files">
    <div class="simm-file simm-file-source" #sourceScroll>
      <markdown
        *ngIf="
          sourceAsset.content !== undefined && sourceAsset.content !== null;
          else sourceAssetUnavailable
        "
        [data]="sourceAsset.content | language : 'java'"
        lineHighlight
        [line]="sourceLineHighlights"
        lineNumbers
      ></markdown>
      <ng-template #sourceAssetUnavailable>
        <div
          class="alert alert-warning m-3"
          *ngIf="sourceLoaded && !projectIsUploaded"
        >
          Source asset not available. Please verify that your scan configuration
          includes the repository meta data and/or that Threatrix has permission
          to access this repository
        </div>
        <div
          class="alert alert-warning m-3"
          *ngIf="sourceLoaded && projectIsUploaded"
        >
          Source asset not available. Please verify that your scan settings for
          uploaded projects are set up correctly.
        </div>
        <div class="m-5" style="position: relative" *ngIf="!sourceLoaded">
          <main-spinner></main-spinner>
        </div>
      </ng-template>
    </div>

    <div class="matches-links"></div>

    <div
      class="simm-file simm-file-match"
      [ngClass]="{
        green: matchAsset?.name === 'CodeVerifierAuthenticator.java'
      }"
      #matchScroll
    >
      <markdown
        *ngIf="
          matchAsset.content !== undefined && matchAsset.content !== null;
          else matchAssetUnavailable
        "
        [data]="matchAsset.content | language : 'java'"
        lineHighlight
        [line]="matchLineHighlights"
        lineNumbers
      >
      </markdown>
      <ng-template #matchAssetUnavailable>
        <div class="alert alert-warning m-3" *ngIf="matchLoaded">
          matched asset not available. Please verify that neither asset nor
          repository have been moved.
        </div>
        <div class="m-5" style="position: relative" *ngIf="!matchLoaded">
          <main-spinner></main-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div class="simm-box" *ngIf="sourceAsset && !matchAsset && stateOpen">
  <!-- TOOLBAR -->
  <div class="simm-toolbar">
    <div class="mr-auto"></div>

    <div class="float-right">
      <span class="hint">type Esc to close >></span>
    </div>
    <button
      class="btn btn-outline-secondary btn-sm btn-close float-right"
      (click)="close()"
      [placement]="'bottom'"
      ngbTooltip="Close SIMM Tool (Esc key press works too.)"
    >
      <i class="feather icon-x"></i>
    </button>
  </div>

  <!-- HEADER -->
  <div class="simm-header">
    <div class="wide">
      <span
        ><b>{{ sourceAsset.name }}</b> | File size:
        {{ sourceAsset.assetSize | number : '1.0-0' }}B</span
      >
    </div>
    <!-- <div><span><b>{{matchAsset.name}}</b> | File size: {{matchAsset.assetSize | number:'1.0-0'}}B</span></div> -->
  </div>

  <!-- FILES -->
  <div class="simm-files">
    <div class="simm-file simm-file-source wide" #sourceScroll>
      <markdown
        *ngIf="
          sourceAsset.content !== undefined && sourceAsset.content !== null;
          else sourceAssetUnavailable
        "
        [data]="sourceAsset.content | language : 'java'"
        lineHighlight
        [line]="sourceLineHighlights"
        lineNumbers
      ></markdown>
      <ng-template #sourceAssetUnavailable>
        <div
          class="alert alert-warning m-3"
          *ngIf="sourceLoaded && !projectIsUploaded"
        >
          Source asset not available. Please verify that your scan configuration
          includes the repository meta data and/or that Threatrix has permission
          to access this repository
        </div>
        <div
          class="alert alert-warning m-3"
          *ngIf="sourceLoaded && projectIsUploaded"
        >
          Source asset not available. Please verify that your scan settings for
          uploaded projects are set up correctly.
        </div>
        <div class="m-5" style="position: relative" *ngIf="!sourceLoaded">
          <main-spinner></main-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</div>
