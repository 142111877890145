<span class="main-title">Component Risk Analysis</span>
<i class="fa fa-times" (click)="$event.stopPropagation(); p.close()"></i>
<div class="risk-body" *ngIf="componentData">
  <div>
    <span class="risk-title">Group:</span>
    <span class="component-data">{{
      componentData.node ? componentData?.node.group : componentData.group
    }}</span>
  </div>
  <div>
    <span class="risk-title">Project:</span>
    <span class="component-data">
      {{
        componentData.node ? componentData.node.name : componentData.name
      }}</span
    >
  </div>
  <div>
    <span class="risk-title">Version:</span>
    <span class="component-data">
      {{
        componentData.node ? componentData.node.version : componentData.version
      }}</span
    >
  </div>

  <div class="results-bubble medium">
    <span class="results-bubble-title">Supply Chain Risk</span>
    <div
      class="results-bubble-inner"
      [ngClass]="{
        crimson:  riskScoreData.supplyChainRiskScore.value >= 0 && riskScoreData.supplyChainRiskScore.value < 10,
        red:      riskScoreData.supplyChainRiskScore.value >= 10 && riskScoreData.supplyChainRiskScore.value < 20,
        orange:   riskScoreData.supplyChainRiskScore.value >= 20 && riskScoreData.supplyChainRiskScore.value < 30,
        yellow:   riskScoreData.supplyChainRiskScore.value >= 30 && riskScoreData.supplyChainRiskScore.value < 40,
        blue:     riskScoreData.supplyChainRiskScore.value >= 40 && riskScoreData.supplyChainRiskScore.value < 60,
        green:    riskScoreData.supplyChainRiskScore.value >= 60
      }"
    >
      <span class="results-bubble-inner-number">{{
        riskScoreData.supplyChainRiskScore.value
      }}</span>
    </div>
  </div>

  <div
    class="risk-line"
    *ngFor="let score of riskScoreData.scores"
  >
    <span class="risk-line-title">{{ score.label }}</span>
    <div style="display: flex">{{ score.value }}</div>
    <div></div>
  </div>
</div>
