import { Injectable } from '@angular/core';
import { UserPreferenceService } from '@app/services/core';

@Injectable({
  providedIn: 'root',
})
export class CompositeService {
  constructor(private userPreferenceService: UserPreferenceService) {}

  isShowComposite(): boolean {
    const compositeSettings: string = this.userPreferenceService.readValue(
      'composite.dashboard.switch.state'
    );
    const isShowCompositeData: boolean =
      compositeSettings === 'true' || compositeSettings === null;
    return isShowCompositeData;
  }

  saveState(isShowCompositeData) {
    this.userPreferenceService.saveValue(
      'composite.dashboard.switch.state',
      isShowCompositeData
    );
  }
}
