<app-ui-modal #copyrightListDialog>
    <!-- HEADER ================================================================================================= -->
    <div class="app-modal-header">
        <h5 class="modal-title">{{ copyrights.length }} copyrights</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="copyrightListDialog.hide()"><span aria-hidden="true">&times;</span></button>
    </div>

    <!-- BODY ================================================================================================= -->
    <div class="app-modal-body">
        <ul>
            <li *ngFor="let copyright of copyrights">
                {{ copyright }}
            </li>
        </ul>
    </div>

    <!-- FOOTER ================================================================================================= -->
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="copyrightListDialog.hide()">Close</button>
    </div>
</app-ui-modal>
