import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ComponentNamePipe } from './component-name.pipe';
import { DiscoverPipe } from './discover.pipe';
import { SortPipe } from './sort.pipe';
import { ToFixedPipe } from './round-number.pipe';
import { ParseJsonPipe } from './json-parse.pipe';

@NgModule({
  declarations: [ComponentNamePipe, DiscoverPipe, SortPipe, ParseJsonPipe],
  imports: [CommonModule],
  exports: [ComponentNamePipe, DiscoverPipe, SortPipe, ParseJsonPipe],
})
export class PipesModule {}
