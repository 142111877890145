import { Injectable } from '@angular/core';
import { GraphQLPage, PagingScope, PagingState } from '@app/models/paging';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UserPreferenceService } from '@app/services/core/user-preference.service';
import { PageInfo } from '@app/models';

/**
 * This service is used to accomodate paging data calculation functionality to avoid constant copy-paste of common paging code.
 */
@Injectable()
export class PagingService {
  constructor(private userPreferenceService: UserPreferenceService) {}

  /**
   * Calculates paging params for GraphQL query.
   *
   * @param pagingScope  - set of session vars to save paging state to load them on page reload and/or load when returned to the UI page.
   * @param pagingState  - paging state is set of vars for the current angular component.
   * @param pageEvent    - event which is fired when paging control changes its state, something in this control selected or clicked.
   * @param matPaginator - pagination control reference, used to update control state
   * @param pageInfo     - object this represents data paging state/info responded by server
   * @returns GraphQLPage instance containing paging and filter params for GraphQL query
   */
  // todo: ref: optimize if else sequence - they can be flatten
  calculateGraphQLPage(
    pagingScope: PagingScope,
    pagingState: PagingState,
    pageEvent: PageEvent,
    matPaginator: MatPaginator,
    pageInfo: PageInfo
  ): GraphQLPage {
    pagingState.isPaginationDisabled = true;

    // page size has changed
    if (pagingState.pageSize !== pageEvent.pageSize) {
      // save new size
      pagingState.pageSize = pageEvent.pageSize;

      // Setting item per page into session..

      // saving paging params into session
      this.userPreferenceService.settingUserPreference(
        pagingScope.moduleName,
        null,
        null,
        {
          componentName: pagingScope.componentName,
          value: pageEvent.pageSize.toString(), // todo: ref: Have to convert to string
          // here bcs Gaurav left bad interface. Remaster UserPreferenceService.
        }
      );

      // drop pagination control stage to first page
      matPaginator.firstPage();

      // return paging params (to apply to data selection out of this method)
      return new GraphQLPage(pagingState.pageSize);
    }

    // page size remains unchanged
    else {
      // page index has INCREMENTED - next page
      if (pageEvent.pageIndex > pageEvent.previousPageIndex) {
        // call with after...

        // seelect FIRST N records AFTER current END cursor - in other words next N records.
        return new GraphQLPage(
          // In the original implementation (which is spread all over the code) value read from session.
          // But it makes no sense bcs value put there only when page size has changed by user via pagination control.
          // Plus in the original implementation does not read session
          // variable on first load of the component, but only after page index changed.
          // legacy value: Number(this.userPreferenceService.getItemPerPageByModuleAndComponentName('Project', 'Components'))
          pagingState.pageSize,
          undefined,
          pageInfo.endCursor,
          undefined
        );
      }

      // page index has DECREMENTED - prev page
      else if (pageEvent.pageIndex < pageEvent.previousPageIndex) {
        // call with before..
        if (!!pageInfo && pageInfo.hasPreviousPage) {
          // select LAST N records BEFORE current START cursor - in other words select previous N records.
          return new GraphQLPage(
            undefined,
            pagingState.pageSize,
            undefined,
            pageInfo.startCursor
          );
        }
      } else {
        // select FIRST N records AFTER current START cursor - in other words same records
        return new GraphQLPage(
          pagingState.pageSize,
          undefined,
          pageInfo.startCursor,
          undefined
        );
      }
    }

    console.error('WEIRD PAGINATION EVENT FIRED - default return.');

    return new GraphQLPage(pagingState.pageSize);
  }
}
