import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MESSAGES } from '@app/messages/messages';
import { IgnoredFiles, Level, Type } from '@app/models/ignored-files';

import { ScanService } from '@app/services/scan.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-assets-table',
  templateUrl: './assets-table.component.html',
  styleUrls: ['./assets-table.component.scss'],
})
export class AssetsTableComponent implements OnInit, OnChanges {
  // @Input() otMetaData: string;
  @Input() scanAssetsTree;
  @Input() columnsFilter = new Map();
  @Input() story;
  @Input() public loading: boolean;
  @Input() public fromLicenseDimension: boolean = false;

  //for modal view
  @Input() entityId: string;
  @Input() projectId: string;

  @Output() filterChange = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() details = new EventEmitter();

  columns = [
    'Name',
    'File Size',
    'Status',
    'Embedded Assets',
    'Attribution',
    'Match Score',
    'Match Type',
  ];
  MESSAGES = MESSAGES;
  tableData = [];
  ignoredAssets: { [assetId: string]: boolean } = {};
  nameChange$ = new Subject<string>();

  constructor(
    private scanService: ScanService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.nameChange$.pipe(debounceTime(500)).subscribe((value) => {
      if (value.length === 0) {
        this.columnsFilter.delete('Name');
      } else {
        this.columnsFilter.set('Name', value);
      }

      this.filterChange.emit({
        columnsFilter: this.columnsFilter,
        idElement: 'nameSearch',
      });
    });
  }

  public ngOnInit(): void {
    this.loadIgnoredAssets();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['scanAssetsTree'] && this.scanAssetsTree) {
      this.tableData = _.sortBy(this.scanAssetsTree.edges, (edge) => [
        edge.node.scanAssetType,
        this.getName(edge.node.name),
      ]);
    }
  }

  getColumnFilterValue(key) {
    const value = this.columnsFilter.get(key);
    if (value === undefined) {
      if (
        key === 'Status' ||
        key === 'File Size' ||
        key === 'Embedded Assets' ||
        key === 'Attribution' ||
        key === 'Match Score' ||
        key === 'Match Type'
      ) {
        return 'ALL';
      } else {
        return '';
      }
    } else {
      return value;
    }
  }

  // return match type caption by match type code
  public matchTypeVal2Caption(val: string) {
    switch (val) {
      case 'UNIQUE_PROPRIETARY':
        return 'PROPRIETARY ';
      case 'PROPRIETARY':
        return 'PROPRIETARY/OPEN SOURCE ';
      case 'EMBEDDED_OPEN_SOURCE':
        return 'OPEN SOURCE/PROPRIETARY ';
      case 'OPEN_SOURCE':
        return 'OPEN SOURCE ';
      case 'OPEN_COMPONENT':
        return 'OPEN SOURCE COMPONENT ';
      default:
        return 'STATIC REFERENCE ';
    }
  }

  onFilterColumn(column: string, event: Event, idElement: string = '') {
    const { value } = event.target as HTMLInputElement | HTMLSelectElement;

    if (value.length === 0 || value === 'ALL') {
      this.columnsFilter.delete(column);
    } else {
      this.columnsFilter.set(column, value);
    }

    this.filterChange.emit({ columnsFilter: this.columnsFilter, idElement });
  }

  onNameChange(event: Event) {
    const { value } = event.target as HTMLInputElement | HTMLSelectElement;
    this.nameChange$.next(value);
  }

  goBack() {
    this.back.emit();
  }

  gotoDetails(scanAsset) {
    this.details.emit(scanAsset);
  }

  getName(name: string) {
    if (name.includes('/') || name.includes('\\')) {
      const orgname = name.replace('\\', '/');
      return orgname.substring(orgname.lastIndexOf('/') + 1);
    } else {
      return name;
    }
  }

  /**
   * Ignore/unignore specific asset path
   *
   * @param event mouse event
   * @param asset scan asset
   * @param ignore whether ignore or unignore asset
   */
  onIgnoreAsset(event: MouseEvent, asset, ignore: boolean) {
    event.stopPropagation();

    let pattern = this.getPattern(asset);
    // fix bug when path has / at the end of pattern for some reason
    if (pattern.substring(pattern.length - 1) === '/') {
      pattern = pattern.substring(0, pattern.length - 1);
    }

    const ignoredAsset = new IgnoredFiles();

    ignoredAsset.objectId = asset.projectId;
    ignoredAsset.pattern = pattern;
    ignoredAsset.level = Level.PROJECT;
    ignoredAsset.type = Type.PATHS;

    const ignoredFiles$ = ignore
      ? this.scanService.saveIgnoredFiles(ignoredAsset)
      : this.scanService.removeIgnoredFiles(ignoredAsset);

    asset.loading = true;
    ignoredFiles$.subscribe({
      next: () => {
        this.toastr.success(
          `${pattern} asset successfully ${ignore ? 'ignored' : 'unignored'}`,
          `${ignore ? 'Ignore' : 'Unignore'} Asset`
        );
        this.loadIgnoredAssets();
        asset.loading = false;
      },
      error: () => {
        this.toastr.error(
          `Error while ${ignore ? 'ignoring' : 'unignoring'} ${pattern} asset`,
          `${ignore ? 'Ignore' : 'Unignore'} Asset`
        );
        asset.loading = false;
      },
    });
  }

  ignored(scanAsset) {
    return this.ignoredAssets[this.getPattern(scanAsset.node)];
  }

  private getPattern(scanAsset) {
    if (scanAsset.workspacePath) {
      const splits = scanAsset.workspacePath.split('/');
      if (splits[splits.length - 1] !== scanAsset.name) {
        splits[splits.length - 1] = scanAsset.name;
        return splits.join('/');
      }
    }
    return scanAsset.name;
  }

  private loadIgnoredAssets() {
    const entityId =
      this.route.snapshot.paramMap.get('entityId') || this.entityId;
    const projectId =
      this.route.snapshot.paramMap.get('projectId') || this.projectId;
    if (entityId) {
      this.scanService
        .getIgnoredFiles(projectId, entityId)
        .subscribe(({ data }) => {
          this.ignoredAssets = {};
          (data['getIgnoredFiles'] as IgnoredFiles[]).forEach((file) => {
            this.ignoredAssets[file.pattern] = true;
          });
        });
    }
  }
}
