import { Component, ViewChild } from '@angular/core';
import { Repository, ScanAsset, ScanAssetMatch } from '@app/models';
import { UiModalComponent } from '@app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { SeverityFormattingService } from '@app/services/severity-formatting.service';

@Component({
  selector: 'app-release-table-view-dialog',
  templateUrl: './release-table-view-dialog.component.html',
  styleUrls: ['./release-table-view-dialog.component.scss'],
})
export class ReleaseTableViewDialogComponent {
  public scanAssetMatch: ScanAssetMatch;

  // modal
  @ViewChild(UiModalComponent) public modal: UiModalComponent;

  public scanAsset: ScanAsset;
  public scanRepository: Repository;

  public constructor(
    public severityFormattingService: SeverityFormattingService
  ) {}

  public openDialog(
    scanAssetMatch: ScanAssetMatch,
    scanAsset: ScanAsset,
    scanRepository: Repository
  ): void {
    this.scanAssetMatch = scanAssetMatch;
    this.scanAsset = scanAsset;
    this.scanRepository = scanRepository;
    this.modal.show();
  }
}
