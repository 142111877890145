import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { AuthGuard } from './security/helpers';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: { auth: 'AUTHENTICATED' },
    children: [
      {
        path: '',
        redirectTo: 'security/entity',
        pathMatch: 'full',
      },
      {
        path: 'security',
        loadChildren: () =>
          import('./threat-center/dashboard/dashboard.module').then(
            (module) => module.DashboardModule
          ),
      },
      // {
      //   path: 'compliance',
      //   loadChildren: () =>
      //     import('./compliance-dashboard/compliance-dashboard.module').then(
      //       (m) => m.ComplianceDashboardModule
      //     ),
      // },
      {
        path: 'codecertify',
        loadChildren: () =>
          import(
            './compliance-dashboard-new/compliance-dashboard-new.module'
          ).then((m) => m.ComplianceDashboardNewModule),
      },
      {
        path: 'aicertify',
        loadChildren: () =>
          import(
            './compliance-dashboard-new/compliance-dashboard-new.module'
          ).then((m) => m.ComplianceDashboardNewModule),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin.module').then((module) => module.AdminModule),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./reports/reports.module').then(
            (module) => module.ReportsModule
          ),
      },
      {
        path: 'alerts',
        loadChildren: () =>
          import('./alerts/alerts.module').then(
            (module) => module.AlertsModule
          ),
      },
      {
        path: 'subscription',
        loadChildren: () =>
          import('./subscription/subscription.module').then(
            (m) => m.SubscriptionModule
          ),
      },
    ],
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./security/security.module').then(
            (module) => module.SecurityModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
