import { Component } from '@angular/core';
import { Task } from '@app/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
})
export class TaskComponent {
  obsTask: Observable<Task>;
}
