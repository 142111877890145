import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-time-series-chart',
  templateUrl: './time-series-chart.component.html',
  styleUrls: ['./time-series-chart.component.scss']
})
export class TimeSeriesChartComponent implements OnInit, OnChanges {
    @Input() stroke;
    @Input() series;
    @Input() chart;
    @Input() xaxis;
    @Input() dataLabels;
    @Input() colors;
    @Input() legend;
    @Input() fill;
    @Input() tooltip;
    @Input() noData;

    chartConfig;

    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['series'] && this.series && this.series.length) {
            this.chartConfig = {
                ...this.chart,
                animations: this.animationsConfig
            };
        }
    }

    private get animationsConfig() {
        return this.series.length > 100 ? { enabled: false }
            : this.series.length > 20 ? { enabled: true, animateGradually: { enabled: false }, dynamicAnimation: { enabled: false } }
            : this.series.length > 10 ? { enabled: true, animateGradually: { enabled: false } }
            : { enabled: true };
    }

}
