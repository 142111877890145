import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

import { LicenseCategory } from '@app/models';

@Directive({
  selector: '[appLicenseCategory]'
})
export class LicenseCategoryDirective implements OnChanges {
    @Input() appLicenseCategory;

    constructor(
        private el: ElementRef
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['appLicenseCategory'] && this.appLicenseCategory && LicenseCategory[this.appLicenseCategory]) {
            const element: HTMLSpanElement = this.el.nativeElement;
            element.setAttribute('title', LicenseCategory[this.appLicenseCategory]);
            element.innerText = LicenseCategory[this.appLicenseCategory].toUpperCase();
            element.classList.add('badge');
            element.classList.add('m-r-5');
            if (this.appLicenseCategory === 'PERMISSIVE' || this.appLicenseCategory === 'PUBLIC_DOMAIN') {
                element.classList.add('badge-light-success');
            } else {
                element.classList.add('badge-light-warning');
            }
        }
    }

}
