import { Component, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScanInfoDialogComponent } from '../scan-info-dialog/scan-info-dialog.component';

@Component({
    selector: 'app-project-status-badge',
    templateUrl: './project-status-badge.component.html',
    styleUrls: ['./project-status-badge.component.scss']
})
export class ProjectStatusBadgeComponent implements OnInit {

    @Input()
    @Output()
    scan: any;

    @Input() size: string; // "smaller", "small" or empty string for regular size

    constructor(
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
    }

    getLabelByStatus(status: string) {
        switch (status) {
            case 'SUCCESS': return 'Passed';
            case 'FAIL': return 'Failed';
            case 'WARNING': return 'Warnings';
        }
        return 'Unknown';
    }

    onClick(event: Event) {
        event.stopPropagation();
        const ref = this.modalService.open(ScanInfoDialogComponent, { windowClass: 'md-class', centered: true });
        const { errorMsg, log } = this.scan;
        ref.componentInstance.errorMsg = errorMsg || '';
        ref.componentInstance.log = log || '';
    }

}
