import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { License } from '@app/models';
import { SeverityFormattingService } from '@app/services/severity-formatting.service';

@Component({
  selector: 'app-licenses-grouping-label',
  templateUrl: './licenses-grouping-label.component.html',
  styleUrls: ['./licenses-grouping-label.component.scss'],
})
export class LicensesGroupingLabelComponent implements OnInit {
  @Input() public licenses: Array<License> = [];
  public name: string;
  public severity: string;
  public showBadge: boolean = true;

  @ViewChild('p') public popover: TemplateRef<any>;

  public constructor(
    public severityFormattingService: SeverityFormattingService
  ) {}

  public ngOnInit() {
    if (this.licenses.length > 0) {
      this.severity = this.getLicenseMaxSeverityClass();
      this.name = this.getLicense();
    }
  }

  public getLicenseMaxSeverityClass(): string {
    return this.severityFormattingService.getLicenseMaxSeverityClass(
      this.licenses
    );
  }

  public closePopover(): void {
    (this.popover as any).close();
  }

  public getLicense(): string {
    if (this.licenses.length === 0) {
      this.showBadge = false;
      return '';
    }
    return new Set(this.licenses.map((license) => license.name)).size > 1
      ? 'Multiple'
      : this.licenses[0].name;
  }
}
