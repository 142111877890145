import { Injectable } from '@angular/core';
import { AlertService } from '@app/services/core/alert.service';
import { CoreGraphQLService } from '@app/services/core/core-graphql.service';
import {
  InviteMailData,
  InviteMailDataRequestInput,
  InviteOrganizationDataQuery,
  InviteQuery,
  User,
  UserQuery,
} from '@app/models';
import { AuthenticationService } from '@app/security/services';
import gql from 'graphql-tag';

// TODO remove this as a dependency in NavRightComponent
@Injectable({
  providedIn: 'root',
})
export class InviteService {
  constructor(
    private coreGraphQLService: CoreGraphQLService,
    private authService: AuthenticationService,
    private alertService: AlertService
  ) {}

  // creates invite
  createInvite(): any {
    if (!this.authService.currentUser || !this.authService.currentUser.orgId) {
      this.alertService.alertBox(
        'Organization not found!',
        'Not Found',
        'error'
      );
      return undefined;
    } else {
      const hostUrl = window.location.origin;
      return this.coreGraphQLService.coreGQLReqForMutation(gql`mutation {
        createInvite(orgId: "${this.authService.currentUser.orgId}", hostUrl: "${hostUrl}") {
              orgId,
              username,
              inviteHash,
              expiredDate,
              inviteUrl,
              inviteHash
          }
      }`);
    }
  }

  // fetch invite
  getInvite(inviteHash: string): any {
    const hostUrl = window.location.origin;
    return this.coreGraphQLService.coreGQLReq<InviteQuery>(
      gql(`query {
      getInvite(inviteHash: "${inviteHash}", hostUrl: "${hostUrl}") {
        orgId,
        username,
        inviteHash,
        expiredDate,
        inviteUrl
        inviteHash
      }
    }`),
      'no-cache'
    );
  }

  // fetch invite mail data
  getInviteMailData(inviteHash: string): any {
    const hostUrl = window.location.origin;
    return this.coreGraphQLService.coreGQLReq<InviteQuery>(
      gql(`query {
      getInviteMailData(inviteHash: "${inviteHash}", hostUrl: "${hostUrl}") {
        subject,
        body,
        inviteHash,
        inviteUrl
        }
    }`),
      'no-cache'
    );
  }

  // send invitation email
  sendInviteMail(inviteMailData: InviteMailData): any {
    const inviteMailDataRequest: InviteMailDataRequestInput =
      new InviteMailDataRequestInput(inviteMailData);
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation ($inviteMailDataRequest: InviteMailDataRequestInput) {
          sendInviteMail(inviteMailDataRequest: $inviteMailDataRequest)
        }
      `,
      { inviteMailDataRequest }
    );
  }

  getUserByInvite(inviteHash: string): any {
    return this.coreGraphQLService.coreGQLReq<UserQuery>(
      gql(`query {
      getUserByInvite(inviteHash: "${inviteHash}") {
        orgId,
        username,
        email,
        fname,
        lname,
        position,
        phone,
        created,
      }
    }`),
      'no-cache'
    );
  }

  updateInvitedUser(user: User, inviteHash: string): any {
    const userRequest: InvitedUserRequestInput = new InvitedUserRequestInput(
      user
    );
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation updateInvitedUser(
          $user: InvitedUserRequestInput
          $inviteHash: String
        ) {
          updateInvitedUser(user: $user, inviteHash: $inviteHash)
        }
      `,
      { user: userRequest, inviteHash }
    );
  }

  // fetch organization name for invite
  getOrgDataByInvite(inviteHash: string) {
    return this.coreGraphQLService.coreGQLReq<InviteOrganizationDataQuery>(
      gql(`query {
      inviteOrg(inviteHash: "${inviteHash}") {
          name
        }
      }`),
      'no-cache'
    );
  }
}

export class InvitedUserRequestInput {
  username: string;
  password: string;
  fname: string;
  lname: string;
  email: string;
  orgId: string;
  phone: string;
  position: string;

  constructor(user: User) {
    this.username = user.username;
    this.password = user.password;
    this.email = user.email;
    this.phone = user.phone;
    this.fname = user.fname;
    this.lname = user.lname;
    this.orgId = user.orgId;
    this.position = user.position;
  }
}
