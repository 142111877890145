import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Subject } from 'rxjs';

import { AuthenticationService } from '@app/security/services';

@Injectable({
  providedIn: 'root',
})
export class CoreHelperService {
  private subject = new Subject();
  private unAuthorizeSubject = new Subject<any>();
  private maximizeWidth$ = new BehaviorSubject<boolean>(undefined);
  isBrowserBackclick = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  isUnAuthorize(val: boolean = false) {
    this.unAuthorizeSubject.next(val);
  }

  getUnAuthorizeVal() {
    return this.unAuthorizeSubject.asObservable();
  }

  spinnerEdit(isSpeenerVisible) {
    this.subject.next(isSpeenerVisible);
  }

  getSpinner() {
    return this.subject.asObservable();
  }

  uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      (c: string) => {
        const r: number = (Math.random() * 16) | 0;
        const v: number = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  logoutUser() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  removeNextDarkClassFormBody() {
    document.querySelector('body').classList.remove('next-dark');
  }

  setBrowserBackButton(isBack) {
    this.isBrowserBackclick = isBack;
  }

  getBrowserBackButton() {
    return this.isBrowserBackclick;
  }

  getDifferencebetweenStrings(a: string, b: string): string {
    let i: number = 0;
    let j: number = 0;
    let result: string = '';

    while (j < b.length) {
      if (a[i] !== b[j] || i === a.length) {
        result += b[j];
      } else {
        i++;
      }
      j++;
    }
    return result;
  }

  setFocusOnElement(elementId: string) {
    if (!!elementId && elementId !== '') {
      setTimeout(() => {
        document.getElementById(elementId)?.focus();
      }, 0);
    }
  }

  /** Toggle maximize width. */
  toggleMaximizeWidth() {
    this.maximizeWidth$.next(!this.maximizeWidth$.value);
  }

  /** Reset maximize width. */
  resetMaximizeWidth() {
    this.maximizeWidth$.next(undefined);
  }

  replaceDragAndDropFileFormat(fileName: string): string {
    return fileName
      .replace('.tar.gz', '')
      .replace('.zip', '')
      .replace('.xml', '');
  }

  get isMaximizeWidth() {
    return this.maximizeWidth$.value;
  }

  get isMaximizeWidth$() {
    return this.maximizeWidth$.asObservable();
  }
}

export class LogOutStaticHelper {
  static coreSer: CoreHelperService;
  static logoutUser(coreHelperService: CoreHelperService) {
    coreHelperService.logoutUser();
  }
}
