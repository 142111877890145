<hr/>

<div class="string-search-settings">

    <h4>String Search Settings</h4>


    <!-- FORM ================================================================================================= -->
    <div class="string-search-form">
        <div class="field-wrapper">
            <input
                    required
                    class="form-control"
                    name="stringSearchPattern"
                    #actionData="ngModel"
                    [(ngModel)]="stringSearchPattern"
                    placeholder="String Search Pattern"
            />
            <div class="hint-block text-danger" *ngIf="hasError">String Search Pattern is required.</div>
        </div>

        <div class="field-wrapper">
            <input
                    required
                    class="form-control"
                    name="stringSearchDescription"
                    #actionData="ngModel"
                    [(ngModel)]="stringSearchDescription"
                    placeholder="String Search Description"
            />
        </div>

        <div class="field-wrapper">
            <button
                    type="button"
                    class="btn btn-secondary float-right"
                    title="Add"
                    (click)="saveStringSearchSettings()"
            >
                <i class="fa-solid fa-plus"></i>&nbsp;Add
            </button>
        </div>
    </div>



    <!-- TABLE ================================================================================================= -->

    <br/>
    <br/>

    <p-table
            [columns]="['Pattern', 'Description']"
            [value]="stringSearchSettingsList"
            [paginator]="false"
            [rows]="500"
            tableStyleClass="table table-striped table-bordered dataTable no-footer table-width"
            class="string-search-table"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Pattern</th>
                <th>Description</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-settings let-rowIndex="rowIndex">
            <tr>
                <td class="row-{{rowIndex}}">
                    <input type="hidden" required class="form-control"
                           id="stringSearchPatternKey-{{rowIndex}}"
                           name="stringSearchPatternKey-{{rowIndex}}"
                           value="{{settings.pattern}}" />
                    <input required class="form-control" placeholder="String Search Pattern" [readOnly]="rowIndex !== selectedIndex"
                           id="stringSearchPattern-{{rowIndex}}"
                           name="stringSearchPattern-{{rowIndex}}"
                           value="{{settings.pattern}}" />
                    <div id="error-{{rowIndex}}" class="hint-block text-danger" *ngIf="hasError">String Search Pattern is required.</div>
                </td>
                <td>
                    <input type="hidden" required class="form-control"
                           id="stringSearchDescriptionOriginal-{{rowIndex}}"
                           name="stringSearchDescriptionOriginal-{{rowIndex}}"
                           value="{{settings.description}}" />
                    <input required class="form-control" placeholder="String Search Description" [readOnly]="rowIndex !== selectedIndex"
                           id="stringSearchDescription-{{rowIndex}}"
                           name="stringSearchDescription-{{rowIndex}}"
                           value="{{settings.description}}" />
                </td>

                <!-- actions -->
                <td>
                    <button
                            *ngIf="rowIndex !== selectedIndex"
                            type="button"
                            class="btn btn-icon"
                            title="Edit String Search Settings"
                            (click)="editStringSearchSettings(rowIndex)"
                            style="color: #c9d0d5; padding: 2px; margin: 0 auto"
                    >
                        <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button
                            *ngIf="rowIndex !== selectedIndex"
                            type="button"
                            class="btn btn-icon text-danger"
                            title="Delete String Search Settings"
                            (click)="deleteStringSearchSettings(settings)"
                            style="color: #c9d0d5; padding: 2px; margin: 0 auto"
                    >
                        <i class="fa-solid fa-trash text-danger"></i>
                    </button>

                    <button
                            *ngIf="rowIndex === selectedIndex"
                            type="button"
                            class="btn btn-icon text-success"
                            title="Update String Search Settings"
                            (click)="updateStringSearchSettings(rowIndex)"
                            style="color: #c9d0d5; padding: 2px; margin: 0 auto"
                    >
                        <i class="fa-regular fa-floppy-disk"></i>
                    </button>

                    <button
                            *ngIf="rowIndex === selectedIndex"
                            type="button"
                            class="btn btn-icon"
                            title="Cancel editing String Search Settings"
                            (click)="cancelEditStringSearchSettings(rowIndex)"
                            style="color: #c9d0d5; padding: 2px; margin: 0 auto"
                    >
                        <i class="fa-regular fa-circle-xmark"></i>
                    </button>
                </td>
            </tr>
        </ng-template>
    </p-table>


</div>