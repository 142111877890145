<app-ui-modal
  #releaseTableViewDialog
  dialogClass="modal-dialog-centered popup-Width-fix release-group-dialog"
>
  <!-- HEADER ================================================================================================= -->
  <div class="app-modal-header">
    <h5 class="modal-title" style="color: #293641">
      {{ scanAssetMatch?.name }}
    </h5>
    <button
      type="button"
      class="close basic-close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="releaseTableViewDialog.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- BODY ================================================================================================= -->
  <div class="app-modal-body">
    <perfect-scrollbar [style.max-height]="'550px'" class="overflowshadowTop">
      <p-table
        class="release-table-view-dialog-table"
        #dt
        tableStyleClass="table table-striped table-bordered table-hover dataTable no-footer table-width"
        [value]="scanAssetMatch?.matchGroups.edges"
        [paginator]="false"
        [rows]="100"
        [autoLayout]="false"
        [filterDelay]="0"
        [globalFilterFields]="['name']"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>Asset ID</th>
            <th>Earliest Version/Date</th>
            <th>Latest Version/Date</th>
            <th>Licenses</th>
            <th>Copyrights</th>
            <th>Tools</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-release>
          <tr>
            <td>
              {{ release.node.assetMatchId | slice : 32 }}
            </td>
            <!--| slice:32-->

            <td>
              {{ release.node.earliestReleaseVersion }}
              / {{ release.node.earliestReleaseDate | date : 'MMMM d, y' }}
            </td>

            <td>
              {{ release.node.latestReleaseVersion }}
              / {{ release.node.latestReleaseDate | date : 'MMMM d, y' }}
            </td>

            <td>
              <div
                class="license"
                *ngFor="let license of release.node.licensesByMatchGroup"
              >
                <i
                  class="license-badge {{
                    severityFormattingService.getLicenseBadgeClass(license)
                  }}"
                ></i>
                <span
                  ><a (click)="licenseViewDialog.openDialog(license)">
                    {{ license?.name }}</a
                  ></span
                >
              </div>
            </td>
            <td>
              <ng-container *ngIf="release.node.copyrights?.length === 1">
                {{ release.node.copyrights[0] }}
              </ng-container>
              <ng-container *ngIf="release.node.copyrights?.length > 1">
                <span (click)="listDialog.open(release.node.copyrights)">
                  {{ release.node.copyrights.length }} copyrights
                </span>
              </ng-container>
            </td>
            <td>
              <span
                class="view-button"
                *ngIf="
                  scanRepository !== null &&
                  scanAsset?.assetRepositoryUrl?.data !== null
                "
              >
                <a (click)="sourceView.open(release.node, scanRepository)"
                  >View</a
                >
                |
                <a
                  (click)="
                    simmtool.openWithParams(
                      scanRepository,
                      scanAsset,
                      release.node,
                      simmtool
                    )
                  "
                  >SIMM</a
                >
              </span>
              <span
                *ngIf="
                  scanRepository === null ||
                  scanAsset?.assetRepositoryUrl?.data === null
                "
                ngbTooltip="SIMM is not accessible because source asset content is not available."
              >
                <i class="fa-solid fa-ban text-warning"></i>
              </span>
            </td>

            <!--<td>{{release.node.earliestReleaseVersion}}</td>
                        <td>{{release.node.earliestReleaseDate | date :'MMMM d, y'}}</td>-->
            <!--
                        <td style="border-left:1px solid gray">
                          license

                          <div *ngFor="let license of release?.node.licenses.edges">
                            <span *ngIf="license.node.category === 'Copyleft Strong'" title="Copyleft Strong" class="badge m-r-5 badge-light-danger">COPYLEFT STRONG</span>
                            <span *ngIf="license.node.category === 'Copyleft Weak'" title="Copyleft Weak" class="badge m-r-5 badge-light-warning">COPYLEFT WEAK</span>
                            <span *ngIf="license.node.category === 'Copyleft Partial'" title="Copyleft Partial" class="badge m-r-5 badge-light-warning">COPYLEFT PARTIAL</span>
                            <span *ngIf="license.node.category === 'Copyleft Limited'" title="Copyleft Limited" class="badge m-r-5 badge-light-warning">COPYLEFT LIMITED</span>
                            <span *ngIf="license.node.category === 'Copyleft'" title="Copyleft" class="badge m-r-5 badge-light-warning">COPYLEFT</span>
                            <span *ngIf="license.node.category === 'Permissive'" title="Permissive" class="badge m-r-5 badge-light-success">PERMISSIVE</span>
                            <span *ngIf="license.node.category === 'Proprietary'" title="Proprietary" class="badge m-r-5 badge-light-warning">PROPRIETARY</span>
                            <a (click)="gotoLicense(license.node.licenseId)">{{license.node.shortName}}</a>
                          </div>
                        </td>
                        -->
          </tr>
        </ng-template>
      </p-table>
    </perfect-scrollbar>
  </div>

  <!-- FOOTER ================================================================================================= -->
  <div class="app-modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      (click)="releaseTableViewDialog.hide()"
    >
      Close
    </button>
  </div>
</app-ui-modal>
<app-license-view-dialog #licenseViewDialog></app-license-view-dialog>
<app-simm #simmtool></app-simm>
<app-source-code-view-dialog #sourceView></app-source-code-view-dialog>
<app-copyright-list-dialog #listDialog></app-copyright-list-dialog>
