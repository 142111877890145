<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">Component vulnerabilities</h5>
</div>
<div style="display: flex">
  <div class="component-info">
    <span>Group: {{ componentData.group }}</span> {{ severity }}
    <span>Project: {{ componentData.name }}</span>
    <span>Version: {{ componentData.version }}</span>
  </div>
  <div
    class="upgrade-section"
    *ngIf="
      componentData.componentType !== 'ASSET' && patchedVersions as patchedVer
    "
  >
    <div class="vertical">
      <span class="titles-small no-whitespace">Upgrade to secure version</span>
      <div class="vertical">
        <div class="form-group" style="margin-bottom: 5px">
          <label class="radio radio-primary d-inline">
            <input
              [(ngModel)]="newVersion"
              type="radio"
              [value]="patchedVer.nextPatchedVersion"
              [name]="componentData.componentId + '-next'"
              id="versionRedio2"
            />
            <span for="versionRedio2" class="cr"
              >Next secure version
              <span class="badge m-r-5 badge-light-success">{{
                patchedVer.nextPatchedVersion
              }}</span></span
            >
          </label>
        </div>
        <div class="form-group" style="margin-bottom: 5px">
          <label class="radio radio-primary d-inline">
            <input
              [(ngModel)]="newVersion"
              type="radio"
              [value]="patchedVer.latestPatchedVersion"
              [name]="componentData.componentId + '-latest'"
              id="versionRedio1"
            />
            <span for="versionRedio1" class="cr"
              >Latest secure version
              <span class="badge m-r-5 badge-light-success">{{
                patchedVer.latestPatchedVersion
              }}</span></span
            >
          </label>
        </div>
      </div>
    </div>
    <button
      style="margin-top: 38px; height: 33px"
      type="button"
      class="btn btn-success"
      (click)="fixVersion()"
    >
      Fix
    </button>
  </div>
</div>
<div class="vulnerabilites-data">
  <div class="line header">
    <div style="width: 15%">CVE</div>
    <div style="width: 40%">CWE</div>
    <div style="width: 20%">CVSS3</div>
    <div style="width: 20%">EPSS</div>
    <div style="width: 5%; text-align: center">Fix</div>
  </div>

  <perfect-scrollbar
    [style.max-height]="'208px'"
    style="padding-right: 10px"
    *ngIf="componentData.vulnerabilities.edges.length > 0; else noVulns"
  >
    <div
      class="line vulnerability"
      (click)="goToVulnerability(vulnerability.node.vulnerabilityAlias)"
      [class]="'vulnerability-' + vulnerability.node.severity"
      *ngFor="let vulnerability of componentData.vulnerabilities.edges"
    >
      <div style="width: 15%">{{ vulnerability.node.vulnerabilityAlias }}</div>
      <div
        style="width: 40%"
        class="cwe-column"
        [ngbTooltip]="
          'CWE-' +
          vulnerability.node?.cwe?.cweId +
          ':' +
          vulnerability.node?.cwe?.name
        "
        container="body"
      >
        CWE-{{ vulnerability.node?.cwe?.cweId }}:
        {{ vulnerability.node?.cwe?.name }}
      </div>
      <div style="width: 15%">{{ vulnerability.node.cvssV3BaseScore }}</div>
      <div style="width: 25%">
        {{ vulnerability?.node?.epss?.epssPercent }}% ({{
          vulnerability?.node?.epss?.percentilePercent
            | toFixed : 0 : direction.UP
        }}th %ile)
      </div>
      <div style="width: 5%">
        <i class="fa-solid fa-circle-check" style="color: #10c610"></i>
      </div>
    </div>
  </perfect-scrollbar>
  <ng-template #noVulns>
    <div role="alert" class="alert alert-primary">
      There are no detected vulnerabilities
    </div>
  </ng-template>
</div>
