<div class="entity-tab-chart">
    <div class="row">
        <div class="col-12">
            <apx-chart *ngIf="series?.length"
                [stroke]="stroke"
                [series]="series"
                [chart]="chartConfig"
                [xaxis]="xaxis"
                [dataLabels]="dataLabels"
                [colors]="colors || []"
                [legend]="legend"
                [fill]="fill"
                [tooltip]="tooltip"
                [noData]="noData"
            ></apx-chart>
        </div>
    </div>
</div>
