import { PaymentMethod } from './PaymentMethod';

export class Subscription {
  subscriptionId: string;
  subscriptionPlan: SubscriptionPlan;
  product: ProductCode;
  plan: Plan;
  amount: number;
  licensedUserCount: number;
  startDate: Date;
  lastPaymentDate: Date;
  autoRenew: boolean;
  status: SubscriptionStatus;
  paymentMethod: PaymentMethod;
}

export class SubscriptionPlan {
  code: string | null;
  product: ProductCode;
  plan: Plan;
  planInterval: PlanInterval;
  stripePriceId: string;

  constructor(plan: Plan, product: ProductCode, planInterval: PlanInterval) {
    this.plan = plan;
    this.product = product;
    this.planInterval = planInterval;
  }
}

export enum ProductCode {
  SECURECORE = 'SECURECORE',
  AICERTIFY = 'AICERTIFY',
  CODECERTIFY = 'CODECERTIFY',
}

export enum Plan {
  PRO = 'PRO',
  TEAM = 'TEAM',
  ENTERPRISE = 'ENTERPRISE',
}

export enum PlanInterval {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL',
}

export enum SubscriptionStatus {
  active = 'Active',
  incomplete = 'Incomplete',
  incomplete_expired = 'Incomplete Expired',
  unknown = 'Unknown',
  trialing = 'Trialing',
  past_due = 'Past Due',
  canceled = 'Canceled',
  unpaid = 'Unpaid',
}
