import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GithubOrgReposQuery, GithubUserReposQuery } from '@app/models';
import { ApolloQueryResult, FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { CoreGraphQLService } from './core/core-graphql.service';
import { Observable } from 'rxjs';
import { GraphQLPage } from '@app/models/paging';

const RepositoryQuery = `
    repositories {
        edges {
            node {            
                id,
                name,
                archived,
                fork,
                private,
                resourcePath,
                sshUrl,
                url,
                primaryLanguage{
                    color,
                    name
                },
                defaultBranchRef {
                    name
                }
                refs {
                    totalCount
                    edges {
                        node {
                            ... on Ref {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

@Injectable()
export class RepositoryService {
  constructor(
    private coreGraphQLService: CoreGraphQLService,
    private http: HttpClient
  ) {}

  fetchAsset(url: string) {
    return this.http.get<any>(url);
  }

  fetchAuthenticatedAsset(url: string, accountToken: string) {
    const headers = { Authorization: `Bearer ${accountToken}` };
    return this.http.get<any>(url, { headers });
  }

  getGithubUserRepos(fetchPolicy: FetchPolicy = 'cache-first') {
    return this.coreGraphQLService.coreGQLReqWithQuery<GithubUserReposQuery>(
      gql`
                query {
                    githubUserRepos {            
                        ${RepositoryQuery}
                    }
                }
            `,
      fetchPolicy
    );
  }

  getGithubOrgRepos(login: string, fetchPolicy: FetchPolicy = 'cache-first') {
    return this.coreGraphQLService.coreGQLReqWithQuery<GithubOrgReposQuery>(
      gql`
                query {
                    githubOrgRepos(login: "${login}") {            
                        ${RepositoryQuery}
                    }
                }
            `,
      fetchPolicy
    );
  }

  public getRepositoryBranches(
    registrationId: string,
    owner: string,
    repository: string,
    searchString?: string,
    next?: string,
  ): Observable<
    ApolloQueryResult<{
      repositoryBranches: {
        branches: Array<string>;
        next: string;
        prev: string;
      };
    }>
  > {
    const page: string = next ? `"${next}"` : 'null';
    const search: string = searchString ? `"${searchString}"` : 'null';
    return this.coreGraphQLService.coreGQLReq<{
      repositoryBranches: {
        branches: Array<string>;
        next: string;
        prev: string;
      };
    }>(
      gql`
            query {
                repositoryBranches(
                    registrationId: "${registrationId}", 
                    owner: "${owner}", 
                    repository: "${repository}",
                    page: ${page},
                    search: ${search}
                  ) {
                    branches
                    next
                    prev
                  }
            }
          `,
      'no-cache'
    );
  }

  public repoListForPath(
    entityId: string,
    scanId: string,
    assetPath: string,
    graphQLPage: GraphQLPage,
    filter?: string
  ) {
    let params: string = scanId ? `, scanId: "${scanId}"` : '';
    params += assetPath ? `, assetPath: "${assetPath}"` : '';
    params += `, first: ${graphQLPage.first}`;
    params += graphQLPage.after ? `, after: "${graphQLPage.after}"` : '';
    const filterParams: string = filter ? `, filter: "${filter}"` : '';
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        query {
          repoListForPath(entityId: "${entityId}"${params} ${filterParams}) {
            edges {
              node {
                scanId
                entityId
                repoName
                repoOwner
                repoUrl
                repositoryId
                versions
                orgId
                purlType
                status
                assetsCount 
                vulnerabilities {
                  severity
                  vulnerabilityAlias
                }
                licenseMetrics {
                  publicDomain
                  copyleftPartial
                  copyleftLimited
                  copyleft
                  copyleftStrong
                  copyleftWeak
                  custom
                  dual
                  permissive
                  proprietary
                  proprietaryFree
                }
                licenses {
                  category
                  licenseId
                  name
                  version
                  type
                }
              }
            },
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
          }
        }`,
      'no-cache'
    );
  }
}
