import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisableIfUnauthorizedDirective } from '@app/security/disable-if-unauthorized.directive';
import { HideIfUnauthorizedDirective } from '@app/security/hide-if-unauthorized.directive';
import { RemoveIfUnauthorizedDirective } from '@app/security/remove-if-unauthorized.directive';
import { ReleaseTableViewDialogComponent } from '@app/shared/components/release-table-view-dialog/release-table-view-dialog.component';
import { ModalModule } from '@app/theme/shared/components';
import { SimmComponent } from '@app/threat-center/shared/simm/simm.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MarkdownModule } from 'ngx-markdown';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TableModule, TooltipModule } from 'primeng';
import { AssetsDonutChartComponent } from './charts/assets-donut-chart/assets-donut-chart.component';
import { CommonDonutChartComponent } from './charts/common-donut-chart/common-donut-chart.component';
import { ComponentsDonutChartComponent } from './charts/components-donut-chart/components-donut-chart.component';
import { DonutChartComponent } from './charts/donut-chart/donut-chart.component';
import { LicensesDonutChartComponent } from './charts/licenses-donut-chart/licenses-donut-chart.component';
import { VulnsDonutChartComponent } from './charts/vulns-donut-chart/vulns-donut-chart.component';
import { AssetsTableComponent } from './components/assets-table/assets-table.component';
import { CopyrightAddDialogComponent } from './components/copyright-add-dialog/copyright-add-dialog.component';
import { CopyrightListDialogComponent } from './components/copyright-list-dialog/copyright-list-dialog.component';
import { CopyrightViewDialogComponent } from './components/copyright-view-dialog/copyright-view-dialog.component';
import { LicenseAddDialogComponent } from './components/license-add-dialog/license-add-dialog.component';
import { LicenseUrlViewDialogComponent } from './components/license-url-view-dialog/license-url-view-dialog.component';
import { LicenseViewDialogComponent } from './components/license-view-dialog/license-view-dialog.component';
import { ProjectStatusBadgeComponent } from './components/project-status-badge/project-status-badge.component';
import { ScanInfoDialogComponent } from './components/scan-info-dialog/scan-info-dialog.component';
import { SourceCodeViewDialogComponent } from './components/source-code-view-dialog/source-code-view-dialog.component';
import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from './pipes/pipes.module';
import { TimeSeriesChartComponent } from './charts/time-series-chart/time-series-chart.component';
import { ThemeSharedModule } from '@app/theme/shared/theme-shared.module';
import { ScanHistoryModalComponent } from './components/scan-history-modal/scan-history-modal.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RadialBarChartComponent } from './charts/radial-bar-chart/radial-bar-chart.component';
import { VulnerabilitiesInfoDialogComponent } from './components/vulnerabilities-info-dialog/vulnerabilities-info-dialog.component';
import { ToFixedPipe } from './pipes/round-number.pipe';
import { FixComponentResultDialogComponent } from './components/fix-component-result-dialog/fix-component-result-dialog.component';
import { ComponentVulnerabilitiesInfoNewDialogComponent } from './components/component-vulnerabilities-info-new-dialog/component-vulnerabilities-info-new-dialog.component'; // eslint-disable-line
import { RiskScorePopupComponent } from './components/risk-score-popup/risk-score-popup.component';
import { MetricsChipComponent } from './components/metrics-chip/metrics-chip.component';
import { SemiCircleChartComponent } from './charts/semi-circle-chart/semi-circle-chart.component';
import { IndeterminateCheckboxComponent } from './components/indeterminate-checkbox/indeterminate-checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AlreadyAttachedModalComponent } from './components/already-attached-modal/already-attached-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { StringSearchSettingsComponent } from './components/string-search-settings/string-search-settings.component';
import { StringSearchSettingsDialogComponent } from './components/string-search-settings-dialog/string-search-settings-dialog.component';
import { NewAssetsTableComponent } from './components/new-assets-table/new-assets-table.component';
import { LicensesGroupingLabelComponent } from './components/licenses-grouping-label/licenses-grouping-label.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    NgbModule,
    ModalModule,
    TableModule,
    NgApexchartsModule,
    MarkdownModule.forRoot(),
    ThemeSharedModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    TooltipModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    DialogModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    TableModule,
    ReactiveFormsModule,
    DisableIfUnauthorizedDirective,
    HideIfUnauthorizedDirective,
    RemoveIfUnauthorizedDirective,
    LicenseViewDialogComponent,
    CopyrightViewDialogComponent,
    LicenseAddDialogComponent,
    CopyrightAddDialogComponent,
    ReleaseTableViewDialogComponent,
    AssetsDonutChartComponent,
    LicensesDonutChartComponent,
    VulnsDonutChartComponent,
    LicenseUrlViewDialogComponent,
    ScanInfoDialogComponent,
    SimmComponent,
    SourceCodeViewDialogComponent,
    LicenseUrlViewDialogComponent,
    ProjectStatusBadgeComponent,
    ComponentsDonutChartComponent,
    CommonDonutChartComponent,
    DirectivesModule,
    PipesModule,
    AssetsTableComponent,
    CopyrightListDialogComponent,
    TimeSeriesChartComponent,
    ScanHistoryModalComponent,
    RadialBarChartComponent,
    VulnerabilitiesInfoDialogComponent,
    ToFixedPipe,
    FixComponentResultDialogComponent,
    ComponentVulnerabilitiesInfoNewDialogComponent,
    RiskScorePopupComponent,
    MetricsChipComponent,
    SemiCircleChartComponent,
    IndeterminateCheckboxComponent,
    AlreadyAttachedModalComponent,
    StringSearchSettingsComponent,
    NewAssetsTableComponent,
    LicensesGroupingLabelComponent,
  ],
  declarations: [
    DisableIfUnauthorizedDirective,
    HideIfUnauthorizedDirective,
    RemoveIfUnauthorizedDirective,
    ReleaseTableViewDialogComponent,
    LicenseViewDialogComponent,
    CopyrightViewDialogComponent,
    LicenseAddDialogComponent,
    CopyrightAddDialogComponent,
    DonutChartComponent,
    AssetsDonutChartComponent,
    LicensesDonutChartComponent,
    VulnsDonutChartComponent,
    LicenseUrlViewDialogComponent,
    ScanInfoDialogComponent,
    SimmComponent,
    SourceCodeViewDialogComponent,
    ProjectStatusBadgeComponent,
    ComponentsDonutChartComponent,
    CommonDonutChartComponent,
    AssetsTableComponent,
    CopyrightListDialogComponent,
    TimeSeriesChartComponent,
    ScanHistoryModalComponent,
    RadialBarChartComponent,
    VulnerabilitiesInfoDialogComponent,
    ToFixedPipe,
    FixComponentResultDialogComponent,
    ComponentVulnerabilitiesInfoNewDialogComponent,
    RiskScorePopupComponent,
    MetricsChipComponent,
    SemiCircleChartComponent,
    IndeterminateCheckboxComponent,
    AlreadyAttachedModalComponent,
    StringSearchSettingsComponent,
    StringSearchSettingsDialogComponent,
    NewAssetsTableComponent,
    LicensesGroupingLabelComponent,
  ],
  providers: [],
})
export class SharedModule {}
