import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProjectBreadcrumbsService {
  /**
   *
   * @param tag
   * @param name
   * @param id
   * @param isFromComponent
   * To Set project breadcrumbs to session storage
   */
  public settingProjectBreadcrumb(
    tag: string,
    name: string,
    id: string,
    isFromComponent: boolean = false
  ): void {
    let projectBreadcrumb: {
      [key: string]: { id: string; name: string } | boolean;
    } = {};
    if (!!localStorage.getItem('ProjectBreadcrumb')) {
      // update
      projectBreadcrumb = JSON.parse(localStorage.getItem('ProjectBreadcrumb'));
    }
    if (tag === 'Project') {
      projectBreadcrumb['SelectedProject'] = { id, name };
    } else if (tag === 'Entity') {
      projectBreadcrumb['SelectedEntity'] = { id, name };
    } else if (tag === 'Component') {
      projectBreadcrumb['SelectedComponent'] = { id, name };
    } else if (tag === 'License') {
      projectBreadcrumb['SelectedLicense'] = { id, name };
    }
    projectBreadcrumb['IsFromComponent'] = isFromComponent;
    localStorage.setItem(
      'ProjectBreadcrumb',
      JSON.stringify(projectBreadcrumb)
    );
  }

  //get breadcrumb details from storage file
  public getProjectBreadcrumb(): {
    [key: string]: { id: string; name: string } | boolean;
  } {
    if (!!localStorage.getItem('ProjectBreadcrumb')) {
      return JSON.parse(localStorage.getItem('ProjectBreadcrumb'));
    }
    return null;
  }
}
