import { Component, OnInit } from '@angular/core';
import { License } from '@app/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-license-view-dialog',
  templateUrl: './license-view-dialog.component.html',
  styleUrls: ['./license-view-dialog.component.scss'],
})
export class LicenseViewDialogComponent implements OnInit {
  license: License;
  permissions: any[];
  limitations: any[];
  conditions: any[];

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  openDialog(license: License) {
    const modalRef = this.modalService.open(LicenseViewDialogComponent, {
      keyboard: false,
      size: 'xl',
    });
    modalRef.componentInstance.license = license;
    modalRef.componentInstance.permissions = this.licenseAttributeFilter(
      license,
      'PERMISSION'
    );
    modalRef.componentInstance.limitations = this.licenseAttributeFilter(
      license,
      'LIMITATION'
    );
    modalRef.componentInstance.conditions = this.licenseAttributeFilter(
      license,
      'CONDITION'
    );
  }

  licenseAttributeFilter(license: any, type: string): any {
    if (!license.attributes) {
      return [];
    }
    return license.attributes.filter((attribute) => attribute.type == type);
  }
}
