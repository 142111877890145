import { Component, OnInit } from '@angular/core';
import { CoreHelperService } from '@app/services/core/core-helper.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  public constructor(private coreHelperService: CoreHelperService) {}

  public ngOnInit(): void {
    this.coreHelperService.removeNextDarkClassFormBody();
  }
}
