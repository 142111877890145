import { Injectable } from '@angular/core';
import { CoreGraphQLService } from '@app/services/core/core-graphql.service';
import gql from 'graphql-tag';
import { ScanLicense } from '@app/models';

@Injectable()
export class LicenseService {
  constructor(private coreGraphQLService: CoreGraphQLService) {}

  public getLicenseByName(name: string) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`query {
      licensesByNameFilter(filter:"${name}") {
        licenseId,
        name,
        category,
        body
      }
    }`,
      'no-cache'
    );
  }

  public getLicense(scanLicense: ScanLicense) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
            query ($isOrgLicense: Boolean) {
                getLicenseForOrgAndLicenseId(
                    orgId: "${scanLicense.orgId}",
                    licenseId: "${scanLicense.licenseId}",
                    isOrgLicense: $isOrgLicense
                ) {
                    licenseId,
                    name,
                    category,
                    body
                }
            }`,
      'no-cache',
      { isOrgLicense: scanLicense.isOrgLicense }
    );
  }

  public getLicenseURI(scanComponent, licenseId) {
    const scanComponentInput = {
      purl: scanComponent.purl,
    };
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        query ($scanComponentInput: ScanComponentInput, $licenseId: String) {
          licenseURI(scanComponent: $scanComponentInput, licenseId: $licenseId)
        }
      `,
      'no-cache',
      { scanComponentInput, licenseId }
    );
  }

  public licenseListForPath(
    entityId: string,
    scanId: string,
    assetPath: string,
    assetsLicenseOnly: boolean = false,
    filters?: string,
  ) {
    let params: string = entityId ? `, entityId: "${entityId}"` : '';
    params += scanId ? `, scanId: "${scanId}"` : '';
    params += assetPath ? `, assetPath: "${assetPath}"` : '';
    params += filters ? `, filter: "${filters}"` : '';

    return this.coreGraphQLService.coreGQLReq<any>(
      gql`query {
        licenseListForPath(assetsLicenseOnly: ${assetsLicenseOnly}, ${params}) {
          licenseList {
            licenseId
            name
            category
            licenseDiscovery
            licenseOrigin
          }
          licenseMetrics {
            copyleft
            copyleftLimited
            copyleftPartial
            copyleftStrong
            copyleftWeak
            custom
            dual
            permissive
            proprietary
            proprietaryFree
            publicDomain
          }
        }
      }`,
      'no-cache'
    );
  }

  public componentLicensesForPath(
    entityId: string,
    scanId: string,
    assetPath: string,
    filters?: string
  ) {
    let params: string = entityId ? `entityId: "${entityId}"` : '';
    params += scanId ? `, scanId: "${scanId}"` : '';
    params += assetPath ? `, assetPath: "${assetPath}"` : '';
    params += filters ? `, filters: "${filters}"` : '';

    return this.coreGraphQLService.coreGQLReq<any>(
      gql`query {
        componentLicensesForPath(${params}) {
          licenseList {
            category
              license {
                category
                cleanName
                compatible
                name
                version
                type
              }
          licenseId
            name
        type
        licenseDiscovery
        licenseOrigin
       }
       licenseMetrics {
        copyleft
        copyleftLimited
        copyleftPartial
        copyleftStrong
        copyleftWeak
        custom
        dual
        permissive
        proprietary
        proprietaryFree
        publicDomain
      }
      }
    }`,
      'no-cache'
    );
  }

}
