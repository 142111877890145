import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';

@Directive({
    selector: '[appSeverityBadge]'
})
export class SeverityBadgeDirective implements OnInit, OnChanges {
    @Input() appSeverityBadge;

    constructor(
        private ref: ElementRef,
    ) { }

    ngOnInit(): void {
        this.ref.nativeElement.classList.add('badge');
        this.ref.nativeElement.classList.add('m-r-5');
    }

    ngOnChanges(): void {
        switch (this.appSeverityBadge) {
            case 'NONE':
                this.ref.nativeElement.classList.add('badge-light-info');
                break;
            case 'LOW':
                this.ref.nativeElement.classList.add('badge-light-primary');
                break;
            case 'MEDIUM':
                this.ref.nativeElement.classList.add('badge-light-warning');
                break;
            case 'HIGH':
            case 'CRITICAL':
                this.ref.nativeElement.classList.add('badge-light-danger');
                break;
            default:
                break;
        }
    }

}
