import { Injectable } from '@angular/core';
import { CoreGraphQLService } from '@app/services/core';
import gql from 'graphql-tag';
import { StringSearchSettings } from '@app/models/string-search-setting';

@Injectable({
  providedIn: 'root'
})
export class StringSearchService {

  constructor(
    private coreGraphQLService: CoreGraphQLService
  ) { }


  public saveStringSearchSettings(pattern: string, description: string, objectId: string, level: string) {
    const settingsInput = new StringSearchSettings(pattern, description, objectId, level);
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation saveStringSearchSetting($stringSearchSetting: StringSearchSettingInput!) {
          saveStringSearchSetting(stringSearchSetting: $stringSearchSetting)
        }
      `,
      { stringSearchSetting: settingsInput }
    );
  }


  public deleteStringSearchSettings(settings: StringSearchSettings) {
    delete settings['__typename'];
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation deleteStringSearchSetting($stringSearchSetting: StringSearchSettingInput!) {
          deleteStringSearchSetting(stringSearchSetting: $stringSearchSetting)
        }
      `,
      { stringSearchSetting: settings }
    );
  }


  public stringSearchSettingsForLevel(objectId: string, level: string) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        query stringSearchSettingsForLevel($objectId: UUID, $level: SearchStringLevel) {
          stringSearchSettingsForLevel(objectId: $objectId, level: $level) {
            pattern
            description
            objectId
            level
          }
        }
      `,
      'no-cache',
      {
        objectId,
        level
      }

    );
  }

  public selectStringSearchSettings(entityId: string, scpProjectId: string, moduleId: string) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        query StringSearchSettings($entityId: UUID, $scpProjectId: UUID, $moduleId: UUID) {
          stringSearchSettings(entityId: $entityId, scpProjectId: $scpProjectId, moduleId: $moduleId) {
            pattern
            description
            objectId
            level
          }
        }
      `,
      'no-cache',
      {
        entityId,
        scpProjectId,
        moduleId
      }
    );
  }

}
