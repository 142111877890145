import { Injectable } from '@angular/core';
import {
  License,
  ScanVulnerabilityEdge,
  Vulnerability,
  VulnerabilityEdge,
} from '@app/models';

@Injectable()
export class SeverityFormattingService {
  public LICENSE_SEVERITY_INDEX = {
    COPYLEFT_STRONG: 2,

    COPYLEFT_WEAK: 1,
    COPYLEFT_PARTIAL: 1,
    COPYLEFT_LIMITED: 1,
    COPYLEFT: 1,
    PROPRIETARY_FREE: 1,
    PROPRIETARY: 1,
    UNDEFINED: 1,

    PUBLIC_DOMAIN: 0,
    PERMISSIVE: 0,
  };

  private LICENSE_SEVERITY_CLASS_BY_INDEX = ['success', 'warning', 'danger'];

  private VULN_SEVERITY_INDEX = {
    UNASSIGNED: 0,
    INFO: 1,
    LOW: 2,
    MEDIUM: 3,
    HIGH: 4,
    CRITICAL: 5,
  };

  private VULN_SEVERITY_CLASS_BY_INDEX = [
    '',
    'info',
    'low',
    'medium',
    'high',
    'critical',
  ];

  getLicenseBadgeClass(license) {
    const category =
      license == null || license.category == null
        ? 'COPYLEFT_STRONG'
        : license.category;
    return this.LICENSE_SEVERITY_CLASS_BY_INDEX[
      this.LICENSE_SEVERITY_INDEX[category]
    ];
  }

  getLicenseBadgeClassForMatches(licenseCategory: string) {
    return this.LICENSE_SEVERITY_CLASS_BY_INDEX[
      this.LICENSE_SEVERITY_INDEX[licenseCategory]
    ];
  }

  getLicenseMaxSeverityClass(licenses: any) {
    let max: number = 0;
    for (const license of licenses) {
      const current =
        this.LICENSE_SEVERITY_INDEX[
          license.category ?? license.licenseCategory
        ];
      if (max < current) {
        max = current;
      }
    }
    return this.LICENSE_SEVERITY_CLASS_BY_INDEX[max];
  }

  getVulnMaxSeverityClass(
    vulnerabilityConnection: ScanVulnerabilityEdge /* | VulnerabilityEdge[] | Vulnerability[]*/
  ) {
    const vulnerabilities: Array<Vulnerability> =
      vulnerabilityConnection.edges.map((e: VulnerabilityEdge) => e.node);

    let max: number = 0;
    for (const vuln of vulnerabilities) {
      const current = this.VULN_SEVERITY_INDEX[vuln.severity];
      if (max < current) {
        max = current;
      }
    }
    return this.VULN_SEVERITY_CLASS_BY_INDEX[max];
  }
}
