import { Injectable } from '@angular/core';
import { CoreGraphQLService } from '@app/services/core';
import { NavigationItem, NavigationItems } from './navigation.model';
import gql from 'graphql-tag';
import { AuthenticationService } from '@app/security/services';
import { ProductCode } from '@app/subscription/models/Subscription';
import { SubscriptionService } from '@app/subscription/services/subscription.service';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  public constructor(
    private coreGraphQLService: CoreGraphQLService,
    private authenticationService: AuthenticationService,
    private subscriptionService: SubscriptionService
  ) {
    this.subscriptionService.subscriptionUpdated.subscribe((value) =>
      this.checkProducts()
    );
  }

  public getNavigationItems(): Array<NavigationItem> {
    this.checkProducts();
    return NavigationItems;
  }

  checkProducts() {
    const product =
      this.authenticationService?.currentUser?.organization?.subscription
        ?.product;
    if (product && product === ProductCode.AICERTIFY) {
      const child = NavigationItems[0].children.find((x) => x.id === 'scan');
      child.hidden = true;
    }
  }

  public canEditDeploymentSettings() {
    return this.coreGraphQLService.coreGQLReq<{
      canEditDeploymentSettings: { data: string };
    }>(
      gql`
        query {
          canEditDeploymentSettings {
            data
          }
        }
      `
    );
  }
}
