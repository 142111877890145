<app-ui-modal #copyrightAddDialog>
    <!-- HEADER ================================================================================================= -->
    <div class="app-modal-header">
        <h5 class="modal-title">Copyright Adding Dialog</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="copyrightAddDialog.hide()"><span aria-hidden="true">&times;</span></button>
    </div>

    <!-- BODY ================================================================================================= -->
    <div class="app-modal-body">
        <!-- TEXT -->
        <div class="info-cols"><small>Copyright text</small></div>
        <textarea [(ngModel)]="copyrightText" type="text" class="form-control compliance-dashboard-add-license-dialog-input" style="height: max-content; min-height: 200px"></textarea><!-- todo: ref: move style into scss -->
    </div>


    <!-- FOOTER ================================================================================================= -->
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="copyrightAddDialog.hide()">Close</button>
        <button type="button" class="btn btn-primary ml-2" (click)="saveCopyright()">Add Copyright</button>
    </div>
</app-ui-modal>
