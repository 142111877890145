import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { Observable } from 'rxjs';
import { CoreHelperService } from '@app/services/core/core-helper.service';

import { NextConfig } from '../../../app-config';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  public nextConfig: any;
  public navCollapsed: boolean;
  public navCollapsedMob: boolean;
  public windowWidth: number;
  public isUnAuthorize: boolean = false;
  public isMaximizeWidth$: Observable<boolean>;
  public isApiExplorerPage: boolean = false;
  public currentURL: string;
  public loaded: boolean = false;
  public loading: boolean = true;

  public constructor(
    private location: Location,
    private coreHelperService: CoreHelperService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    this.coreHelperService.getUnAuthorizeVal().subscribe((val: boolean) => {
      this.isUnAuthorize = val;
    });
    this.nextConfig = NextConfig.config;
    this.currentURL = this.location.path();
    this.isApiExplorerPage = this.currentURL.indexOf('api-explorer') > -1;
    const baseHerf: string = this.location['_baseHref'];
    if (baseHerf) {
      this.currentURL = baseHerf + this.location.path();
    }
    this.windowWidth = window.innerWidth;

    if (
      this.currentURL === baseHerf + '/layout/collapse-menu' ||
      this.currentURL === baseHerf + '/layout/box' ||
      (this.windowWidth >= 992 && this.windowWidth <= 1024)
    ) {
      this.nextConfig.collapseMenu = true;
    }
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }

  public ngOnInit(): void {
    this.navCollapsed =
      this.windowWidth >= 992 ? this.nextConfig.collapseMenu : false;
    this.navCollapsedMob = false;

    if (this.windowWidth < 992) {
      this.nextConfig.layout = 'vertical';
      setTimeout(() => {
        document
          .querySelector('.pcoded-navbar')
          .classList.add('menupos-static');
        (
          document.querySelector('#nav-ps-next') as HTMLElement
        ).style.maxHeight = '100%'; // 100% amit
      }, 500);
    }

    this.isMaximizeWidth$ = this.coreHelperService.isMaximizeWidth$;
    this.loaded = true;
  }

  public navMobClick(): void {
    if (this.windowWidth < 992) {
      if (
        this.navCollapsedMob &&
        !document
          .querySelector('app-navigation.pcoded-navbar')
          .classList.contains('mob-open')
      ) {
        this.navCollapsedMob = !this.navCollapsedMob;
        setTimeout(() => {
          this.navCollapsedMob = !this.navCollapsedMob;
        }, 100);
      } else {
        this.navCollapsedMob = !this.navCollapsedMob;
      }
    }
  }

  // Shows and hides the loading spinner during RouterEvent changes
  private navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
      this.cdRef.detectChanges();
    }
    if (event instanceof NavigationEnd) {
      this.isApiExplorerPage = event.url.indexOf('api-explorer') > -1;
      this.loading = false;
      this.cdRef.detectChanges();
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
      this.cdRef.detectChanges();
    }
    if (event instanceof NavigationError) {
      this.loading = false;
      this.cdRef.detectChanges();
    }
  }
}
