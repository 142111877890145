import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Vulnerability } from '@app/models';
import { Direction } from '@app/shared/pipes/round-number.pipe';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-vulnerabilities-info-dialog',
  templateUrl: './vulnerabilities-info-dialog.component.html',
  styleUrls: ['./vulnerabilities-info-dialog.component.scss'],
})
export class VulnerabilitiesInfoDialogComponent {
  public componentData;
  public vulnerabilities: Array<Vulnerability>;
  public secureVersions: {
    [key: string]: {
      namespace: string;
      name: string;
      vulnerableVersion: string;
      nextPatchedVersion: string;
      latestPatchedVersion: string;
    };
  };

  public route;
  public direction: typeof Direction = Direction;

  public constructor(
    private router: Router,
    public activeModal: NgbActiveModal
  ) {}

  public getComponentName(): string {
    return `${this.componentData?.group ?? ''}:${this.componentData.name}@${
      this.componentData.version
    }`;
  }

  public gotoDetails(vulId: string): void {
    const entityId: string = this.route.snapshot.paramMap.get('entityId');
    const projectId: string = this.route.snapshot.paramMap.get('projectId');
    const scanId: string =
      this.route.snapshot.paramMap.get('scanId') || this.componentData.scanId;
    const url: string =
      `security/entity/${entityId}/project/${projectId}` +
      `/scan/${scanId}/vulnerability/${vulId}`;
    this.router.navigate([decodeURIComponent(url)], {
      queryParams: { isFromCompliance: true },
    });
    this.activeModal.close();
  }
}
