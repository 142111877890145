<div id="card">
  <div id="chart">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [plotOptions]="chartOptions.plotOptions"
      [labels]="chartOptions.labels"
      [stroke]="chartOptions.stroke"
      [fill]="chartOptions.fill"
    ></apx-chart>
  </div>
</div>
