import { Component, OnDestroy, OnInit } from '@angular/core';
import { FixResult } from '@app/models';
import { FixService } from '@app/services/fix.service';
import { Direction } from '@app/shared/pipes/round-number.pipe';
import { PatchedInfoSimplified } from '@app/threat-center/shared/models/types';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { FixComponentResultDialogComponent } from '../fix-component-result-dialog/fix-component-result-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '@app/services/navigation.service';

@Component({
  selector: 'app-component-vulnerabilities-info-new-dialog',
  templateUrl: './component-vulnerabilities-info-new-dialog.component.html',
  styleUrls: ['./component-vulnerabilities-info-new-dialog.component.scss'],
})
export class ComponentVulnerabilitiesInfoNewDialogComponent
  implements OnInit, OnDestroy
{
  public componentData;
  public scanId: string;
  public entityId: string;
  public projectId: string;
  public newVersion: string;
  public severity: string;

  public direction: typeof Direction = Direction;

  public patchedVersions: PatchedInfoSimplified;
  public loading: boolean = true;

  public subscription: Subscription;

  public constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private fixService: FixService,
    private route: ActivatedRoute,
    private navigationService: NavigationService
  ) {}

  public ngOnInit(): void {
    this.subscription = this.fixService
      .getPatchedVersion(this.componentData.componentId)
      .pipe(
        take(1),
        finalize(() => (this.loading = false))
      )
      .subscribe((patchedVersion: PatchedInfoSimplified) => {
        this.patchedVersions = patchedVersion;
        if (
          patchedVersion.nextPatchedVersion ===
          patchedVersion.latestPatchedVersion
        ) {
          this.newVersion = patchedVersion.latestPatchedVersion;
        }
      });
  }

  /**
   * unsubscribe from the observable to avoid memory leaks
   */
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public goToVulnerability(id: string): void {
    this.activeModal.close();
    this.navigationService.navigateToVulnerabilityDetails(
      id,
      this.entityId,
      this.projectId,
      this.scanId
    );
  }

  public fixVersion(): void {
    this.fixService
      .fixComponentVersion(
        this.scanId,
        this.componentData.componentId,
        this.componentData.version,
        this.newVersion
      )
      .subscribe(
        (results: Array<FixResult>) => {
          const modalRef: NgbModalRef = this.modalService.open(
            FixComponentResultDialogComponent,
            {
              keyboard: false,
            }
          );
          modalRef.componentInstance.fixResults = results;
        },
        (error: Error) => {
          console.error('error: ' + error.message);
        }
      );
  }
}
