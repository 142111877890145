import { Injectable, Pipe, PipeTransform } from '@angular/core';

export type SortOrder = 'asc' | 'desc';

@Injectable()
@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(
    value: any[],
    sortOrder: SortOrder | string = 'asc',
    sortKey?: string
  ): any {
    sortOrder = sortOrder && (sortOrder.toLowerCase() as any);

    if (!value || (sortOrder !== 'asc' && sortOrder !== 'desc')) {
      return value;
    }

    let numberArray = [];
    let stringArray = [];

    if (!sortKey) {
      numberArray = value.filter((item) => typeof item === 'number').sort();
      stringArray = value.filter((item) => typeof item === 'string').sort();
    } else {
      numberArray = value
        .filter((item) => typeof resolvePath(item, sortKey) === 'number')
        .sort((a, b) => resolvePath(a, sortKey) - resolvePath(b, sortKey));
      stringArray = value
        .filter((item) => typeof resolvePath(item, sortKey) === 'string')
        .sort((a, b) => {
          if (resolvePath(a, sortKey).toLowerCase() < resolvePath(b, sortKey).toLowerCase()) {
            return -1;
          } else if (resolvePath(a, sortKey).toLowerCase() > resolvePath(b, sortKey).toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        });
    }
    const sorted = numberArray.concat(stringArray);
    return sortOrder === 'asc' ? sorted : sorted.reverse();
  }
}

const resolvePath = (object, path, defaultValue = null) =>
  path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object);
