import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-ui-modal',
  templateUrl: './ui-modal.component.html',
  styleUrls: ['./ui-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiModalComponent implements OnDestroy {
  @Input() dialogClass: string;
  @Input() hideHeader = false;
  @Input() hideFooter = false;
  @Input() containerClick = true;
  public visible = false;
  public visibleAnimate = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    document.querySelector('body').classList.remove('modal-open');
  }

  public show(): void {
    this.visible = true;
    setTimeout(() => {
      this.visibleAnimate = true;
      this.cdr.detectChanges();
    }, 100);
    document.querySelector('body').classList.add('modal-open');
    this.cdr.detectChanges();
  }

  public hide(): void {
    this.visibleAnimate = false;
    setTimeout(() => {
      this.visible = false;
      this.cdr.detectChanges();
    }, 300);

    document.querySelector('body').classList.remove('modal-open');
    this.cdr.detectChanges();
  }

  public onContainerClicked(event: MouseEvent): void {
    if (
      (event.target as HTMLElement).classList.contains('modal') &&
      this.containerClick === true
    ) {
      this.hide();
    }
  }
}
