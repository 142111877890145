<div class="col-xl-12" *ngIf="obsScanLicense | async as scanLicense">
  <div style="margin-bottom: 15px" *ngIf="jiraCredentials && !jiraTicket">
    <button
      class="btn btn-outline-primary"
      data-toggle="modal"
      type="button"
      (click)="createJiraTicket()"
    >
      Create Jira Ticket
    </button>
  </div>
  <div style="margin-bottom: 15px" *ngIf="jiraTicket">
    <button
      class="btn btn-outline-primary"
      data-toggle="modal"
      type="button"
      (click)="openJiraTicket(jiraTicket.key, jiraTicket.self)"
    >
      {{ jiraTicket.key }}
    </button>
  </div>

  <ngb-tabset type="pills" class="tab-card" #ctdTabset="ngbTabset">
    <ngb-tab title="Summary" id="tab1">
      <ng-template ngbTabContent>
        <app-card
          [hidHeader]="true"
          cardClass="mb-0"
          blockClass="align-items-center justify-content-between"
        >
          <div class="" style="padding-right: 30px">
            <div class="">
              <div class="">
                <div
                  class="f-18"
                  style="margin-bottom: 20px; text-align: center; width: 100%"
                >
                  {{ scanLicense.license?.name }}
                </div>
              </div>
              <div
                style="
                  font-size: 16px;
                  height: 30px;
                  border-bottom: 1px solid gray;
                  text-align: center;
                "
              >
                THREAT CATEGORY
              </div>
              <div
                style="
                  width: 100%;
                  font-size: 20px;
                  text-align: center;
                  padding-top: 15px;
                "
              >
                <span
                  *ngIf="scanLicense.license?.category === 'COPYLEFT_STRONG'"
                  title="Copyleft Strong"
                  class="badge m-r-5 badge-light-danger f-18"
                  >COPYLEFT STRONG</span
                >
                <span
                  *ngIf="scanLicense.license?.category === 'COPYLEFT_WEAK'"
                  title="Copyleft Weak"
                  class="badge m-r-5 badge-light-warning f-18"
                  >COPYLEFT WEAK</span
                >
                <span
                  *ngIf="scanLicense.license?.category === 'COPYLEFT_PARTIAL'"
                  title="Copyleft Partial"
                  class="badge m-r-5 badge-light-warning f-18"
                  >COPYLEFT PARTIAL</span
                >
                <span
                  *ngIf="scanLicense.license?.category === 'COPYLEFT_LIMITED'"
                  title="Copyleft Limited"
                  class="badge m-r-5 badge-light-warning f-18"
                  >COPYLEFT LIMITED</span
                >
                <span
                  *ngIf="scanLicense.license?.category === 'COPYLEFT'"
                  title="Copyleft"
                  class="badge m-r-5 badge-light-warning f-18"
                  >COPYLEFT</span
                >
                <span
                  *ngIf="scanLicense.license?.category === 'PERMISSIVE'"
                  title="Permissive"
                  class="badge m-r-5 badge-light-success f-18"
                  >PERMISSIVE</span
                >
                <span
                  *ngIf="scanLicense.license?.category === 'PROPRIETARY_FREE'"
                  title="Proprietary Free"
                  class="badge m-r-5 badge-light-warning"
                  >PROPRIETARY FREE</span
                >
                <span
                  *ngIf="scanLicense.license?.category === 'PUBLIC_DOMAIN'"
                  title="Permissive"
                  class="badge m-r-5 badge-light-success f-18"
                  >PUBLIC DOMAIN</span
                >
                <span
                  *ngIf="scanLicense.license?.category === 'UNDEFINED'"
                  title="Undefined"
                  class="badge m-r-5 badge-light-warning f-18"
                  >UNDEFINED</span
                >
              </div>
            </div>

            <div class="" style="margin-top: 40px">
              <div class="form-group">
                <label for="summary-spdx" class="col-form-label">SPDX</label>
                <div class="">
                  <input
                    type="text"
                    class="form-control"
                    id="summary-spdx"
                    readonly
                    placeholder="{{ scanLicense.license?.spdxId }}"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="summary-style" class="col-form-label">Style</label>
                <div class="">
                  <input
                    type="text"
                    class="form-control"
                    id="summary-style"
                    readonly
                    placeholder="{{ scanLicense.license?.style }}"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="summary-publicationYear" class="col-form-label"
                  >Publication Year</label
                >
                <div class="">
                  <input
                    type="text"
                    class="form-control"
                    id="summary-publicationYear"
                    readonly
                    placeholder="{{ scanLicense.license?.publicationYear }}"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="summary-type" class="col-form-label">Type</label>
                <div class="">
                  <input
                    type="text"
                    class="form-control"
                    id="summary-type"
                    readonly
                    placeholder="{{ scanLicense.license?.type }}"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="summary-compat" class="col-form-label"
                  >Compatible</label
                >
                <div class="">
                  <input
                    type="text"
                    class="form-control"
                    id="summary-compat"
                    readonly
                    placeholder="{{ scanLicense.license?.compatible }}"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="summary-incompat" class="col-form-label"
                  >Incompatible</label
                >
                <div class="">
                  <input
                    type="text"
                    class="form-control"
                    id="summary-incompat"
                    readonly
                    placeholder="{{ scanLicense.license?.incompatible }}"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="summary-discovery" class="col-form-label"
                  >DISCOVERY</label
                >
                <div class="">
                  <input
                    type="text"
                    class="form-control"
                    id="summary-discovery"
                    readonly
                    placeholder="{{ scanLicense.licenseDiscovery }}"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">First occurrence</label>
                <div class="">
                  <a
                    *ngIf="scanLicense.licenseOrigin === 'ASSET'"
                    routerLink="/security/entity/{{ entityId }}/project/{{
                      projectId
                    }}/scan/{{ scanId }}/scanasset/{{
                      scanLicense.scanLicenseAssets?.edges[0]?.node?.scanAssetId
                    }}"
                    class="form-control border-2"
                    style="border-color: #6c757d"
                  >
                    {{ scanLicense.licenseOrigin }}
                  </a>
                  <a
                    *ngIf="scanLicense.licenseOrigin === 'COMPONENT'"
                    routerLink="/security/entity/{{ entityId }}/project/{{
                      projectId
                    }}/scan/{{ scanId }}/component/{{
                      window.btoa(
                        scanLicense.scanComponents?.edges[0]?.node?.componentId
                      )
                    }}"
                    class="form-control badge-light-warning"
                    style="border-color: #6c757d"
                  >
                    {{ scanLicense.licenseOrigin }}
                  </a>
                </div>
              </div>
              <div class="form-group">
                <label for="notes" class="col-form-label">Notes</label>
                <div class="">
                  <textarea rows="5" class="form-control" id="notes" readonly>{{
                    scanLicense.license?.notes
                  }}</textarea>
                </div>
              </div>
            </div>
          </div>
        </app-card>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="License" id="tab2">
      <ng-template ngbTabContent>
        <app-card
          [hidHeader]="true"
          cardClass="mb-0"
          blockClass="align-items-center"
        >
          <div class="row">
            <div class="row main-container">
              <!-- LEFT COLUMN -->
              <div class="" style="padding-right: 30px">
                <div class="col-xl-12">
                  <div
                    style="
                      font-size: 16px;
                      height: 30px;
                      border-bottom: 1px solid gray;
                      text-align: center;
                    "
                  >
                    THREAT CATEGORY
                  </div>
                  <div
                    style="
                      width: 100%;
                      font-size: 20px;
                      text-align: center;
                      padding-top: 15px;
                    "
                  >
                    <span
                      *ngIf="
                        scanLicense.license?.category === 'COPYLEFT_STRONG'
                      "
                      title="Copyleft Strong"
                      class="badge m-r-5 badge-light-danger f-18"
                      >COPYLEFT STRONG</span
                    >
                    <span
                      *ngIf="scanLicense.license?.category === 'COPYLEFT_WEAK'"
                      title="Copyleft Weak"
                      class="badge m-r-5 badge-light-warning f-18"
                      >COPYLEFT WEAK</span
                    >
                    <span
                      *ngIf="
                        scanLicense.license?.category === 'COPYLEFT_PARTIAL'
                      "
                      title="Copyleft Partial"
                      class="badge m-r-5 badge-light-warning f-18"
                      >COPYLEFT PARTIAL</span
                    >
                    <span
                      *ngIf="
                        scanLicense.license?.category === 'COPYLEFT_LIMITED'
                      "
                      title="Copyleft Limited"
                      class="badge m-r-5 badge-light-warning f-18"
                      >COPYLEFT LIMITED</span
                    >
                    <span
                      *ngIf="scanLicense.license?.category === 'COPYLEFT'"
                      title="Copyleft"
                      class="badge m-r-5 badge-light-warning f-18"
                      >COPYLEFT</span
                    >
                    <span
                      *ngIf="scanLicense.license?.category === 'PERMISSIVE'"
                      title="Permissive"
                      class="badge m-r-5 badge-light-success f-18"
                      >PERMISSIVE</span
                    >
                    <span
                      *ngIf="
                        scanLicense.license?.category === 'PROPRIETARY_FREE'
                      "
                      title="Proprietary Free"
                      class="badge m-r-5 badge-light-warning"
                      >PROPRIETARY FREE</span
                    >
                    <span
                      *ngIf="scanLicense.license?.category === 'PUBLIC_DOMAIN'"
                      title="Permissive"
                      class="badge m-r-5 badge-light-success f-18"
                      >PUBLIC DOMAIN</span
                    >
                    <span
                      *ngIf="scanLicense.license?.category === 'UNDEFINED'"
                      title="Undefined"
                      class="badge m-r-5 badge-light-warning f-18"
                      >UNDEFINED</span
                    >
                  </div>
                </div>
                <div class="col-xl-12" style="margin-top: 40px">
                  <div class="form-group row p-l-5">
                    <label for="spdx" class="col-sm-12 col-form-label"
                      >SPDX</label
                    >
                    <div class="col-xl-12">
                      <input
                        type="text"
                        class="form-control"
                        id="spdx"
                        readonly
                        placeholder="{{ scanLicense.license?.spdxId }}"
                      />
                    </div>
                  </div>
                  <div class="form-group row p-l-5">
                    <label for="style" class="col-sm-12 col-form-label"
                      >Style</label
                    >
                    <div class="col-xl-12">
                      <input
                        type="text"
                        class="form-control"
                        id="style"
                        readonly
                        placeholder="{{ scanLicense.license?.style }}"
                      />
                    </div>
                  </div>
                  <div class="form-group row p-l-5">
                    <label
                      for="publicationYear"
                      class="col-sm-12 col-form-label"
                      >Publication Year</label
                    >
                    <div class="col-xl-12">
                      <input
                        type="text"
                        class="form-control"
                        id="publicationYear"
                        readonly
                        placeholder="{{ scanLicense.license?.publicationYear }}"
                      />
                    </div>
                  </div>
                  <div class="form-group row p-l-5">
                    <label for="type" class="col-sm-12 col-form-label"
                      >Type</label
                    >
                    <div class="col-xl-12">
                      <input
                        type="text"
                        class="form-control"
                        id="type"
                        readonly
                        placeholder="{{ scanLicense.license?.type }}"
                      />
                    </div>
                  </div>
                  <div class="form-group row p-l-5">
                    <label for="summary-compat" class="col-sm-12 col-form-label"
                      >Compatible</label
                    >
                    <div class="col-xl-12">
                      <input
                        type="text"
                        class="form-control"
                        id="summary-compat"
                        readonly
                        placeholder="{{ scanLicense.license?.compatible }}"
                      />
                    </div>
                  </div>
                  <div class="form-group row p-l-5">
                    <label
                      for="summary-incompat"
                      class="col-sm-12 col-form-label"
                      >Incompatible</label
                    >
                    <div class="col-xl-12">
                      <input
                        type="text"
                        class="form-control"
                        id="summary-incompat"
                        readonly
                        placeholder="{{ scanLicense.license?.incompatible }}"
                      />
                    </div>
                  </div>
                  <div class="form-group row p-l-5">
                    <label for="notes" class="col-sm-12 col-form-label"
                      >Notes</label
                    >
                    <div class="col-xl-12">
                      <textarea
                        rows="10"
                        class="form-control"
                        id="notes"
                        readonly
                        >{{ scanLicense.license?.notes }}</textarea
                      >
                    </div>
                  </div>
                </div>
              </div>

              <!-- RIGHT COLUMN -->
              <div class="col-xl-8">
                <!-- DESCRIPTION -->

                <div class="col-xl-12">
                  <div
                    class="f-18"
                    style="margin-bottom: 20px; text-align: center; width: 100%"
                  >
                    {{ scanLicense.license?.name }}
                  </div>
                  <div>{{ scanLicense.license?.description }}</div>
                </div>

                <!-- ATTRIBUTES
                              <div class="row" style="padding-left:15px;margin-top:40px">
                                <div class="col-xl-4">
                                  <div class="caption">Permissions</div>
                                  <div *ngFor="let licenseAttribute of permissions">
                                    <i class="fas fa-check color-success m-r-5"></i> {{licenseAttribute.name.replaceAll('_', ' ')}}<i class="m-l-5 fas fa-question-circle" title="{{licenseAttribute.description}}"></i>
                                  </div>
                                </div>
                                <div class="col-xl-4">
                                  <div class="caption">Limitations</div>
                                  <div *ngFor="let licenseAttribute of limitations">
                                    <i class="fas fa-times color-danger m-r-5"></i> {{licenseAttribute.name.replaceAll('_', ' ')}}<i class="m-l-5 fas fa-question-circle" title="{{licenseAttribute.description}}"></i>
                                  </div>
                                </div>
                                <div class="col-xl-4">
                                  <div class="caption">Conditions</div>
                                  <div *ngFor="let licenseAttribute of conditions">
                                    <i class="fas fa-info-circle color-info m-r-5"></i> {{licenseAttribute.name.replaceAll('_', ' ')}}<i class="m-l-5 fas fa-question-circle" title="{{licenseAttribute.description}}"></i>
                                  </div>
                                </div>
                              </div>-->

                <div style="display: flex; margin-top: 20px; margin-left: 15px">
                  <div style="flex-grow: 1">
                    <div
                      class="caption"
                      style="border-bottom: 1px solid gray; margin-right: 40px"
                    >
                      Permissions
                    </div>
                    <div *ngFor="let licenseAttribute of permissions">
                      <i class="fas fa-check color-success m-r-5"></i>
                      {{
                        licenseAttribute.name.replaceAll(
                          '_',
                          '
                      '
                        )
                      }}<i
                        class="m-l-5 fas fa-question-circle"
                        title="{{ licenseAttribute.description }}"
                      ></i>
                    </div>
                  </div>
                  <div style="flex-grow: 1">
                    <div
                      class="caption"
                      style="border-bottom: 1px solid gray; margin-right: 40px"
                    >
                      Limitations
                    </div>
                    <div *ngFor="let licenseAttribute of limitations">
                      <i class="fas fa-times color-danger m-r-5"></i>
                      {{
                        licenseAttribute.name.replaceAll(
                          '_',
                          '
                      '
                        )
                      }}<i
                        class="m-l-5 fas fa-question-circle"
                        title="{{ licenseAttribute.description }}"
                      ></i>
                    </div>
                  </div>
                  <div style="flex-grow: 2">
                    <div
                      class="caption"
                      style="border-bottom: 1px solid gray; margin-right: 20px"
                    >
                      Conditions
                    </div>
                    <div *ngFor="let licenseAttribute of conditions">
                      <i class="fas fa-info-circle color-info m-r-5"></i>
                      {{
                        licenseAttribute.name.replaceAll(
                          '_',
                          '
                      '
                        )
                      }}<i
                        class="m-l-5 fas fa-question-circle"
                        title="{{ licenseAttribute.description }}"
                      ></i>
                    </div>
                  </div>
                </div>

                <!-- LICENSE BODY -->
                <div class="col-xl-12" style="margin-top: 40px">
                  <div
                    class="card card-body bg-light"
                    style="padding-left: 50px"
                  >
                    <pre class="asset-pane">{{
                      scanLicense.license?.body
                    }}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-card>
      </ng-template>
    </ngb-tab>
    <!-- Components -->
    <ngb-tab id="tab3" *ngIf="!isFromComponent && scanId">
      <ng-template ngbTabTitle
        >Components&nbsp;
        <span class="badge badge-light">
          {{ licenseComponents?.totalCount || 0 }}
        </span>
      </ng-template>
      <ng-template ngbTabContent>
        <ngb-alert
          *ngIf="
            !licenseComponents ||
            (licenseComponents && licenseComponents.totalCount === 0)
          "
          [type]="'primary'"
          [dismissible]="false"
          >No components found for this license.
        </ngb-alert>
        <app-card
          *ngIf="licenseComponents && licenseComponents.totalCount > 0"
          [hidHeader]="true"
          cardClass="mb-0"
          blockClass="align-items-center justify-content-between"
        >
          <div class="row">
            <perfect-scrollbar
              [style.max-height]="'650px'"
              [style.padding-right]="'10px'"
              class="col-sm-12"
              class="overflowshadow"
              [config]="{
                useBothWheelAxes: true,
                suppressScrollX: false,
                suppressScrollY: false
              }"
            >
              <p-table
                class="m-r-5"
                tableStyleClass="table table-striped table-bordered table-hover dataTable no-footer table-width"
                [columns]="componentColumns"
                [value]="licenseComponents?.edges"
                [paginator]="false"
                [rows]="50"
                [autoLayout]="true"
                [filterDelay]="0"
                [globalFilterFields]="[
                  'node.name',
                  'node.group',
                  'node.version'
                ]"
              >
                <ng-template pTemplate="caption">
                  <div style="text-align: right">
                    <i class="pi pi-search" style="margin: 4px 4px 0 0"></i>
                    <input
                      style="width: auto; display: inline"
                      class="form-control"
                      placeholder="Component Filter"
                      pInputText
                      size="50"
                      (input)="onComponentFilterInput($event)"
                    />
                  </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th
                      *ngFor="let col of columns"
                      [pSortableColumn]="col.field"
                    >
                      {{ col.header }}
                    </th>
                  </tr>
                </ng-template>

                <ng-template
                  pTemplate="body"
                  let-component
                  let-columns="columns"
                >
                  <tr class="cursor-pointer">
                    <td
                      (click)="gotoComponentDetails(component.node.componentId)"
                    >
                      {{ component.node.name }}
                    </td>
                    <td
                      (click)="gotoComponentDetails(component.node.componentId)"
                    >
                      {{ component.node.group }}
                    </td>
                    <td
                      (click)="gotoComponentDetails(component.node.componentId)"
                    >
                      {{ component.node.version }}
                    </td>
                    <td
                      (click)="gotoComponentDetails(component.node.componentId)"
                    >
                      {{
                        !!component.node.componentLocation
                          ? component.node.componentLocation.replaceAll(
                              '_',
                              ' '
                            )
                          : ''
                      }}
                    </td>
                    <td
                      (click)="gotoComponentDetails(component.node.componentId)"
                    >
                      {{
                        !!component.node.componentDiscoveryMethod
                          ? component.node.componentDiscoveryMethod.replaceAll(
                              '_',
                              '
                      '
                            )
                          : ''
                      }}
                    </td>
                    <td
                      (click)="gotoComponentDetails(component.node.componentId)"
                    >
                      <div
                        *ngFor="
                          let vulnerabilityEdge of component.node
                            .vulnerabilities.edges
                        "
                      >
                        <span
                          >{{
                            component.node.vulnerabilities.edges[0]?.node
                              .vulnerabilityAlias
                          }}:{{
                            component.node.vulnerabilities.edges[0]?.node
                              .severity
                          }}</span
                        >
                        <span
                          *ngIf="vulnerabilityEdge.node.severity === 'CRITICAL'"
                          title="Critical"
                          class="badge m-l-5 badge-light-danger"
                          >CRITICAL</span
                        >
                        <span
                          *ngIf="vulnerabilityEdge.node.severity === 'HIGH'"
                          title="Critical"
                          class="badge m-l-5 badge-light-danger"
                          >HIGH</span
                        >
                        <span
                          *ngIf="vulnerabilityEdge.node.severity === 'MEDIUM'"
                          title="Critical"
                          class="badge m-l-5 badge-light-warning"
                          >MEDIUM</span
                        >
                        <span
                          *ngIf="vulnerabilityEdge.node.severity === 'LOW'"
                          title="Critical"
                          class="badge m-l-5 badge-light-primary"
                          >LOW</span
                        >
                        <span
                          *ngIf="vulnerabilityEdge.node.severity === 'INFO'"
                          title="Critical"
                          class="badge m-l-5 badge-light-success"
                          >INFO</span
                        >
                      </div>
                      <div
                        *ngIf="component.node.vulnerabilities.edges.length > 0"
                      >
                        <button
                          class="btn btn-outline-primary"
                          data-toggle="modal"
                          type="button"
                          (click)="
                            $event.stopPropagation();
                            fixVersion(
                              component.node.componentId,
                              component.node.version
                            )
                          "
                        >
                          Fix
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </perfect-scrollbar>
            <!--<div class="col-sm-12">
              <mat-paginator [hidePageSize]="false" [disabled]="false" [length]="licenseComponents?.totalCount" [pageSize]="defaultPageSize"
                [pageSizeOptions]="[25, 50, 100]" (page)="changePage($event)">
              </mat-paginator>
            </div>-->
          </div>
        </app-card>
      </ng-template>
    </ngb-tab>

    <!-- Assets -->
    <ngb-tab *ngIf="!isFromComponent && scanId" id="asset">
      <ng-template ngbTabTitle
        >Assets&nbsp;
        <span class="badge badge-light">
          {{ scanAssetsTree?.totalCount }}
        </span>
      </ng-template>
      <ng-template ngbTabContent>
        <div *ngIf="scanOpenSourceProject">
          This is open source project '{{ scanOpenSourceProject.owner }}/{{
            scanOpenSourceProject.name
          }}' and the assets were not scanned.
          <br />
          <a href="{{ scanOpenSourceProject.repoWebsite }}">{{
            scanOpenSourceProject.repoWebsite
          }}</a>
        </div>

        <ngb-alert
          *ngIf="
            !scanAssetsTree ||
            (scanAssetsTree && scanAssetsTree.totalCount === 0 && !loading)
          "
          [type]="'primary'"
          [dismissible]="false"
          >No assets found for this license.
          <ng-container *ngIf="hasFilters()"
            >Try clearing filters.
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="clearFilters()"
            >
              Clear
            </button></ng-container
          >
        </ngb-alert>

        <div *ngIf="!scanOpenSourceProject && scanAssetsTree.totalCount > 0">
          <perfect-scrollbar
            [style.max-height]="'auto'"
            class="overflowshadow"
            [config]="{
              useBothWheelAxes: true,
              suppressScrollX: false,
              suppressScrollY: false
            }"
          >
            <app-new-assets-table
              [entityId]="entityId"
              [projectId]="projectId"
              [scanAssetsTree]="scanAssetsTree"
              [story]="story"
              [columnsFilter]="columnsAssetFilter"
              (filterChange)="onFilterAssetColumn($event)"
              (details)="assetGotoDetails($event)"
              (back)="assetGoBack()"
              [fromLicenseDimension]="true"
            ></app-new-assets-table>
            <!-- <app-assets-table
              [entityId]="entityId"
              [projectId]="projectId"
              [scanAssetsTree]="scanAssetsTree"
              [story]="story"
              [columnsFilter]="columnsAssetFilter"
              (filterChange)="onFilterAssetColumn($event)"
              (details)="assetGotoDetails($event)"
              (back)="assetGoBack()"
              [fromLicenseDimension]="true"
            >
            </app-assets-table> -->
          </perfect-scrollbar>
          <div class="col-sm-12">
            <mat-paginator
              [hidePageSize]="false"
              [length]="scanAssetsTree?.totalCount"
              [pageSize]="defaultPageSize"
              [pageSizeOptions]="[25, 50, 100]"
              (page)="changeAssetPage($event)"
            >
            </mat-paginator>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>

<main-spinner *ngIf="loading"></main-spinner>
