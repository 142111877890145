import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectBreadcrumbsService } from './core';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  public constructor(
    private router: Router,
    private projectBreadcrumbsService: ProjectBreadcrumbsService
  ) {}

  public navigateToProject(
    projectType: 'AICERTIFY' | 'CODECERTIFY' | 'SECURECORE' | 'AI_CERTIFY',
    entityId: string,
    projectId: string,
    blank: boolean = false
  ): void {
    let url: string;
    switch (projectType) {
      case 'AICERTIFY':
      case 'AI_CERTIFY':
        url = this.router.serializeUrl(
          this.router.createUrlTree(['/aicertify/entity/' + entityId], {
            queryParams: { projectId },
          })
        );
        break;
      case 'CODECERTIFY':
        url = this.router.serializeUrl(
          this.router.createUrlTree(['/codecertify/entity/' + entityId], {
            queryParams: { projectId },
          })
        );
        break;
      default:
        url = this.router.serializeUrl(
          this.router.createUrlTree([
            '/security/entity/' + entityId + '/project/' + projectId,
          ])
        );
        break;
    }
    if (blank) {
      window.open(url, '_blank');
    } else {
      this.router.navigate([url]);
    }
  }

  public navigateToVulnerabilityDetails(
    vulnId: string,
    entityId: string,
    projectId: string,
    scanId: string
  ): void {
    if (this.projectBreadcrumbsService.getProjectBreadcrumb()) {
      this.projectBreadcrumbsService.settingProjectBreadcrumb('', '', '', true);
    }
    const url: string = scanId
      ? 'security/entity/' +
        entityId +
        '/project/' +
        projectId +
        '/scan/' +
        scanId +
        '/vulnerability/' +
        vulnId
      : 'security/entity/' +
        entityId +
        '/project/' +
        projectId +
        '/vulnerability/' +
        vulnId;

    this.router.navigate([decodeURIComponent(url)]);
  }
}
