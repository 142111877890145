import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-scan-info-dialog',
  templateUrl: './scan-info-dialog.component.html',
  styleUrls: ['./scan-info-dialog.component.scss']
})
export class ScanInfoDialogComponent implements OnInit {
  public errorMsg: string;
  public log: string;

  constructor(
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

}
