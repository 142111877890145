import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
const SecureStorage = require('secure-web-storage');
const SECRET_KEY: any = 'secret_key';

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor(private cookieSrvice: CookieService) {}

  public secureStorage = new SecureStorage(
    localStorage,
    this.setEncrptDecrypt()
  );
  public secureSessionStorage = new SecureStorage(
    sessionStorage,
    this.setEncrptDecrypt()
  );
  public secureCookieStorage = {
    getItem: (key: string): string => {
      const { hash, encrypt, decrypt } = this.setEncrptDecrypt();
      const keyHash: string = hash(key);
      const encryptedData: string = this.cookieSrvice.get(keyHash);
      if (encryptedData) {
        return decrypt(encryptedData);
      }
      return null;
    },
    setItem: (key: string, value): void => {
      const { hash, encrypt } = this.setEncrptDecrypt();
      const keyHash: string = hash(key);
      const encryptedData: string = encrypt(value);
      this.cookieSrvice.set(keyHash, encryptedData, null, '/');
    },
    clearItem: (key: string): void => {
      const { hash } = this.setEncrptDecrypt();
      const keyHash: string = hash(key);
      this.cookieSrvice.delete(keyHash, '/');
    },
  };

  private setEncrptDecrypt() {
    return {
      hash: (key) => {
        key = CryptoJS.SHA256(key, SECRET_KEY);
        return key.toString();
      },
      // Encrypt the localstorage/sessionStorage data
      encrypt: (data) => {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);
        data = data.toString();
        return data;
      },
      // Decrypt the encrypted data
      decrypt: (data) => {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);
        data = data.toString(CryptoJS.enc.Utf8);
        return data;
      },
    };
  }
}
