export const environment = {
  hybrid: false,
  production: false,
  staging: false,
  apiUrl: 'https://stage.threatrix.io',
  wsUrl: 'wss://stage.threatrix.io',
  console: false,
  development: false,
  stripe: {
    publicKey: 'pk_test_51Iv7gyCsfPOq0BK1dlRWakGGDM0xCqaofQnTzcf5RL7sibCZZzfN3s9eWjfWRfSc9w0vkTIYD4gM7LeqJo9kY6Ak00chdnbGfE',
  }
};
