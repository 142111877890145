import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-risk-score-popup',
  templateUrl: './risk-score-popup.component.html',
  styleUrls: ['./risk-score-popup.component.scss'],
})
export class RiskScorePopupComponent {
  @Input() p: any;
  @Input() componentData: any;
  @Input() riskScoreData: any;
}
