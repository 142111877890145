import { UserPreferenceService } from '@app/services/core/user-preference.service';
import { NextConfig } from '@app/app-config';

/**
 * This class is intended to represent group of paging params used on most of GraphQL query calls.
 */
export class GraphQLPage {
  // filter: string;
  first: number;
  last: number;
  after: string;
  before: string;

  constructor(first: number, last?: number, after?: string, before?: string) {
    // this.filter = filter;
    this.first = first;
    this.last = last;
    this.after = after;
    this.before = before;
  }
}

/**
 * Used to determine scope of paging variables
 * which are stored in the session and saves paging state
 * for the certain table/list/page/tab of the UX app.
 */
export class PagingScope {
  moduleName: string;
  componentName: string;

  constructor(moduleName: string, componentName: string) {
    this.moduleName = moduleName;
    this.componentName = componentName;
  }
}

export class PagingState {
  // filter = new Map();
  pageSize: number = NextConfig.config.defaultPageSize;
  pageSizeOptions: number[] = NextConfig.config.defaultPageSizeOptions;
  isPaginationDisabled: boolean = true;

  constructor(
    pagingScope?: PagingScope,
    userPreferenceService?: UserPreferenceService
  ) {
    if (pagingScope === undefined) {
      return;
    }
    this.pageSize = Number(
      userPreferenceService.getItemPerPageByModuleAndComponentName(
        pagingScope.moduleName,
        pagingScope.componentName
      )
    );
  }

  public static ofPageSize(pageSize: number) {
    const pagingState = new PagingState();
    pagingState.pageSize = pageSize;
    return pagingState;
  }
}

/**
 * This class represents data (list of some objects) paging state/info responded by server.
 */
// todo: ref: get rid of duplicate declaration of this class [task: https://github.com/threatrix/product/issues/1535]
export class PageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
}
