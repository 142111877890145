import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UiModalComponent } from '@app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-copyright-add-dialog',
  templateUrl: './copyright-add-dialog.component.html',
  styleUrls: ['./copyright-add-dialog.component.scss'],
})
export class CopyrightAddDialogComponent implements OnInit {
  // target objects
  copyrightedObject: any;

  // input values
  copyrightText: string;

  // save function
  @Input() saveFn: (copyrightedObject: any, copyrightText: string) => void;

  // modal
  @ViewChild(UiModalComponent)
  modal: UiModalComponent;

  constructor() {}

  ngOnInit(): void {}

  openDialog(copyrightedObject: any) {
    this.copyrightedObject = copyrightedObject;
    this.modal.show();
  }

  saveCopyright() {
    this.saveFn(this.copyrightedObject, this.copyrightText);
    this.modal.hide();
    this.copyrightText = '';
  }
}
