import { Component, Input, OnInit } from '@angular/core';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, Subject, Subscription } from 'rxjs';
import {
  AutofixVulnerabilityVersions,
  FixResult,
  PatchedInfoSimplified,
} from '@app/threat-center/shared/models/types';
import {
  AutoFixDataInput,
  FixService,
  IAutoFixDataInput,
} from '@app/services/fix.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FixComponentResultDialogComponent } from '@app/shared/components/fix-component-result-dialog/fix-component-result-dialog.component'; // eslint-disable-line
import { AlertService } from '@app/services/core';

@Component({
  selector: 'app-fix-component-dialog',
  templateUrl: './fix-component-dialog.component.html',
  styleUrls: ['./fix-component-dialog.component.scss'],
})
export class FixComponentDialogComponent implements OnInit {
  public scanId: string;
  public newVersion: string;
  public oldVersion: string;
  public componentId: string;
  public name: string;
  public vulnerabilityAlias: string;

  public fixResultSubject: Subject<boolean> = new Subject();
  public patchedVersions: PatchedInfoSimplified;
  public loading: boolean = true;
  public mode: 'MULTIPLE' | 'SINGLE' = 'SINGLE';
  public vulnerabilitiesFixesRequestData: { [key: string]: IAutoFixDataInput } =
    {};
  public autofixVulnerabilityVersions: Array<AutofixVulnerabilityVersions> = [];

  public constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private fixService: FixService,
    private _snackBar: MatSnackBar,
    private alertService: AlertService
  ) {}

  public ngOnInit(): void {
    if (this.mode === 'MULTIPLE') {
      this.fixService
        .autofixVulnerabilityVersions(this.scanId, this.vulnerabilityAlias)
        .subscribe(
          (data: Array<AutofixVulnerabilityVersions>) => {
            this.autofixVulnerabilityVersions = data;
            this.loading = false;
            data.forEach((element: AutofixVulnerabilityVersions) => {
              this.vulnerabilitiesFixesRequestData[element.purl] = {
                componentPurl: element.purl,
                newVersion: '',
                oldVersion: element.vulnerableVersion,
                scanId: this.scanId,
              };
            });
          },
          (error) => {
            console.error(error);
            this.loading = false;
          }
        );
    } else {
      this.fixService.getPatchedVersion(this.componentId).subscribe(
        (patchedVersion: PatchedInfoSimplified) => {
          this.loading = false;
          this.patchedVersions = patchedVersion;

          if (
            patchedVersion.nextPatchedVersion ===
            patchedVersion.latestPatchedVersion
          ) {
            this.newVersion = patchedVersion.latestPatchedVersion;
          }
        },
        (error) => {
          console.error(error);
          this.loading = false;
        }
      );
    }
  }

  public fixVersion(): void {
    this.openSnackBar();

    if (this.mode === 'SINGLE') {
      this.fixSingleComponent();
    } else {
      this.fixMultipleComponents();
    }
  }

  private fixSingleComponent() {
    this.fixService
      .fixComponentVersion(
        this.scanId,
        this.componentId,
        this.oldVersion,
        this.newVersion
      )
      .subscribe(
        (results: Array<FixResult>) => {
          this.fixResultSubject.next(results[0].success);
          const modalRef: NgbModalRef = this.modalService.open(
            FixComponentResultDialogComponent,
            {
              keyboard: false,
            }
          );
          modalRef.componentInstance.fixResults = results;
          this._snackBar.dismiss();
        },
        (error) => {
          console.error('error: ' + error);
          this._snackBar.dismiss();
        }
      );
  }

  private fixMultipleComponents() {
    let vulnsCounter = 0;
    const request: Array<AutoFixDataInput> = [];
    Object.keys(this.vulnerabilitiesFixesRequestData).forEach((key: string) => {
      if (this.vulnerabilitiesFixesRequestData[key].newVersion) {
        vulnsCounter += 1;
      }
      request.push(
        new AutoFixDataInput(
          this.vulnerabilitiesFixesRequestData[key].componentPurl,
          this.vulnerabilitiesFixesRequestData[key].newVersion,
          this.vulnerabilitiesFixesRequestData[key].oldVersion,
          this.vulnerabilitiesFixesRequestData[key].scanId
        )
      );
    });
    if (vulnsCounter < this.autofixVulnerabilityVersions.length) {
      this.alertService
        .alertConfirm(
          'You have not chosen all the components',
          'Are you sure you want to continue only with the selected fixes?',
          'question',
          true,
          true,
          '#4680ff',
          '#6c757d',
          'Yes',
          'No'
        )
        .then((value) => {
          if (value) {
            this.fixMultipleComponentsCall(request);
          }
        });
    } else {
      this.fixMultipleComponentsCall(request);
    }
  }

  private fixMultipleComponentsCall(request: Array<AutoFixDataInput>): void {
    this.fixService.fixComponentVersions(request).subscribe(
      (data) => {
        const modalRef: NgbModalRef = this.modalService.open(
          FixComponentResultDialogComponent,
          {
            keyboard: false,
          }
        );
        modalRef.componentInstance.fixResults = data;
        this._snackBar.dismiss();
      },
      (error) => {
        console.error('error: ' + error);
        this._snackBar.dismiss();
      }
    );
  }

  public closeBtn(): void {
    this.activeModal.close();
  }

  private openSnackBar(): void {
    this._snackBar.openFromComponent(SnackbarComponent, {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration: 100000,
    });
  }
}

@Component({
  selector: 'snack-bar-component',
  template:
    '<span class="fix-component-title">The fix is in progress</span> <i class="fa-duotone fa-spinner-third fa-spin"></i>',
  styles: [
    `
      .fix-component-title {
        margin-right: 11px;
      }
    `,
  ],
})
export class SnackbarComponent {}
