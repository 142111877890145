export enum RepositoryCode {
  Unknown = 'UNKNOWN',
  Bitbucket = 'BITBUCKET',
  GitHub = 'GITHUB',
  Gitlab = 'GITLAB',
  Pastebin = 'PASTEBIN',

  // Build Systems
  LuaRocks = 'LUAROCKS',
  Maven = 'MAVEN',
  Gradle = 'GRADLE',
  NPM = 'NPM',
  NuGet = 'NUGET',
  Python = 'PYPI',
  Pip = 'PIP',
  PipFileLock = 'PIP_FILE_LOCK',
  Poetry = 'POETRY',
  GO = 'GO',
  Stackoverflow = 'STACKOVERFLOW',
  Bazel = 'BAZEL',
  Conan = 'CONAN',

  Cocoapods = 'COCOA_PODS',
  Composer = 'COMPOSER',
  Cargo = 'CARGO',
  GEM = 'GEM',
  HEXElixir = 'HEX_ELIXIR',
  HexErlang = 'HEX_ERLANG',
  RENV = 'RENV',
}
