import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeSharedModule } from '../../theme/shared/theme-shared.module';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import {
  NgbAlertModule,
  NgbPopoverModule,
  NgbTabsetModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';

import { SourceDimensionComponent } from './source-dimension/source-dimension.component';
import { LicenseDimensionComponent } from './license-dimension/license-dimension.component';
import { SharedModule } from '@app/shared/shared.module';
import { TaskComponent } from './task/task.component';
import { MessagesComponent } from './messages/messages.component';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  imports: [
    CommonModule,
    ThemeSharedModule,
    SharedModule,
    TableModule,
    RouterModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbTabsetModule,
    MatPaginatorModule,
    NgbAlertModule,
  ],
  exports: [
    SourceDimensionComponent,
    LicenseDimensionComponent,
    TaskComponent,
    MessagesComponent,
  ],
  declarations: [
    SourceDimensionComponent,
    LicenseDimensionComponent,
    TaskComponent,
    MessagesComponent,
  ],
})
export class TcSharedModule {}
