import { Component, Input } from '@angular/core';
import { FixResult } from '@app/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fix-result',
  templateUrl: './fix-component-result-dialog.component.html',
  styleUrls: ['./fix-component-result-dialog.component.scss'],
})
export class FixComponentResultDialogComponent {
  @Input() fixResults: Array<FixResult>;

  public constructor(public activeModal: NgbActiveModal) {}

  public closeBtn(): void {
    this.activeModal.close();
  }
}
