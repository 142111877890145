import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Project } from '@app/models';
import { ProjectDashboardService } from '@app/services/project-dashboard.service';
import { StateService } from '@app/services/state.service';
import { ClipboardDialogComponent } from '@app/shared/project/clipboard-dialog/clipboard-dialog.component';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-scan-history-modal',
  templateUrl: './scan-history-modal.component.html',
  styleUrls: ['./scan-history-modal.component.scss'],
})
export class ScanHistoryModalComponent {
  @Output() public rowSelect: EventEmitter<any> = new EventEmitter();
  @Output() public openLogs: EventEmitter<any> = new EventEmitter();
  @Output() public changePage: EventEmitter<any> = new EventEmitter();

  public columns: Array<string> = [
    'ID',
    'Commit',
    'Branch',
    'Tag',
    'Created',
    'Vulnerabilities',
    'Licenses',
    'Components',
    'Embedded',
  ];
  // input data
  public projectTagInputValue: string = '';
  public project: Project;
  public isDisablePagination: boolean;
  public isHighlightNewScan;
  public highlitedScanId;
  public defaultPageSize;

  public constructor(
    public projectDashboardService: ProjectDashboardService,
    private modalService: NgbModal,
    public stateService: StateService,
    public modal: NgbActiveModal
  ) {}

  public onRowSelectEmit(data): void {
    this.rowSelect.emit(data);
  }

  public openLogsEmit(error, errorMsg, log): void {
    this.openLogs.emit({ error, errorMsg, log });
  }

  public changePageEmit(data): void {
    this.changePage.emit(data);
  }

  public rowUnselect($event: any) {
    this.stateService.selectedScan = $event.data;
  }

  public copyToClipboard(value: string, message: string): void {
    if (value && value.length > 0) {
      navigator.clipboard.writeText(value).then(() => {
        const modalRef: NgbModalRef = this.modalService.open(
          ClipboardDialogComponent,
          {
            keyboard: false,
            centered: true,
            windowClass: 'clip-board-copy',
          }
        );
        modalRef.componentInstance.message = message;
      });
    }
  }
}
