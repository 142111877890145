import { Injectable } from '@angular/core';
import { LoadingDialogComponent } from '@app/threat-center/dashboard/project-scan-dialog/loading-dialog.component';
import { ScanHelperService } from '@app/services/scan-helper.service';
import { BehaviorSubject } from 'rxjs';
import { OngoingOperationsService } from './ongoing-operations.service';
import { CoreHelperService } from './core';

@Injectable()
export class ReloadService {
  public scanSubject: BehaviorSubject<any> = new BehaviorSubject('');
  public closeModal: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public constructor(
    private coreHelperService: CoreHelperService,
    private ongoingService: OngoingOperationsService,
    private scanHelperService: ScanHelperService
  ) {
    this.ongoingService.subject.subscribe(
      (value: { username: string; logout: boolean }) => {
        this.scanHelperService.isLoggedOut = value.logout;
        if (value.logout || !value.username) {
          return;
        }
        this.restoreScan();
      }
    );
  }

  public submitingRepoforScanStart(
    scanRequest,
    message,
    pageType?: 'AI_CERTIFY' | 'SUPPLY_CHAIN'
  ) {
    const preScanProjectData = {
      uniqId: this.coreHelperService.uuidv4(),
      message: scanRequest.repository + message,
      projectName: scanRequest.repository,
      entityId: scanRequest.entityId,
    };
    this.scanHelperService.submitingCheckAlreadyScanned(
      preScanProjectData,
      LoadingDialogComponent,
      pageType
    );
    this.scanHelperService.pageType = pageType;
  }

  private restoreScan() {
    const projects = JSON.parse(
      localStorage.getItem('SCANNING_PROJECTS') || '[]'
    );
    this.scanHelperService.projectScanResults = projects;
    projects
      .filter((p) => p.taskToken)
      .forEach((p) => {
        this.scanHelperService.getTaskUpdate({ taskToken: p.taskToken });
      });
    this.scanHelperService.openFloatingModal(LoadingDialogComponent);
  }
}
