import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NextConfig } from '../../../../../app-config';
import { Location } from '@angular/common';
import { NavigationService } from '../navigation-service';
import { ApolloQueryResult } from 'apollo-client';
import { Navigation, NavigationItem } from '../navigation.model';

@Component({
  selector: 'app-nav-content',
  templateUrl: './nav-content.component.html',
  styleUrls: ['./nav-content.component.scss'],
})
export class NavContentComponent implements OnInit, AfterViewInit {
  private readonly disabledClass = 'disabled d-none';
  public nextConfig: { [key: string]: any } = NextConfig.config;
  public navigation: Array<NavigationItem>;
  public prevDisabled: string = this.disabledClass;
  public nextDisabled: string = '';
  public contentWidth: number = 0;
  public wrapperWidth: any;
  public scrollWidth: number = 0;
  public windowWidth: number = window.innerWidth;
  public collapseStyle: string = 'none';
  public menuClass: boolean = false;

  @Output() navMobCollapseEmitter = new EventEmitter();

  @ViewChild('navbarContent') navbarContent: ElementRef;
  @ViewChild('navbarWrapper') navbarWrapper: ElementRef;

  public constructor(
    public navigationService: NavigationService,
    private location: Location
  ) {}

  public ngOnInit(): void {
    this.navigation = this.navigationService.getNavigationItems();
    this.checkIfCanEditDeploymentSettings();
    if (this.windowWidth < 992) {
      this.nextConfig.layout = 'vertical';
      setTimeout(() => {
        document
          .querySelector('.pcoded-navbar')
          .classList.add('menupos-static');
        (
          document.querySelector('#nav-ps-next') as HTMLElement
        ).style.maxHeight = '100%';
      }, 500);
    }
  }

  public ngAfterViewInit(): void {
    if (this.nextConfig['layout'] === 'horizontal') {
      this.contentWidth = this.navbarContent.nativeElement.clientWidth;
      this.wrapperWidth = this.navbarWrapper.nativeElement.clientWidth;
    }
  }

  public toggleMobOption(): void {
    this.menuClass = !this.menuClass;
    this.collapseStyle = this.menuClass ? 'block' : 'none';
  }

  public navCollapse(): void {
    // if (this.windowWidth >= 992) {
    //   this.onNavCollapse.emit();
    // } else {
    //   this.onNavHeaderMobCollapse.emit();
    // }
  }

  public scrollPlus(): void {
    this.scrollWidth = this.scrollWidth + (this.wrapperWidth - 80);
    if (this.scrollWidth > this.contentWidth - this.wrapperWidth) {
      this.scrollWidth = this.contentWidth - this.wrapperWidth + 80;
      this.nextDisabled = this.disabledClass;
    }
    this.prevDisabled = '';
    if (this.nextConfig.rtlLayout) {
      (
        document.querySelector('#side-nav-horizontal') as HTMLElement
      ).style.marginRight = '-' + this.scrollWidth + 'px';
    } else {
      (
        document.querySelector('#side-nav-horizontal') as HTMLElement
      ).style.marginLeft = '-' + this.scrollWidth + 'px';
    }
  }

  public scrollMinus(): void {
    this.scrollWidth = this.scrollWidth - this.wrapperWidth;
    if (this.scrollWidth < 0) {
      this.scrollWidth = 0;
      this.prevDisabled = this.disabledClass;
    }
    this.nextDisabled = '';
    if (this.nextConfig.rtlLayout) {
      (
        document.querySelector('#side-nav-horizontal') as HTMLElement
      ).style.marginRight = '-' + this.scrollWidth + 'px';
    } else {
      (
        document.querySelector('#side-nav-horizontal') as HTMLElement
      ).style.marginLeft = '-' + this.scrollWidth + 'px';
    }
  }

  public fireLeave(): void {
    const sections = document.querySelectorAll('.pcoded-hasmenu');
    for (let i = 0; i < sections.length; i++) {
      sections[i].classList.remove('active');
      sections[i].classList.remove('pcoded-trigger');
    }

    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }
    const link = `a.nav-link[ href='" + current_url + "' ]`;
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        parent.classList.add('active');
      } else if (up_parent.classList.contains('pcoded-hasmenu')) {
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        last_parent.classList.add('active');
      }
    }
  }

  public navMob(): void {
    if (
      this.windowWidth < 992 &&
      document
        .querySelector('app-navigation.pcoded-navbar')
        .classList.contains('mob-open')
    ) {
      this.navMobCollapseEmitter.emit();
    }
  }

  public fireOutClick(): void {
    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }
    const link = `a.nav-link[ href='" + current_url + "' ]`;
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        if (this.nextConfig['layout'] === 'vertical') {
          parent.classList.add('pcoded-trigger');
        }
        parent.classList.add('active');
      } else if (up_parent.classList.contains('pcoded-hasmenu')) {
        if (this.nextConfig['layout'] === 'vertical') {
          up_parent.classList.add('pcoded-trigger');
        }
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        if (this.nextConfig['layout'] === 'vertical') {
          last_parent.classList.add('pcoded-trigger');
        }
        last_parent.classList.add('active');
      }
    }
  }

  /**
   * we check if the user have the right to edit deplyment settings and
   * if not - we delete the item in the menu
   */
  private checkIfCanEditDeploymentSettings(): void {
    this.navigationService.canEditDeploymentSettings().subscribe(
      (
        editPermission: ApolloQueryResult<{
          canEditDeploymentSettings: {
            data: string;
          };
        }>
      ) => {
        if (editPermission.data.canEditDeploymentSettings.data === 'false') {
          this.navigation[0].children.filter((child: Navigation) => {
            if (child.id === 'admin') {
              child.children = child.children.filter(
                (subItem: NavigationItem) => subItem.id !== 'deployment-mode'
              );
            }
          });
        }
      }
    );
  }
}
