import { Component, Input, OnInit } from '@angular/core';
import { ChartData } from '@app/models/charts';
import { ChartHelperService } from '@app/services/core/chart-helper.service';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
})
export class DonutChartComponent implements OnInit {
  @Input()
  chartData: ChartData;

  @Input()
  chartClass: string;

  chartConfig: any;

  constructor(private chartHelperService: ChartHelperService) {}

  ngOnInit(): void {
    if (this.chartData.series === undefined) {
      this.chartData.series = [];
    }

    this.chartConfig = Object.assign(
      this.chartHelperService.initDonutChartConfiguration()
    );
    let height: string;
    switch (this.chartClass) {
      case 'tiny':
        height = '90px';
        break;
      case 'smaller':
        height = '125px';
        break;
      case 'big':
        height = '160px';
        break;
      default:
        height = '140px';
        break;
    }
    this.chartConfig.chart['height'] = height;
    this.chartConfig.dataLabels.style = {
      fontSize: '12px',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontWeight: '300',
      colors: undefined,
    };
  }
}
