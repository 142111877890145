import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NextConfig } from '../../../../../../app-config';
import { AuthorizationService } from '@app/security/services';
import { NavigationItem } from '../../navigation.model';
import { SubscriptionService } from '@app/subscription/services/subscription.service';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-nav-group',
  templateUrl: './nav-group.component.html',
  styleUrls: ['./nav-group.component.scss'],
})
export class NavGroupComponent implements OnInit {
  @Input() item: NavigationItem;
  @Input() layout1: boolean = false;
  @Input() activeId: any;
  public nextConfig: any;

  isHybrid: boolean = environment.hybrid;

  constructor(
    private subscriptionService: SubscriptionService,
    private location: Location,
    private authorizationService: AuthorizationService
  ) {
    this.nextConfig = NextConfig.config;
  }

  ngOnInit() {
    // at reload time active and trigger link
    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }
    const link = `a.nav-link[ href='" + current_url + "' ]`;
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        if (this.nextConfig['layout'] === 'vertical') {
          parent.classList.add('pcoded-trigger');
        }
        parent.classList.add('active');
      } else if (up_parent.classList.contains('pcoded-hasmenu')) {
        if (this.nextConfig['layout'] === 'vertical') {
          up_parent.classList.add('pcoded-trigger');
        }
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        if (this.nextConfig['layout'] === 'vertical') {
          last_parent.classList.add('pcoded-trigger');
        }
        last_parent.classList.add('active');
      }
    }
  }

  showMenu(): boolean {
    if (this.isHybrid) {
      return true;
    } else if (this.subscriptionService.subscriptionIsValid()) {
      return true;
    } else if (this.subscriptionService.trialIsValid()) {
      return true;
    } else {
      return false;
    }
  }

  checkPermissions(item: NavigationItem) {
    if (item && item.type) {
      if (item.type === 'group' || item.type === 'collapse') {
        for (const child of item.children) {
          if (this.checkPermissions(child)) {
            return true;
          }
        }
        return false;
      } else {
        return this.authorizationService.hasPermissions(item.permissions);
      }
    }
    return true;
  }
}
