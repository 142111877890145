import { Injectable } from '@angular/core';
import Swal, { SweetAlertType } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor() {}

  // Core alert message
  alertBox(text: string, title: string = 'Error!', type) {
    return Swal.fire({
      type,
      title,
      text,
    });
  }

  alertBoxNavigate(title: string, text: string) {
    return Swal.fire({
      title,
      text,
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Navigate',
      showCloseButton: true,
      cancelButtonText: 'Scan Again',
    });
  }

  alertConfirm(
    title: string,
    text: string,
    typ: SweetAlertType = 'warning',
    isshowConfirmButton: boolean = false,
    isshowCancelButton: boolean = false,
    confirmButtonColor: string,
    cancelButtonColor: string,
    confirmButtonText: string,
    cancelButtonText: string
  ) {
    return Swal.fire({
      title,
      text,
      type: typ,
      showConfirmButton: isshowConfirmButton,
      showCancelButton: isshowCancelButton,
      confirmButtonColor,
      cancelButtonColor,
      confirmButtonText,
      cancelButtonText,
    });
  }

  alertBoxHtml(text: string, title: string = 'Error!', type: string = 'error') {
    return Swal.fire({
      type: 'error',
      title,
      html: text,
    });
  }
}
