<ngx-scrolltop
  [backgroundColor]="'#4680ff'"
  [symbolColor]="'#fafafa'"
  [size]="40"
  [mode]="'classic'"
  position="right"
  theme="gray"
>
</ngx-scrolltop>

<router-outlet>
  <app-spinner></app-spinner>
</router-outlet>
<div class="app-version" id="app-version">
  <div class="version-text" id="app-version-text">
    BV: {{ backendVersion }} FV: {{ frontendVersion?.version }}
  </div>
</div>
