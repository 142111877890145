import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * This servis is required as a layer for operations to avoid circular dependency
 * it recieves a subject from its caller and pings the necessary service to renew or start
 * an ongoing operation
 */
@Injectable()
export class OngoingOperationsService {
  public subject: BehaviorSubject<any> = new BehaviorSubject('');
}
