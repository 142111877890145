import { Injectable } from '@angular/core';
import { FixComponentVersionQuery, FixResult } from '@app/models';
import {
  AutofixVulnerabilityVersions,
  AutofixVulnerabilityVersionsQuery,
  PatchedInfoSimplified,
  PatchedInfoSimplifiedQuery,
} from '@app/threat-center/shared/models/types';
import { Observable } from 'rxjs';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';
import { CoreGraphQLService } from '@app/services/core/core-graphql.service';
import { ApolloQueryResult } from 'apollo-client';

export class AutoFixDataInput {
  readonly componentPurl: string;
  readonly newVersion: string;
  readonly oldVersion: string;
  readonly scanId: string;

  constructor(
    componentPurl: string,
    newVersion: string,
    oldVersion: string,
    scanId: string
  ) {
    this.componentPurl = componentPurl;
    this.newVersion = newVersion;
    this.oldVersion = oldVersion;
    this.scanId = scanId;
  }
}

export interface IAutoFixDataInput {
  componentPurl: string;
  newVersion: string;
  oldVersion: string;
  scanId: string;
}

@Injectable()
export class FixService {
  constructor(private coreGraphQLService: CoreGraphQLService) {}

  public fixComponentVersion(
    scanId: string,
    componentId: string,
    oldVersion: string,
    newVersion: string
  ): Observable<FixResult[]> {
    return this.coreGraphQLService
      .coreGQLReq<FixComponentVersionQuery>(
        gql(`query {
            fixComponentVersion(
                scanId: "${scanId}", componentPurl: "${componentId}", newVersion: "${newVersion}", oldVersion:"${oldVersion}"
            ) {
                buildFile
                success
                errorMessage
            }
        }`),
        'no-cache'
      )
      .pipe(map((res) => res.data.fixComponentVersion));
  }

  public fixComponentVersions(
    autoFixDataList: Array<AutoFixDataInput>
  ): Observable<Array<any>> {
    return this.coreGraphQLService
      .coreGQLReqForMutation<any>(
        gql`
          mutation FixComponentVersions($autoFixDataList: [AutoFixDataInput]!) {
            fixComponentVersions(autoFixDataList: $autoFixDataList) {
              success
              scmRepoOwner
              scmRepoName
              scmRepoBranch
              errorMessage
              buildFile
            }
          }
        `,
        { autoFixDataList }
      )
      .pipe(map((res) => res.data.fixComponentVersions));
  }

  public getPatchedVersion(
    componentId: string
  ): Observable<PatchedInfoSimplified> {
    // todo: ref: Why do we have to call gql() each time? Make coreGQLReq accept
    // string as first arg and call gql() inside coreGQLReq. [task: https://github.com/threatrix/product/issues/1225]
    return this.coreGraphQLService
      .coreGQLReq<PatchedInfoSimplifiedQuery>(
        gql(`query {
            autofixVersions(componentPurl: "${componentId}") {
                namespace
                name
                vulnerableVersion
                nextPatchedVersion
                latestPatchedVersion
            }
        }`),
        'no-cache'
      )
      .pipe(map((result) => result.data.autofixVersions));
  }

  public autofixVulnerabilityVersions(
    scanId: string,
    vulnerabilityAlias: string
  ): Observable<Array<AutofixVulnerabilityVersions>> {
    return this.coreGraphQLService
      .coreGQLReq<AutofixVulnerabilityVersionsQuery>(
        gql(`query {
            autofixVulnerabilityVersions(scanId: "${scanId}", vulnerabilityAlias: "${vulnerabilityAlias}") {
              vulnerableVersion
              purl
              namespace
              name
              latestPatchedVersion
              nextPatchedVersion
            }
        }`),
        'no-cache'
      )
      .pipe(
        map(
          (result: ApolloQueryResult<AutofixVulnerabilityVersionsQuery>) =>
            result.data.autofixVulnerabilityVersions
        )
      );
  }
}
