import { LicenseQueryNode } from './license';
import { ScanComponent } from './scan-asset';

export class FixResult {
  buildFile: string;
  success: boolean;
  errorMessage: string;
}

export class TxComponent {
  componentId: string;
  name: string;
  group: string;
  version: string;
  copyrightList?: Copyright[];
  releaseDate: Date;
  component?: any;
  dependencyManagerType?: string;
}

export class Copyright {
  text: string;
  startYear: number;
  endYear: number;
  owners: string[];
  toPresent: boolean;
}

export interface ComponentQuery {
  component: TxComponent;
}
export interface ScanComponentQuery {
  scanComponent: ComponentDetailQuery;
}

export interface FixComponentVersionQuery {
  fixComponentVersion: FixResult[];
}

export interface ComponentDetailQuery {
  component: TxComponent;
  componentId: string;
  group: string;
  licenses: LicenseQueryNode;
  name: string;
  releaseDate: string;
  version: string;
}

export interface ScanComponentWithVersionsQuery {
  scanComponent: ComponentWithVersionsDetailQuery;
}

export interface ComponentWithVersionsDetailQuery {
  component: ComponentWithVersions;
  componentId: string;
  group: string;
  licenses: LicenseQueryNode;
  name: string;
  releaseDate: string;
  version: string;
}

export interface ComponentWithVersions extends TxComponent {
  componentVersions: ComponentVersions;
}

export interface ComponentVersionsQuery {
  componentVersions: ComponentVersions;
}

export interface ComponentVersions {
  resultList: Array<ComponentVersionsResult>;
  pageSize: number;
  start: number;
  total: number;
  currentIndex?: number;
}

export interface ComponentVersionsResult {
  componentId: string;
  purl: string;
  version: string;
  releaseDate: Date;
  licenses: {
    edges: Array<{
      node: {
        name: string;
        licenseId: string;
      };
    }>;
  };
  vulnerabilities: {
    edges: Array<{
      node: {
        vulnerabilityAlias: string;
        severity: string;
      };
    }>;
    maxSeverity?: string;
  };
  changeIcon?: string;
  vulnerabilitiesSeveretyTotal?: {
    CRITICAL: number;
    HIGH: number;
    MEDIUM: number;
    LOW: number;
    INFO: number;
  };
}
