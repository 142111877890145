import { Component, Input } from '@angular/core';
import { Messages } from '@app/models';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent {
  @Input() messages: Messages;
  @Input() isDismiss: boolean = true;
}
