import { Injectable } from '@angular/core';
import { LicenseData, LicenseUrlAndContent, ScanAssetMatch } from '@app/models';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { FetchResult } from 'apollo-link';
import { ScanAssetMatchLicense } from '@app/threat-center/shared/models/types';
import { CoreGraphQLService } from '@app/services/core/core-graphql.service';
import { ScanAssetMatchCopyright } from '@app/models/copyright';

@Injectable()
export class LicenseAndCopyrightAuditService {
  constructor(private coreGraphQLService: CoreGraphQLService) {}

  /**
   * Marks match license as rejected.
   *
   * Backend endpoint method: io.threatrix.threatcenter.controller.AuditScanAssetController#setRejectedScanAssetMatchLicense
   *
   * @param scanAssetMatch - ScanAssetMatch instance which license we want to reject.
   * @param licenseId - obviously license ID of the license to be rejected.
   * ScanAssetMatchLicense
   */
  markMatchLicenseAsRejected(
    scanAssetMatch: ScanAssetMatch,
    licenseId: string
  ): Observable<FetchResult<ScanAssetMatchLicense>> {
    return this.coreGraphQLService.coreGQLReqForMutation(gql`
            mutation {
                setRejectedScanAssetMatchLicense(
                    scanId: "${scanAssetMatch.scanId}",
                    scanAssetId: "${scanAssetMatch.scanAssetId}"
                    assetMatchId: "${scanAssetMatch.assetMatchId}"
                    licenseId: "${licenseId}"
                ) {
                      orgId
                      scanId
                      scanAssetId
                      assetMatchId
                    
                      licenseId
                      licenseName
                }
            }`);
  }

  /**
   * Marks match copyright as rejected.
   *
   * Backend endpoint method: io.threatrix.threatcenter.controller.AuditScanAssetController#setRejectedScanAssetMatchCopyright
   *
   * @param scanAssetMatch - ScanAssetMatch instance which copyright we want to reject.
   * @param copyrightText  - copyright text of the copyright to be rejected.
   * @return ScanAssetMatchCopyright
   */
  markMatchCopyrightAsRejected(
    scanAssetMatch: ScanAssetMatch,
    copyrightText: string
  ): Observable<FetchResult<ScanAssetMatchCopyright>> {
    return this.coreGraphQLService.coreGQLReqForMutation(gql`
            mutation {
                setRejectedScanAssetMatchCopyright(
                    scanId: "${scanAssetMatch.scanId}",
                    scanAssetId: "${scanAssetMatch.scanAssetId}"
                    assetMatchId: "${scanAssetMatch.assetMatchId}"
                    copyrightText: "${copyrightText}"
                ) {
                    orgId
                    scanId
                    scanAssetId
                    scanAssetMatchId
                
                    copyrightText
                
                    discoveryType
                
                    auditorUsername
                    auditorFullName
                    auditDate
                
                    rejected
                }
            }`);
  }

  // io.threatrix.threatcenter.controller.LicenseController#addLicenseScanAssetMatch
  addMatchLicense(
    scanAssetMatch: ScanAssetMatch,
    licenseData: LicenseData
  ): Observable<FetchResult<ScanAssetMatchLicense>> {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
            mutation ($licenseData: LicenseDataInput) {
                addLicenseScanAssetMatch(
                    scanId: "${scanAssetMatch.scanId}",
                    scanAssetId: "${scanAssetMatch.scanAssetId}",
                    assetMatchId: "${scanAssetMatch.assetMatchId}",
                    license: $licenseData
                ) {
                    orgId
                      scanId
                      scanAssetId
                      assetMatchId
                    
                      licenseId
                      licenseName
                }
            }`,
      { licenseData }
    );
  }

  // io.threatrix.threatcenter.controller.AuditScanAssetController#addCopyrightScanAssetMatch
  addMatchCopyright(
    scanAssetMatch: ScanAssetMatch,
    copyrightText: string
  ): Observable<FetchResult<ScanAssetMatchCopyright>> {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
            mutation($copyrightText: String) {
                addCopyrightScanAssetMatch(
                    scanId: "${scanAssetMatch.scanId}",
                    scanAssetId: "${scanAssetMatch.scanAssetId}",
                    assetMatchId: "${scanAssetMatch.assetMatchId}",
                    copyrightText: $copyrightText,
                ) {
                    orgId
                    scanId
                    scanAssetId
                    scanAssetMatchId
                    
                    copyrightText
                    discoveryType
                }
            }`,
      { copyrightText }
    );
  }

  // io.threatrix.threatcenter.controller.LicenseController#getLicenseAssetUrlAndContent
  getAssetLicenseUrlAndContent(
    scanAssetMatch: ScanAssetMatch,
    licenseId: string
  ): Observable<FetchResult<LicenseUrlAndContent>> {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
            query {
                getAssetLicenseUrlAndContent(
                    scanId: "${scanAssetMatch.scanId}",
                    scanAssetId: "${scanAssetMatch.scanAssetId}",
                    assetMatchId: "${scanAssetMatch.assetMatchId}",
                    licenseId: "${licenseId}"
                ) {
                    url
                    content
                    provenance
                }
            }
          `,
      'cache-first'
    );
  }

  // io.threatrix.threatcenter.controller.LicenseController#getComponentLicenseUrlAndContent
  getComponentLicenseUrlAndContent(
    scanId: string,
    componentId: string,
    licenseId: string
  ) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
            query {
                componentLicenseUrlAndContent(
                    scanId: "${scanId}",
                    componentPurl: "${componentId}",
                    licenseId: "${licenseId}"
                ) {
                    url
                    content
                    provenance
                }
            }
          `,
      'cache-first'
    );
  }
}
