import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ChartData } from '@app/models/charts';

@Component({
  selector: 'app-common-donut-chart',
  templateUrl: './common-donut-chart.component.html',
  styleUrls: ['./common-donut-chart.component.scss'],
})
export class CommonDonutChartComponent implements OnInit, OnChanges {
  @Input() chartClass: string; // class name to add to chart enclosing div to apply addition styles to make chart smaller.

  @Input() title: string;

  @Input() series: number[];
  @Input() labels: string[];
  @Input() colors: string[];

  chartData: ChartData = new ChartData();

  constructor() {}

  ngOnInit(): void {
    this.chartData = new ChartData(
      this.title,
      this.series,
      this.labels,
      this.colors
    );
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    this.chartData = new ChartData(
      this.title,
      this.series,
      this.labels,
      this.colors
    );
  }
}
