<app-ui-modal
  #sourceCodeViewDialog
  dialogClass="modal-dialog-centered popup-Width-fix"
>
  <!-- HEADER ================================================================================================= -->
  <div class="app-modal-header">
    <h5 [ngStyle]="{ color: color }" class="modal-title">Source code view</h5>
    <button
      type="button"
      class="close basic-close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="sourceCodeViewDialog.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- BODY ================================================================================================= -->
  <div class="app-modal-body">
    <div [ngStyle]="{ color: color }" class="info-cols">
      <small>File name</small>
    </div>
    <p [ngStyle]="{ color: color }" class="light-font">{{ fileName }}</p>

    <!-- TEXT -->
    <div [ngStyle]="{ color: color }" class="info-cols">
      <small>Source code</small>
    </div>
    <div class="card card-body bg-light" style="padding-left: 50px">
      <pre class="asset-pane">{{ content }}</pre>
    </div>
  </div>

  <!-- FOOTER ================================================================================================= -->
  <div class="app-modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      (click)="sourceCodeViewDialog.hide()"
    >
      Close
    </button>
  </div>
</app-ui-modal>
