import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'componentName',
})
export class ComponentNamePipe implements PipeTransform {
  transform(componentData, ...args: unknown[]): string {
    return !!componentData && componentData.group
      ? componentData.group +
          ':' +
          componentData.name +
          '@' +
          componentData.version
      : componentData.name + '@' + componentData.version;
  }
}
