<section class="contain">
    <app-alert type="danger">
        <p class="lead mb-0">
            You do not have permissions to access the requested component.
        </p>
    </app-alert>

    <section class="container">
        <div class="action">
            <button type="button" class="btn btn-outline-info big-btn" (click)="goBack()">
                <i class="fal fa-arrow-circle-left" style="margin-right:5px"></i>Go Back
            </button>
        </div>
    </section>
</section>