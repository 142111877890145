import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../services';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  //Http Inetrsaptor
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const jwtToken =
      this.authenticationService.getFromCookie('jwt');
    let existingHeaders = request.headers;
    if (
      jwtToken &&
      // todo: workout following rules, for example "user" can be in the middle of request url and does not relate to our own endpoints.
      (request.url.includes('user') ||
        request.url.includes('localhost') ||
        request.url.includes('threatrix.io') ||
        request.url.includes('graphql') ||
        request.url.includes('project/upload') || // upload for scan v1
        request.url.includes('scan/upload') || // upload for scan v2
        request.url.includes('compliance/report') || // pdf reports
        request.url.includes('report/summary') ||
        request.url.includes('report/components') ||
        request.url.includes('report/assets') ||
        request.url.includes('embedded-asset-state-report') ||
        request.url.includes('license-state-report') ||
        request.url.includes('vulnerability-state-report'))
      && !request.url.startsWith('https://api.github.com/')
    ) {
      existingHeaders = existingHeaders.set(
        'Authorization',
        `Bearer ${jwtToken}`
      );
    } else {
      return next.handle(request.clone());
    }
    const authReq = request.clone({ headers: existingHeaders });
    return next.handle(authReq).pipe(
      map((event: HttpEvent<any>) => event),
      catchError((err) => throwError(err))
    );
  }
}
