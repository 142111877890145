<apx-chart
  *ngIf="chartOptions"
  [series]="series"
  [chart]="chartOptions.chart"
  [labels]="labels"
  [colors]="colors"
  [grid]="chartOptions.grid"
  [plotOptions]="chartOptions.plotOptions"
  [responsive]="chartOptions.responsive"
  [dataLabels]="chartOptions.dataLabels"
  [legend]="chartOptions.legend"
  [autoUpdateSeries]="true"
></apx-chart>
<span
  class="total-value"
  [ngClass]="{ 'two-digit': total >= 100, 'three-digit': total >= 1000 }"
  >{{ total }}</span
>
