<app-ui-modal #copyrightViewDialog>
    <!-- HEADER ================================================================================================= -->
    <div class="app-modal-header">
        <h5 class="modal-title">{{filename}} copyright</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="copyrightViewDialog.hide()"><span aria-hidden="true">&times;</span></button>
    </div>

    <!-- BODY ================================================================================================= -->
    <div class="app-modal-body">
        <!-- TEXT -->
        <div class="info-cols"><small>Copyright text</small></div>
        <p class="copyright-text" *ngIf="copyright?.copyrightText">{{copyright?.copyrightText}}</p>
        <p class="copyright-text" *ngIf="copyright?.text">{{copyright?.text}}</p>
    </div>


    <!-- FOOTER ================================================================================================= -->
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="copyrightViewDialog.hide()">Close</button>
    </div>
</app-ui-modal>
