import { Injectable } from '@angular/core';
import { NextConfig } from '@app/app-config';
import { UserPreferenceModel } from '@app/core/core.class';

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceService {
  // TODO: REF: This method saves all possible settings. This is
  // bad approach just like God Object anti-pattern. Split it into several methods.
  // Set Preference details to session storage
  settingUserPreference(
    moduleName: string,
    previousLastTabSelected: string,
    lastTabSelectedName: string,
    itemPerPageD: { componentName: string; value: string } = null,
    panelActiveId = null,
    selectedDonutChart = null,
    selectedLinechartTab = null,
    lastSelectedScan: string = null,
    assetPreferences: {
      currentStory: any;
      currentAssetDetails: any;
      projectId: string;
      parentScanAssetId: string;
      currrentScanId: string;
    } = null,
    lastSelectedAuditId: string = null
  ) {
    let preferenceDetails: Array<UserPreferenceModel> = [];
    if (!!localStorage.getItem('UserPreference')) {
      preferenceDetails = this.getPreferenceDetailsFromSession();
    }

    if (
      preferenceDetails.filter((pre) => pre.moduleName === moduleName).length >=
      1
    ) {
      //Update Data
      preferenceDetails.forEach((prefrence) => {
        if (prefrence.moduleName === moduleName) {
          //current tab settings
          prefrence.lastTabSelectedName =
            !!lastTabSelectedName && lastTabSelectedName !== ''
              ? lastTabSelectedName
              : prefrence.lastTabSelectedName;

          //Previous tab settings
          if (
            previousLastTabSelected !== null &&
            previousLastTabSelected !== ''
          ) {
            if (
              !!prefrence.lastSelectedTabLists &&
              prefrence.lastSelectedTabLists.length >= 1
            ) {
              prefrence.lastSelectedTabLists.push(previousLastTabSelected);
            } else {
              prefrence.lastSelectedTabLists = [];
              prefrence.lastSelectedTabLists.push(previousLastTabSelected);
            }
          } else {
            prefrence.lastSelectedTabLists =
              previousLastTabSelected == ''
                ? []
                : prefrence.lastSelectedTabLists;
          }

          // Item per page setting
          if (!!itemPerPageD) {
            if (
              !!prefrence.itemPerPageDetails &&
              prefrence.itemPerPageDetails.length >= 1
            ) {
              if (
                prefrence.itemPerPageDetails.filter(
                  (item) => item.componentName === itemPerPageD.componentName
                ).length >= 1
              ) {
                //Update
                prefrence.itemPerPageDetails.forEach((item) => {
                  if (item.componentName === itemPerPageD.componentName) {
                    item.value = itemPerPageD.value;
                  }
                });
              } else {
                //Push Data..
                prefrence.itemPerPageDetails.push(itemPerPageD);
              }
            } else {
              //Push Data it's new
              prefrence.itemPerPageDetails.push(itemPerPageD);
            }
          }

          if (panelActiveId !== null) {
            prefrence.panelActiveId = panelActiveId;
          }

          if (selectedDonutChart !== null) {
            prefrence.selectedDonutChart = selectedDonutChart;
          }

          if (selectedLinechartTab !== null) {
            prefrence.selectedLinechartTab = selectedLinechartTab;
          }

          if (lastSelectedScan !== null) {
            prefrence.lastSelectedScanId = lastSelectedScan;
          }
          if (lastSelectedAuditId !== null) {
            prefrence.lastSelectedAuditId = lastSelectedAuditId;
          }

          if (
            !!assetPreferences &&
            !!assetPreferences.currentAssetDetails &&
            !!assetPreferences.currentStory &&
            !!assetPreferences.projectId
          ) {
            if (
              !!prefrence.assetPreferences &&
              prefrence.assetPreferences.length >= 1
            ) {
              if (
                !!prefrence.assetPreferences.find(
                  (f) => f.projectId === assetPreferences.projectId
                )
              ) {
                prefrence.assetPreferences.forEach((f) => {
                  if (f.projectId === assetPreferences.projectId) {
                    f.projectId = assetPreferences.projectId;
                    f.currentStory = assetPreferences.currentStory;
                    f.currentAssetDetails =
                      assetPreferences.currentAssetDetails;
                    f.parentScanAssetId = assetPreferences.parentScanAssetId;
                    f.currrentScanId = assetPreferences.currrentScanId;
                  }
                });
              } else {
                prefrence.assetPreferences.push(assetPreferences);
              }
            } else {
              prefrence.assetPreferences = [];
              prefrence.assetPreferences.push(assetPreferences);
            }
          }
        }
      });
    } else {
      //pushing Data first time.
      const itemPPage = [];
      const prevousTabList = [];
      if (!!itemPerPageD) {
        itemPPage.push(itemPerPageD);
      }
      if (!!previousLastTabSelected && previousLastTabSelected !== '') {
        prevousTabList.push(previousLastTabSelected);
      }
      preferenceDetails.push({
        moduleName,
        itemPerPageDetails: itemPPage,
        lastTabSelectedName,
        lastSelectedTabLists: prevousTabList,
        lastSelectedAuditId,
      });
    }
    localStorage.setItem('UserPreference', JSON.stringify(preferenceDetails));
  }

  //getting last tab selecetd
  getLastTabSelectedNameByModule(moduleName: string) {
    if (!!localStorage.getItem('UserPreference')) {
      const preferenceDetails = this.getPreferenceDetailsFromSession();
      if (!!preferenceDetails && preferenceDetails.length >= 1) {
        return !!preferenceDetails.find((pre) => pre.moduleName === moduleName)
          ? preferenceDetails.find((pre) => pre.moduleName === moduleName)
              .lastTabSelectedName
          : null;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  //getting panel details for chart
  getPanelDetailByModule(moduleName: string) {
    if (!!localStorage.getItem('UserPreference')) {
      const preferenceDetails = this.getPreferenceDetailsFromSession();
      if (!!preferenceDetails && preferenceDetails.length >= 1) {
        return !!preferenceDetails.find((pre) => pre.moduleName === moduleName)
          ? preferenceDetails.find((pre) => pre.moduleName === moduleName)
          : null;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  //getting last scan selected
  getLastScanSelectedByModule(moduleName: string) {
    if (!!localStorage.getItem('UserPreference')) {
      const preferenceDetails = this.getPreferenceDetailsFromSession();
      if (!!preferenceDetails && preferenceDetails.length >= 1) {
        return !!preferenceDetails.find((pre) => pre.moduleName === moduleName)
          ? preferenceDetails.find((pre) => pre.moduleName === moduleName)
          : null;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getSavedAuditId(moduleName: string): string {
    if (!!localStorage.getItem('UserPreference')) {
      const preferenceDetails = this.getPreferenceDetailsFromSession();
      if (!!preferenceDetails && preferenceDetails.length >= 1) {
        const preferenceDetailsInstance = preferenceDetails.find(
          (pre) => pre.moduleName === moduleName
        );
        return !!preferenceDetailsInstance
          ? preferenceDetailsInstance.lastSelectedAuditId
          : null;
      }
    }
    return null;
  }

  //getting previously seleceted tab details
  getPreviousTabSelectedByModule(
    moduleName: string,
    isUpdate: boolean = false
  ) {
    if (localStorage.getItem('UserPreference')) {
      const preferenceDetails = this.getPreferenceDetailsFromSession();
      if (!!preferenceDetails && preferenceDetails.length >= 1) {
        const lists = !!preferenceDetails.find(
          (pre) => pre.moduleName === moduleName
        )
          ? preferenceDetails.find((pre) => pre.moduleName === moduleName)
              .lastSelectedTabLists
          : null;
        if (!!lists && lists.length >= 1) {
          // return last record and pop it as well
          const returnVal = lists[lists.length - 1];
          lists.splice(-1, 1);
          preferenceDetails.forEach((element) => {
            if (element.moduleName === moduleName) {
              element['lastSelectedTabLists'] = lists;
            }
          });
          if (isUpdate) {
            localStorage.setItem(
              'UserPreference',
              JSON.stringify(preferenceDetails)
            );
          }
          return returnVal;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  //getting Item per page module by component name...
  // todo: ref: implementation of this method is a fucking shame! remaster it.
  getItemPerPageByModuleAndComponentName(
    moduleName: string,
    componentName: string
  ) {
    if (!!localStorage.getItem('UserPreference')) {
      const preferenceDetails = this.getPreferenceDetailsFromSession();
      if (!!preferenceDetails && preferenceDetails.length >= 1) {
        const moduleItem = preferenceDetails.find(
          (pre) => pre.moduleName === moduleName
        );
        if (
          !!moduleItem &&
          !!moduleItem.itemPerPageDetails &&
          moduleItem.itemPerPageDetails.length >= 1
        ) {
          return !!moduleItem.itemPerPageDetails.find(
            (item) => item.componentName === componentName
          )
            ? moduleItem.itemPerPageDetails.find(
                (item) => item.componentName === componentName
              ).value
            : NextConfig.config.defaultPageSize;
        } else {
          return NextConfig.config.defaultPageSize;
        }
      } else {
        return NextConfig.config.defaultPageSize;
      }
    } else {
      return NextConfig.config.defaultPageSize;
    }
  }

  private getPreferenceDetailsFromSession() {
    return JSON.parse(localStorage.getItem('UserPreference'));
  }

  readValue(key: string) {
    return sessionStorage.getItem(key);
  }

  saveValue(key: string, value: any) {
    sessionStorage.setItem(key, value);
  }
}
