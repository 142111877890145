import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JiraService } from '@app/services/jira.service';

@Component({
  selector: 'app-create-jira-ticket',
  templateUrl: './create-jira-ticket.component.html',
  styleUrls: ['./create-jira-ticket.component.scss'],
})
export class CreateJiraTicketComponent implements OnInit {
  content: string;
  scanId;
  orgId;
  projectId;

  // For vulnerabilities
  vulnerabilityId;
  vulnerabilityAlias;

  // For Licenses
  licenseId;

  // For scan asset match
  assetMatchId;

  constructor(
    public activeModal: NgbActiveModal,
    private jiraService: JiraService
  ) {}

  ngOnInit() {}

  closeBtn() {
    this.activeModal.close();
  }

  createJiraTicket() {
    if (this.vulnerabilityAlias && this.vulnerabilityId) {
      this.jiraService
        .createVulnerabilityJiraTicket(
          this.vulnerabilityId,
          this.projectId,
          this.scanId,
          this.orgId,
          this.vulnerabilityAlias,
          this.content
        )
        .subscribe(() => {
          this.successTicketCreation();
        });
    } else if (this.licenseId) {
      this.jiraService
        .createLicenseJiraTicket(
          this.licenseId,
          this.projectId,
          this.scanId,
          this.orgId,
          this.content
        )
        .subscribe(() => {
          this.successTicketCreation();
        });
    } else if (this.assetMatchId) {
      this.jiraService
        .createScanAssetMatchJiraTicket(
          this.assetMatchId,
          this.projectId,
          this.scanId,
          this.orgId,
          this.content
        )
        .subscribe(() => {
          this.successTicketCreation();
        });
    }
  }

  successTicketCreation() {
    this.activeModal.close();
    window.location.reload();
  }
}
