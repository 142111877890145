<div *ngIf="license">
    <!--<div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">{{license.licenseId}}</h3>
    </div>-->
    <div class="modal-body">
        <perfect-scrollbar class="compliance-dashboard-modal">
<!--            <div>{{license.body}}</div>-->


            <div class="row">

                <!-- LEFT COLUMN -->
                <div *ngIf="!license.isOrgLicense" class="col-xl-4" style="padding-right:30px">
                    <div class="col-xl-12">
                        <div style="font-size: 16px; height:30px;border-bottom:1px solid gray;text-align:center">
                            THREAT CATEGORY
                        </div>
                        <div style="width:100%;font-size:20px;text-align:center;padding-top:15px">
                            <span *ngIf="license?.category === 'COPYLEFT_STRONG'" title="Copyleft Strong"
                                  class="badge m-r-5 badge-light-danger f-18">COPYLEFT STRONG</span>
                            <span *ngIf="license?.category === 'COPYLEFT_WEAK'" title="Copyleft Weak"
                                  class="badge m-r-5 badge-light-warning f-18">COPYLEFT WEAK</span>
                            <span *ngIf="license?.category === 'COPYLEFT_PARTIAL'" title="Copyleft Partial"
                                  class="badge m-r-5 badge-light-warning f-18">COPYLEFT PARTIAL</span>
                            <span *ngIf="license?.category === 'COPYLEFT_LIMITED'" title="Copyleft Limited"
                                  class="badge m-r-5 badge-light-warning f-18">COPYLEFT LIMITED</span>
                            <span *ngIf="license?.category === 'COPYLEFT'" title="Copyleft"
                                  class="badge m-r-5 badge-light-warning f-18">COPYLEFT</span>
                            <span *ngIf="license?.category === 'PERMISSIVE'" title="Permissive"
                                  class="badge m-r-5 badge-light-success f-18">PERMISSIVE</span>
                            <span *ngIf="license?.category === 'PROPRIETARY_FREE'" title="Proprietary Free"
                                  class="badge m-r-5 badge-light-warning">PROPRIETARY FREE</span>
                            <span *ngIf="license?.category === 'PUBLIC_DOMAIN'" title="Permissive"
                                  class="badge m-r-5 badge-light-success f-18">PUBLIC DOMAIN</span>
                            <span *ngIf="license?.category === 'UNDEFINED'" title="Undefined"
                                  class="badge m-r-5 badge-light-warning f-18">UNDEFINED</span>
                        </div>
                    </div>
                    <div class="col-xl-12" style="margin-top:40px">
                        <div class="form-group row  p-l-5">
                            <label for="spdx" class="col-sm-12 col-form-label">SPDX</label>
                            <div class="col-xl-12">
                                <input type="text" class="form-control" id="spdx" readonly placeholder="{{license?.spdxId}}">
                            </div>
                        </div>
                        <div class="form-group row  p-l-5">
                            <label for="style" class="col-sm-12 col-form-label">Style</label>
                            <div class="col-xl-12">
                                <input type="text" class="form-control" id="style" readonly placeholder="{{license?.style}}">
                            </div>
                        </div>
                        <div class="form-group row  p-l-5">
                            <label for="publicationYear" class="col-sm-12 col-form-label">Publication Year</label>
                            <div class="col-xl-12">
                                <input type="text" class="form-control" id="publicationYear" readonly
                                       placeholder="{{license?.publicationYear}}">
                            </div>
                        </div>
                        <div class="form-group row  p-l-5">
                            <label for="type" class="col-sm-12 col-form-label">Type</label>
                            <div class="col-xl-12">
                                <input type="text" class="form-control" id="type" readonly placeholder="{{license?.type}}">
                            </div>
                        </div>
                        <div class="form-group row  p-l-5">
                            <label for="summary-compat" class="col-sm-12 col-form-label">Compatible</label>
                            <div class="col-xl-12">
                                <input type="text" class="form-control" id="summary-compat" readonly placeholder="{{license?.compatible}}">
                            </div>
                        </div>
                        <div class="form-group row  p-l-5">
                            <label for="summary-incompat" class="col-sm-12 col-form-label">Incompatible</label>
                            <div class="col-xl-12">
                                <input type="text" class="form-control" id="summary-incompat" readonly placeholder="{{license?.incompatible}}">
                            </div>
                        </div>
                        <div class="form-group row  p-l-5">
                            <label for="notes" class="col-sm-12 col-form-label">Notes</label>
                            <div class="col-xl-12">
                                <textarea rows="10" class="form-control" id="notes" readonly>{{license?.notes}}</textarea>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- RIGHT COLUMN -->
                <div class="{{license.isOrgLicense ? 'col-xl-12' : 'col-xl-8'}}">

                    <!-- NAME DESCRIPTION -->
                    <div class="col-xl-12">
                        <div class="f-18" style="margin-bottom:20px;text-align:center;width:100%;">{{license?.name}}</div>
                    </div>


                    <!-- THREAT CATEGORY -->
                    <div *ngIf="license.isOrgLicense" class="col-xl-6 offset-xl-3">
                        <div style="font-size: 16px; height:30px;border-bottom:1px solid gray;text-align:center">
                            THREAT CATEGORY
                        </div>
                        <div style="width:100%;font-size:20px;text-align:center;padding-top:15px">
                            <span *ngIf="license?.category === 'COPYLEFT_STRONG'" title="Copyleft Strong"
                                  class="badge m-r-5 badge-light-danger f-18">COPYLEFT STRONG</span>
                            <span *ngIf="license?.category === 'COPYLEFT_WEAK'" title="Copyleft Weak"
                                  class="badge m-r-5 badge-light-warning f-18">COPYLEFT WEAK</span>
                            <span *ngIf="license?.category === 'COPYLEFT_PARTIAL'" title="Copyleft Partial"
                                  class="badge m-r-5 badge-light-warning f-18">COPYLEFT PARTIAL</span>
                            <span *ngIf="license?.category === 'COPYLEFT_LIMITED'" title="Copyleft Limited"
                                  class="badge m-r-5 badge-light-warning f-18">COPYLEFT LIMITED</span>
                            <span *ngIf="license?.category === 'COPYLEFT'" title="Copyleft"
                                  class="badge m-r-5 badge-light-warning f-18">COPYLEFT</span>
                            <span *ngIf="license?.category === 'PERMISSIVE'" title="Permissive"
                                  class="badge m-r-5 badge-light-success f-18">PERMISSIVE</span>
                            <span *ngIf="license?.category === 'PROPRIETARY_FREE'" title="Proprietary Free"
                                  class="badge m-r-5 badge-light-warning">PROPRIETARY FREE</span>
                            <span *ngIf="license?.category === 'PUBLIC_DOMAIN'" title="Permissive"
                                  class="badge m-r-5 badge-light-success f-18">PUBLIC DOMAIN</span>
                            <span *ngIf="license?.category === 'UNDEFINED'" title="Undefined"
                                  class="badge m-r-5 badge-light-warning f-18">UNDEFINED</span>
                        </div>
                    </div>


                    <!-- DESCRIPTION -->
                    <div class="col-xl-12">
                        <div>{{license?.description}}</div>
                    </div>





                    <!-- ATTRIBUTES
                          <div class="row" style="padding-left:15px;margin-top:40px">
                            <div class="col-xl-4">
                              <div class="caption">Permissions</div>
                              <div *ngFor="let licenseAttribute of permissions">
                                <i class="fas fa-check color-success m-r-5"></i> {{licenseAttribute.name.replaceAll('_', ' ')}}<i class="m-l-5 fas fa-question-circle" title="{{licenseAttribute.description}}"></i>
                              </div>
                            </div>
                            <div class="col-xl-4">
                              <div class="caption">Limitations</div>
                              <div *ngFor="let licenseAttribute of limitations">
                                <i class="fas fa-times color-danger m-r-5"></i> {{licenseAttribute.name.replaceAll('_', ' ')}}<i class="m-l-5 fas fa-question-circle" title="{{licenseAttribute.description}}"></i>
                              </div>
                            </div>
                            <div class="col-xl-4">
                              <div class="caption">Conditions</div>
                              <div *ngFor="let licenseAttribute of conditions">
                                <i class="fas fa-info-circle color-info m-r-5"></i> {{licenseAttribute.name.replaceAll('_', ' ')}}<i class="m-l-5 fas fa-question-circle" title="{{licenseAttribute.description}}"></i>
                              </div>
                            </div>
                          </div>-->


                    <!-- LICENSE ATTRIBUTES -->
                    <div *ngIf="!license.isOrgLicense" style="display: flex;margin-top: 20px;margin-left:15px">
                        <div style="flex-grow: 1">
                            <div class="caption" style="border-bottom: 1px solid gray;margin-right: 40px">Permissions</div>
                            <div *ngFor="let licenseAttribute of permissions">
                                <i class="fas fa-check color-success m-r-5"></i>
                                {{licenseAttribute.name.replaceAll('_', ' ')}}
                                <i class="m-l-5 fas fa-question-circle" title="{{licenseAttribute.description}}"></i>
                            </div>
                        </div>
                        <div style="flex-grow: 1">
                            <div class="caption" style="border-bottom: 1px solid gray;margin-right: 40px">Limitations</div>
                            <div *ngFor="let licenseAttribute of limitations">
                                <i class="fas fa-times color-danger m-r-5"></i>
                                {{licenseAttribute.name.replaceAll('_', ' ')}}
                                <i class="m-l-5 fas fa-question-circle" title="{{licenseAttribute.description}}"></i>
                            </div>
                        </div>
                        <div style="flex-grow: 2">
                            <div class="caption" style="border-bottom: 1px solid gray;margin-right: 20px">Conditions</div>
                            <div *ngFor="let licenseAttribute of conditions">
                                <i class="fas fa-info-circle color-info m-r-5"></i>
                                {{licenseAttribute.name.replaceAll('_', ' ')}}
                                <i class="m-l-5 fas fa-question-circle" title="{{licenseAttribute.description}}"></i>
                            </div>
                        </div>
                    </div>


                    <!-- LICENSE BODY -->
                    <div class="col-xl-12" style="margin-top:40px">
                        <div class="card card-body bg-light" style="padding-left:50px">
                            <pre class="asset-pane">{{license?.body}}</pre>
                        </div>
                    </div>


                </div>

            </div>
        </perfect-scrollbar>
    </div>

</div>