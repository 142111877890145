<p-table
  *ngIf="scanAssetsTree"
  #dt
  [columns]="columns"
  [value]="tableData"
  [paginator]="false"
  [rows]="50"
  tableStyleClass="table table-striped table-bordered table-hover dataTable no-footer table-width"
  [autoLayout]="true"
  [filterDelay]="0"
  [globalFilterFields]="['name']"
  sortField="embedded"
  [sortOrder]="-1"
  [loading]="loading"
  [loadingIcon]="'fa fa-spinner'"
  dataKey="node.scanAssetId"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Name</th>
      <th>Matches</th>
      <th>Status</th>
      <th>Embedded Assets / Total %</th>
      <th>Attribution</th>
      <th>Match Score</th>
      <th>Match Type</th>
    </tr>
    <tr>
      <th *ngFor="let col of columns">
        <input
          id="nameSearch"
          class="form-control"
          name="search-name"
          [value]="getColumnFilterValue(col)"
          [placeholder]="col"
          *ngIf="col === 'Name'"
          (input)="onNameChange($event)"
        />

        <select
          id="statusSearch"
          class="form-control custom-select"
          [value]="getColumnFilterValue(col)"
          *ngIf="col === 'Status'"
          (change)="onFilterColumn(col, $event, 'statusSearch')"
        >
          <option value="ALL" selected>- All -</option>
          <option value="IGNORED_SIZE_SMALL">IGNORED_SIZE_SMALL</option>
          <option value="IGNORED_SIZE_LARGE">IGNORED_SIZE_LARGE</option>
          <option value="IGNORED_SIZE_EMPTY">IGNORED_SIZE_EMPTY</option>
          <option value="IGNORED_TYPE_UNSUPPORTED">
            IGNORED_TYPE_UNSUPPORTED
          </option>
          <option value="IGNORED">IGNORED</option>
          <option value="ACCEPTED">ACCEPTED</option>
        </select>

        <select
          id="fileSizeSearch"
          class="form-control custom-select"
          [value]="getColumnFilterValue(col)"
          *ngIf="col === 'File Size'"
          (change)="onFilterColumn(col, $event, 'fileSizeSearch')"
        >
          <option value="ALL" selected>- All -</option>
          <option value="1kb">0-1kb</option>
          <option value="1kb-1mb">1kb-1mb</option>
          <option value="1mb-10mb">1mb-10mb</option>
          <option value="10mb+">10mb+</option>
        </select>

        <select
          id="embededSearch"
          class="form-control custom-select"
          [value]="getColumnFilterValue(col)"
          *ngIf="col === 'Embedded Assets'"
          (change)="onFilterColumn(col, $event, 'embededSearch')"
        >
          <option value="ALL" selected>- All -</option>
          <option
            value="over0"
            [label]="MESSAGES.ASSET.FILTER.EMBEDDED_AND_OPENSOURCE"
          ></option>
          <option value="0-10">0-10%</option>
          <option value="11-30">11-30%</option>
          <option value="31-50">31-50%</option>
          <option value="51-70">51-70%</option>
          <option value="71-100">71-100%</option>
        </select>

        <select
          id="attributionSearch"
          class="form-control custom-select"
          [value]="getColumnFilterValue(col)"
          *ngIf="col === 'Attribution'"
          (change)="onFilterColumn(col, $event, 'attributionSearch')"
        >
          <option value="ALL" selected>- All -</option>
          <option value="REQUIRED">REQUIRED</option>
          <option value="PARTIAL">PARTIAL</option>
          <option value="COMPLETE">COMPLETE</option>
          <option value="REVIEWED_IGNORED">REVIEWED_IGNORED</option>
        </select>

        <select
          id="matchScoreSearch"
          class="form-control"
          [value]="getColumnFilterValue(col)"
          *ngIf="col === 'Match Score'"
          (change)="onFilterColumn(col, $event, 'matchScoreSearch')"
        >
          <option value="ALL" selected>- All -</option>
          <option value="HIGH">HIGH</option>
          <option value="MEDIUM">MEDIUM</option>
          <option value="LOW">LOW</option>
        </select>

        <select
          id="matchTypeSearch"
          class="form-control"
          [value]="getColumnFilterValue(col)"
          *ngIf="col === 'Match Type'"
          (change)="onFilterColumn(col, $event, 'matchTypeSearch')"
        >
          <option value="ALL" selected>- All -</option>
          <option value="UNIQUE_PROPRIETARY">PROPRIETARY</option>
          <option value="PROPRIETARY">PROPRIETARY/OPEN SOURCE</option>
          <option value="EMBEDDED_OPEN_SOURCE">OPEN SOURCE/PROPRIETARY</option>
          <option value="OPEN_SOURCE">OPEN SOURCE</option>
          <option value="OPEN_COMPONENT">OPEN SOURCE COMPONENT</option>
        </select>
      </th>
    </tr>
    <tr class="cursor-pointer" *ngIf="story.length > 0">
      <td (click)="goBack()" colspan="7">
        <i class="fas fa-folder-open"></i>&nbsp;&nbsp;...
      </td>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-scanAsset
    let-rowIndex="rowIndex"
    let-expanded="expanded"
    let-columns="columns"
  >
    <tr
      [ngStyle]="{
        cursor:
          scanAsset.node.scanAssetType == 'DIR' || scanAsset.node.matchCount > 0
            ? 'pointer'
            : 'not-allowed'
      }"
      (click)="!ignored(scanAsset) && gotoDetails(scanAsset)"
      *ngIf="
        scanAsset.node.status === 'ACCEPTED' ||
        scanAsset.node.scanAssetType === 'DIR'
      "
      [class.ignored]="ignored(scanAsset)"
    >
      <td class="name">
        <i
          *ngIf="scanAsset.node.scanAssetType === 'DIR'"
          class="fas fa-folder"
        ></i>
        <i
          *ngIf="scanAsset.node.scanAssetType != 'DIR'"
          class="far fa-file"
        ></i>
        <span class="asset-name">{{ getName(scanAsset.node.name) }}</span>
        <i
          class="far fa-clock ml-2"
          *ngIf="scanAsset.node.otCount"
          [placement]="'top'"
          [ngbTooltip]="'Origin Tracing: ' + scanAsset.node.otCount"
        ></i>
        <i
          class="fas fa-cubes"
          triggers="mouseenter:mouseleave"
          [ngbPopover]="popContent"
          style="color: #2dc6db; margin-left: 3px"
          *ngIf="
            scanAsset.node.matchType === 'OPEN_COMPONENT' &&
            scanAsset.node.component
          "
        >
        </i>
        <i
          *ngIf="
            scanAsset.node.scanAssetType !== 'DIR' &&
            (this.columnsFilter.size >= 1 || fromLicenseDimension)
          "
          triggers="mouseenter:mouseleave"
          [ngbPopover]="popContentWorkspace"
          style="color: #2dc6db; margin-left: 5px; cursor: pointer"
          class="fad fa-folder-tree"
        >
        </i>

        <ng-container *ngIf="scanAsset.node.loading; else popup">
          <i class="fa fa-spinner fa-spin ml-auto"></i>
        </ng-container>
        <ng-template #popup>
          <ng-container
            *ngTemplateOutlet="assetPopup; context: { $implicit: scanAsset }"
          ></ng-container>
        </ng-template>

        <ng-template #popContent>
          {{
            scanAsset.node.component.name +
              ':' +
              scanAsset.node.component.group +
              ':' +
              scanAsset.node.component.version
          }}
        </ng-template>
        <ng-template #popContentWorkspace>
          {{ scanAsset?.node?.workspacePath }}
        </ng-template>
      </td>
      <td>
        {{ scanAsset?.node?.matchCount }} /
        {{ scanAsset?.node?.percentEmbedded | number : '1.0-0' }}%
      </td>
      <!-- status score start -->
      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>
      <td>{{ ignored(scanAsset) ? 'IGNORED' : scanAsset.node.status }}</td>
      <!-- status score end -->

      <!-- Embedded score start -->

      <td *ngIf="scanAsset.node.scanAssetType != 'DIR'">
        {{ scanAsset.node.matchCount }}
        <span *ngIf="scanAsset.node.matchCount > 0">
          / {{ scanAsset?.node?.percentEmbedded | number : '1.1-2' }}% /
          {{ scanAsset?.node?.averagePercentCopied | number : '1.1-2' }}%</span
        >
      </td>
      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>

      <!-- Embedded score end -->

      <td *ngIf="scanAsset.node.scanAssetType != 'DIR'">
        {{ scanAsset.node.attributionStatus }}
      </td>

      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>

      <!-- Match score start -->
      <td *ngIf="scanAsset.node.scanAssetType != 'DIR'">
        {{ scanAsset.node.matchScore }}
      </td>
      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>
      <!-- Match score finish -->

      <td *ngIf="scanAsset.node.scanAssetType != 'DIR'">
        {{
          !!scanAsset.node.matchType
            ? matchTypeVal2Caption(scanAsset.node.matchType)
            : ''
        }}
      </td>
      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>
    </tr>
    <tr
      style="cursor: not-allowed"
      *ngIf="
        scanAsset.node.status != 'ACCEPTED' &&
        scanAsset.node.scanAssetType != 'DIR'
      "
      [class.ignored]="ignored(scanAsset)"
    >
      <td class="name">
        <i
          *ngIf="scanAsset.node.scanAssetType === 'DIR'"
          class="fas fa-folder"
        ></i>
        <i
          *ngIf="scanAsset.node.scanAssetType != 'DIR'"
          class="far fa-file"
        ></i>
        <span class="asset-name">{{ getName(scanAsset.node.name) }}</span>
        <i
          *ngIf="
            scanAsset.node.scanAssetType !== 'DIR' &&
            this.columnsFilter.size >= 1
          "
          triggers="mouseenter:mouseleave"
          [ngbPopover]="popContentWorkspace"
          style="color: #2dc6db; margin-left: 3px; cursor: pointer"
          class="fad fa-folder-tree"
        >
        </i>

        <ng-container
          *ngTemplateOutlet="
            assetPopup;
            context: {
              $implicit: scanAsset
            }
          "
        ></ng-container>

        <ng-template #popContentWorkspace>
          {{ scanAsset?.node?.workspacePath }}
        </ng-template>
      </td>
      <td
        *ngIf="
          scanAsset.node.assetSize >= 1024 &&
          scanAsset.node.scanAssetType != 'DIR'
        "
      >
        {{ scanAsset.node.assetSize / 1024 | number : '1.0-1' }} kB
      </td>
      <td
        *ngIf="
          scanAsset.node.assetSize < 1024 &&
          scanAsset.node.scanAssetType != 'DIR'
        "
      >
        {{ scanAsset.node.assetSize | number : '1.0-0' }} bytes
      </td>
      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>
      <td>{{ ignored(scanAsset) ? 'IGNORED' : scanAsset.node.status }}</td>
      <td *ngIf="scanAsset.node.scanAssetType != 'DIR'">
        {{ scanAsset.node.matchCount }}
        <span *ngIf="scanAsset.node.matchCount > 0">
          / {{ scanAsset?.node?.percentEmbedded | number : '1.1-2' }}% /
          {{ scanAsset?.node?.averagePercentCopied | number : '1.1-2' }}%</span
        >
      </td>
      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>
      <td *ngIf="scanAsset.node.scanAssetType != 'DIR'">
        {{ scanAsset.node.attributionStatus }}
      </td>
      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>

      <!-- Match score start -->
      <td *ngIf="scanAsset.node.scanAssetType !== 'DIR'">
        {{ scanAsset.node.matchScore }}
      </td>
      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>
      <!-- Match score finish -->

      <td *ngIf="scanAsset.node.scanAssetType != 'DIR'">
        {{
          !!scanAsset.node.matchType
            ? matchTypeVal2Caption(scanAsset.node.matchType)
            : ''
        }}
      </td>
      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>
    </tr>

    <ng-template #assetPopup let-asset>
      <div class="action-menu-icon-wrapper">
        <div class="action-menu-popup-wrapper">
          <i class="feather icon-more-vertical"></i>

          <div class="action-menu-popup">
            <div class="action-menu">
              <button
                class="btn btn-outline"
                (click)="onIgnoreAsset($event, asset.node, !ignored(scanAsset))"
              >
                {{ ignored(scanAsset) ? 'Unignore' : 'Ignore' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>

  <ng-template pTemplate="rowexpansion" let-scanAsset let-rowIndex="rowIndex">
    <ng-container
      *ngFor="let match of scanAsset.node.embeddedAssets.edges; let i = index"
    >
      <tr class="custom-tr expanded" style="position: relative">
        <td>{{ match.node.name }}</td>
      </tr></ng-container
    >
  </ng-template>
</p-table>
