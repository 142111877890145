<div style="display: flex; position: relative">
  <div *ngIf="showBadge" class="license-status {{ severity }}"></div>
  <span class="license-name">
    <span
      [matTooltip]="name === 'Multiple' ? 'Open licenses details' : ''"
      matTooltipPosition="right"
      matTooltipClass="tooltip-details"
      [ngClass]="{ hover: name === 'Multiple' }"
      [ngbPopover]="popContent"
      [autoClose]="true"
      popoverTitle="Licenses"
      triggers="manual"
      container="body"
      #p="ngbPopover"
      [container]="'body'"
      popoverClass="license-popover"
      [ngStyle]="{ cursor: name === 'Multiple' ? 'pointer' : 'default' }"
      (click)="$event.stopPropagation(); name === 'Multiple' ? p.open() : null"
      class="license-span"
    >
      <span> {{ name }} </span>
      <!-- <ng-container
        [ngTemplateOutlet]="licenseMenu"
        [ngTemplateOutletContext]="{
          license:
            licenses.length > 0 && licenses[0].license
              ? licenses[0].license
              : licenses.length > 0
              ? licenses[0]
              : []
        }"
        *ngIf="
          !(name === 'Multiple' || name === 'Add License') &&
          licenses.length > 0
        "
      ></ng-container
    > -->
    </span>
  </span>
</div>

<ng-template #popContent>
  <i (click)="closePopover()" class="fa-solid fa-xmark" aria-label="Close"></i>
  <div
    *ngFor="let license of licenses"
    class="license-name-wrapper"
    style="display: flex; padding: 2px"
  >
    <div
      class="license-status {{
        severityFormattingService.getLicenseBadgeClassForMatches(
          license?.category
        )
      }}"
    ></div>
    <span class="license-name no-cut">
      {{ license?.name }}
    </span>
  </div>
</ng-template>
