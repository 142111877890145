import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-semi-circle-chart',
  templateUrl: './semi-circle-chart.component.html',
  styleUrls: ['./semi-circle-chart.component.scss'],
})
export class SemiCircleChartComponent {
  @Input() public series: Array<number>;
  @Input() public chartOptions: any;
  @Input() public labels: Array<string>;
  @Input() public colors: Array<string>;
  @Input() public total: number;
}
