import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { RepositoryCode } from '@app/models/repository-code';

@Directive({
  selector: '[appRepoIcon]',
})
export class RepoIconDirective implements OnChanges {
  @Input() appRepoIcon: RepositoryCode;
  @Input() shrink: boolean;

  constructor(private ref: ElementRef) {}

  ngOnChanges(): void {
    if (this.appRepoIcon) {
      this.ref.nativeElement.setAttribute(
        'class',
        `repo-icon ${this.repoTypeIconClass}`
      );
    }
    if (this.shrink) {
      this.ref.nativeElement.setAttribute(
        'class',
        `repo-icon ${this.repoTypeIconClass} shrink`
      );
    }
  }

  private get repoTypeIconClass() {
    switch (this.appRepoIcon) {
      case RepositoryCode.Unknown:
        return 'fa fa-cubes';

      case RepositoryCode.GitHub:
        return 'fab fa-github';

      case RepositoryCode.Gitlab:
        return 'fab fa-gitlab';

      case RepositoryCode.Bitbucket:
        return 'fab fa-bitbucket';

      case RepositoryCode.Stackoverflow:
        return 'fab fa-stack-overflow';

      case RepositoryCode.NPM:
        return 'fab fa-npm';

      case RepositoryCode.Python:
      case RepositoryCode.Pip:
      case RepositoryCode.PipFileLock:
      case RepositoryCode.Poetry:
        return 'fab fa-python';

      case RepositoryCode.Maven:
      case RepositoryCode.Gradle:
        return 'fab fa-java';

      case RepositoryCode.GEM:
        return 'fa-regular fa-gem';

      case RepositoryCode.RENV:
        return 'fa-solid fa-r';

      case RepositoryCode.HEXElixir:
      case RepositoryCode.HexErlang:
        return 'fa-brands fa-erlang';

      case RepositoryCode.Cargo:
        return 'fa-brands fa-rust';

      case RepositoryCode.Composer:
        return 'fa-brands fa-php';

      case RepositoryCode.GO:
        return 'fa-brands fa-golang';

      // todo: ref: find icon for missing types
      default:
        return 'fa fa-cubes';
    }
  }
}
