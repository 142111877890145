import {
  CoreHelperService,
  LogOutStaticHelper,
} from '@app/services/core/core-helper.service';

export function CommonUIMethodsDecorator(): Function { // eslint-disable-line
  return (target: () => void) => {
    target.prototype.logOutUser = (coreHelperService: CoreHelperService) =>
      LogOutStaticHelper.logoutUser(coreHelperService);
  };
}
