<app-navigation
  class="navbar pcoded-header navbar-expand-lg navbar-light pcoded-navbar theme-horizontal navbar-dark header-blue brand-blue headerpos-fixed"
  [ngClass]="{
  'navbar-collapsed': navCollapsed,
  'theme-horizontal': this.nextConfig['layout'] === 'horizontal',
  'mob-open': navCollapsedMob
}" (onNavMobCollapse)="navMobClick()"></app-navigation>

<!-- <app-nav-bar class="navbar pcoded-header navbar-expand-lg navbar-light" (onNavCollapse)="this.navCollapsed = !this.navCollapsed;" (onNavHeaderMobCollapse)="navMobClick()"></app-nav-bar> -->
<div class="pcoded-main-container" *ngIf="!isUnAuthorize && loaded">
  <main-spinner *ngIf="loading"> </main-spinner>
  <div class="pcoded-wrapper" *ngIf="!loading"
    [ngClass]="{'api-explorer-page': isApiExplorerPage, 'w-95' : isApiExplorerPage && (isMaximizeWidth$ | async)}"
    [class.container]="this.nextConfig.layout === 'horizontal' && this.nextConfig.subLayout === 'horizontal-2'">
    <div class="pcoded-content" style="min-height: 80vh;">
      <div class="pcoded-inner-content">
        <div class="main-body">
          <div class="page-wrapper">
            <!--<app-breadcrumb></app-breadcrumb> -->
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="pcoded-main-container" *ngIf="isUnAuthorize">
  <div class="pcoded-wrapper"
    [ngClass]="{'container': this.nextConfig.layout === 'horizontal' && this.nextConfig.subLayout === 'horizontal-2'}">
    <div class="pcoded-content">
      <div class="pcoded-inner-content">
        <div class="main-body">
          <div class="page-wrapper">
            <app-unauthorized></app-unauthorized>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<app-configuration></app-configuration>
