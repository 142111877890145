import { Component, Input, OnInit } from '@angular/core';
import { ChartData } from '@app/models/charts';
import { ApexNonAxisChartSeries } from 'ng-apexcharts';

@Component({
  selector: 'app-assets-donut-chart',
  templateUrl: './assets-donut-chart.component.html',
  styleUrls: ['./assets-donut-chart.component.scss'],
})
export class AssetsDonutChartComponent implements OnInit {
  @Input()
  chartData: ChartData;

  @Input()
  chartClass: string;

  private TITLE = 'Asset composition';
  private COLORS = ['#20c997', '#00acc1', '#fb5b5b'];
  private chartLabels = [
    {
      label: 'Unique',
      class: 'lgt-green',
    },
    {
      label: 'Embedded',
      class: 'skyblue',
    },
    {
      label: 'Open Source',
      class: 'red',
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.composeChartData();
  }

  composeChartData() {
    this.chartData.title = this.TITLE;
    this.chartData.colors = this.COLORS;
    this.chartData.fill = { colors: this.COLORS };

    this.chartData.chartLabels = this.chartLabels;
    this.chartData.labels = this.chartLabels.map(({ label }) => label);
  }
}
