import { ApexNonAxisChartSeries } from 'ng-apexcharts';

export class ChartData {
  title: string;

  labels: string[];
  series: ApexNonAxisChartSeries;
  colors: string[];

  fill: {
    colors: string[];
  };

  chartLabels: {
    label: string;
    class: string;
  }[];

  constructor(
    title?: string,
    series?: ApexNonAxisChartSeries,
    labels?: string[],
    colors?: string[]
  ) {
    this.title = title;
    this.series = series;
    this.labels = labels;
    this.colors = colors;
  }
}
