import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'discover'
})
export class DiscoverPipe implements PipeTransform {

    transform(value: string): unknown {
        switch (value) {
            case 'DEPENDENCY_FILE':
                return 'DEPENDENCY FILE';
            case 'DRIVE':
                return 'DRIVE';
            case 'STATIC_REF':
                return 'STATIC REF';
            default:
            return '';
        }
    }
}
